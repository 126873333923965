import React, { Component } from 'react';

import brancnBig from '../../../../images/mainpage/sections/branch.png'
import brancnFigure from '../../../../images/mainpage/sections/branch_figure.svg'



import './sections.css'

class BranchesOpen extends Component {




    render() {
        const { lang } = this.props
        return (


            <section className='new__brach__open__wrap'>
            <h1 className="hidden-h1">{lang.NEW_BRANCH_H1}</h1>
                {/* <img src={brancnFigure} alt="" className='branch__figure' /> */}
                <div className='new__branch__content'>
                    <h1>{lang.NEW_BRANCH_H1}</h1>
                    <span>
                        <h2>{lang.NEW_BRANCH_1_H2}</h2>
                        <p>{lang.NEW_BRANCH_1_P}</p>
                        <p>{lang.NEW_BRANCH_1_P_2}</p>
                    </span>
                    <span>
                        <h3>{lang.NEW_BRANCH_2_H2}</h3>
                        <p>{lang.NEW_BRANCH_2_P}</p>
                        <p>{lang.NEW_BRANCH_2_P_2}</p>
                    </span>
                    <span>
                        <h3>{lang.NEW_BRANCH_3_H2}</h3>
                        <p>{lang.NEW_BRANCH_3_P}</p>
                        <p>{lang.NEW_BRANCH_3_P_2}</p>
                        <p>{lang.NEW_BRANCH_3_P_3}</p>
                    </span>
                </div>

                <div className='new__branch__images custom-img-container'>
                    <img src={brancnBig} alt="" className='branch__big__img' />
                </div>

                <div className="mobile-banner">
                    <p>{lang.SUMMER_CAMP_LEFT} </p>
                </div>
            </section>

        );
    }
}

export default BranchesOpen;
