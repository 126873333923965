import React, { Component } from 'react';

import './footer.css'
import { Link } from 'react-router-dom'



import instagramImg from '../../images/footer/insta.svg'
import facebookImg from '../../images/footer/facebook.svg'
import twitterImg from '../../images/footer/twitter.svg'
import youtubeImg from '../../images/footer/youtube.svg'

import instagram_hovered from '../../images/footer/insta_hovered.svg'
import facebook_hovered from '../../images/footer/facebook_hovered.svg'
import twitter_hovered from '../../images/footer/twitter_hovered.svg'
import youtube_hovered from '../../images/footer/youtube_hovered.svg'


import logo from '../../images/footer/logo.svg'

import shape1 from '../../images/footer/footer_logo_w.svg'
import shape2 from '../../images/footer/midot2021.svg'





class Footer extends Component {
    constructor() {
        super()
        this.state = {
            youtube: false,
            facebook: false,
            instagram: false,
            twitter: false
        }
    }
    componentDidMount() {
        const url = window.location.pathname.split('/')[1]
     
    }
    render() {
        const { lang, managers, selected_language } = this.props
        const { youtube, facebook, instagram, twitter } = this.state
        let urlLang = selected_language === 'עברית' ? '/he' : selected_language === 'English' ? '/en' : selected_language === 'عربى' ? '/ar' : null
        let link = 'https://www.round-up.org.il/%D7%9B%D7%A0%D7%A4%D7%99%D7%99%D7%9D-%D7%A9%D7%9C-%D7%A7%D7%A8%D7%9E%D7%91%D7%95'
        return (
            <section className='footer__wrapper'>
                <div className='footer__flex'>
                    <div className='footer__icons'>
                        <figure><img src={logo} alt="" /></figure>
                        <aside>
                            <ul>
                                <li onMouseEnter={() => this.setState({ youtube: true })}
                                    onMouseLeave={() => this.setState({ youtube: false })}>
                                    <a href="https://www.youtube.com/channel/UC_mPSmRo7szeRiEKsVXyzqQ" target="_blank" rel="noopener noreferrer">
                                        {youtube ?
                                            <img src={youtube_hovered} alt="" /> :
                                            <img src={youtubeImg} alt="" />}
                                    </a>
                                </li>
                                <li onMouseEnter={() => this.setState({ facebook: true })}
                                    onMouseLeave={() => this.setState({ facebook: false })}>
                                    <a href="https://www.facebook.com/KremboWings/" target="_blank" rel="noopener noreferrer">
                                        {facebook ?
                                            <img src={facebook_hovered} alt="" /> :
                                            <img src={facebookImg} alt="" />}
                                    </a></li>
                                <li onMouseEnter={() => this.setState({ instagram: true })}
                                    onMouseLeave={() => this.setState({ instagram: false })}>
                                    <a href="https://www.instagram.com/krembowings/" target="_blank" rel="noopener noreferrer">
                                        {instagram ?
                                            <img src={instagram_hovered} alt="" /> :
                                            <img src={instagramImg} alt="" />}
                                    </a></li>
                                <li onMouseEnter={() => this.setState({ twitter: true })}
                                    onMouseLeave={() => this.setState({ twitter: false })}>
                                    <a href="https://twitter.com/krembowings" target="_blank" rel="noopener noreferrer">
                                        {twitter ?
                                            <img src={twitter_hovered} alt="" /> :
                                            <img src={twitterImg} alt="" />}
                                    </a></li>
                            </ul>
                            <Link to={urlLang + '/donate'}
                                onClick={() => { this.props.resetNavbar(true, true, false) }}>
                                <button>{lang.donate}</button>
                            </Link>
                        </aside>

                        <span>
                            <a href={link} target="_blank" rel="noopener noreferrer"><figure><img src={shape1} alt="" /></figure></a>
                            <figure>
                                <img src={shape2} alt="" />
                            </figure>
                        </span>
                    </div>

                    <div className='footer__sections'>
                        <ul>
                            <Link to={urlLang + '/about'}>
                                {lang.about}
                            </Link>
                            <Link to={urlLang + '/about/values'}>
                                <li onClick={() => this.props.setSection('aboutValues')}>{lang.about_1}</li>
                            </Link>
                            <Link to={urlLang + '/about/howitstarted'}>
                                <li onClick={() => this.props.setSection('aboutStarted')}>{lang.about_2}</li>
                            </Link>
                            <Link to={urlLang + '/about/structure'}>
                                <li onClick={() => this.props.setSection('aboutStructure')}>{lang.about_3}</li>
                            </Link>
                            <Link to={urlLang + '/about/milestones'}>
                                <li onClick={() => this.props.setSection('aboutMilestones')}>{lang.about_4}</li>
                            </Link>
                            <Link to={urlLang + '/about/manage'}>
                                <li onClick={() => this.props.setSection('aboutManage')}>{lang.about_5}</li>
                            </Link>
                            <Link to={urlLang + '/about/boardofdirectors'}>
                                <li onClick={() => this.props.setSection('aboutVaad')}>{lang.about_6}</li>
                            </Link>
                            <Link to={urlLang + '/about/vision'}>
                                <li onClick={() => this.props.setSection('aboutVision')}>{lang.about_7}</li>
                            </Link>
                            <Link to={urlLang + '/about/ceoletter'}>
                                <li onClick={() => this.props.setSection('aboutLast')}>{lang.about_8}</li>
                            </Link>
                        </ul>

                        <span>
                            <ul>

                                <h3>  {lang.knowladge}</h3>

                                <Link to={urlLang + '/vision'}>
                                    <li>{lang.knowladge_1}</li>
                                </Link>
                                <Link to={urlLang + '/symbol'}>
                                    <li>{lang.knowladge_2}</li>
                                </Link>
                                <Link to={urlLang + '/activity'}>
                                    <li>{lang.knowladge_3}</li>
                                </Link>
                                <Link to={urlLang + '/educationalconcept'}>
                                    <li>{lang.knowladge_4}</li>
                                </Link>
                            </ul>
                            <ul>
                                <Link to={urlLang + '/press'}>
                                    {lang.press}
                                </Link>
                                <Link to={urlLang + '/press/projects'} onClick={() => this.props.setSection('pressProjects')}>
                                    <li>{lang.press_1}</li>
                                </Link>
                                <Link to={urlLang + '/press'}>
                                    <li>{lang.press_2}</li>
                                </Link>
                                <Link to={urlLang + '/press/newspapers'} onClick={() => this.props.setSection('pressNews')}>
                                    <li>{lang.press_3}</li>
                                </Link>
                                <Link to={urlLang + '/press/magazines'} onClick={() => this.props.setSection('pressMagazine')}>
                                    <li>{lang.press_4}</li>
                                </Link>
                            </ul>
                        </span>
                        <ul className='footer__contacts'>
                            <Link to={urlLang + '/contact'}>
                                {lang.contact}
                            </Link>
                            {managers ? managers.map((n, i) => <a href={`mailto:${n.ManageEmail}`}
                                key={i} ><li>{n.ManageName} - {n.Manageposition}</li></a>) : null}
                        </ul>
                    </div>

                </div>

                <span className="footer__last">
                    <p className="p-bold">{lang.last_section_1}</p>
                    <p>{lang.last_section_2}</p>
                    <span>
                        <p className="p-bold">{lang.last_section_3}</p>
                        <p>{lang.last_section_4}</p>
                        <p className="p-bold">{lang.last_section_5}</p>
                        <p>{lang.last_section_6}</p>
                    </span>
                    <span>
                        <p className="p-bold">{lang.last_section_7}</p>
                        <p>{lang.last_section_8}</p>
                    </span>

                    <p>{lang.last_section_9}</p>
                </span>

                <span className="footer__last">
                    <span>
                        <p>{lang.last_section_10}</p>
                        <p className="p-bold">{lang.last_section_11}</p>
                        <p>{lang.last_section_12}</p>
                    </span>

                    <p className="p-bold "  >{lang.last_section_13}</p>
                </span>

                {/* TABLET SECTION */}

                <div className="footer__last__tablet">
                    <span>
                        <p className="p-bold">{lang.last_section_1}</p>
                        <p>{lang.last_section_2}</p>
                    </span>
                    <span>
                        <p className="p-bold">{lang.last_section_3}</p>
                        <p>{lang.last_section_4}</p>
                        <p className="p-bold">{lang.last_section_5}</p>
                        <p>{lang.last_section_6}</p>
                    </span>
                    <span>
                        <p className="p-bold">{lang.last_section_7}</p>
                        <p>{lang.last_section_8}</p>
                        <p>{lang.last_section_9}</p>
                    </span>

                    <span>
                        <p>{lang.last_section_10}</p>
                        <p className="p-bold">{lang.last_section_11}</p>
                        <p>{lang.last_section_12}</p>
                        <p className="p-bold"  >{lang.last_section_13}</p>
                    </span>
                </div>


                {/* MOBILE-FOOTER */}

                <div className="footer__flex--mobile footer__flex">
                    <figure><img src={logo} alt="" /></figure>
                    <div className='footer__sections'>
                        <ul className='footer__contacts'>
                            <Link to={urlLang + '/contact'}>
                                {lang.contact}
                            </Link>
                            {managers ? managers.map((n, i) => <a href={`mailto:${n.ManageEmail}`}
                                key={i} ><li>{n.ManageName} - {n.Manageposition}</li></a>) : null}
                        </ul>
                    </div>
                    <div className='footer__sections'>
                        <ul>
                            <Link to={urlLang + '/about'}>
                                {lang.about}
                            </Link>
                            <Link to={urlLang + '/about/values'}>
                                <li onClick={() => this.props.setSection('aboutValues')}>{lang.about_1}</li>
                            </Link>
                            <Link to={urlLang + '/about/howitstarted'}>
                                <li onClick={() => this.props.setSection('aboutStarted')}>{lang.about_2}</li>
                            </Link>
                            <Link to={urlLang + '/about/structure'}>
                                <li onClick={() => this.props.setSection('aboutStructure')}>{lang.about_3}</li>
                            </Link>
                            <Link to={urlLang + '/about/milestones'}>
                                <li onClick={() => this.props.setSection('aboutMilestones')}>{lang.about_4}</li>
                            </Link>
                            <Link to={urlLang + '/about/manage'}>
                                <li onClick={() => this.props.setSection('aboutManage')}>{lang.about_5}</li>
                            </Link>
                            <Link to={urlLang + '/about/boardofdirectors'}>
                                <li onClick={() => this.props.setSection('aboutVaad')}>{lang.about_6}</li>
                            </Link>
                            <Link to={urlLang + '/about/vision'}>
                                <li onClick={() => this.props.setSection('aboutVision')}>{lang.about_7}</li>
                            </Link>
                            <Link to={urlLang + '/about/ceoletter'}>
                                <li onClick={() => this.props.setSection('aboutLast')}>{lang.about_8}</li>
                            </Link>
                        </ul>
                        <span>
                            <ul>
                                <h3>  {lang.knowladge}</h3>
                                <Link to={urlLang + '/vision'}>
                                    <li>{lang.knowladge_1}</li>
                                </Link>
                                <Link to={urlLang + '/symbol'}>
                                    <li>{lang.knowladge_2}</li>
                                </Link>
                                <Link to={urlLang + '/activity'}>
                                    <li>{lang.knowladge_3}</li>
                                </Link>
                                <Link to={urlLang + '/educationalconcept'}>
                                    <li>{lang.knowladge_4}</li>
                                </Link>
                            </ul>
                            <ul>
                                <Link to={urlLang + '/press'}>
                                    {lang.press}
                                </Link>
                                <Link to={urlLang + '/press/projects'} onClick={() => this.props.setSection('pressProjects')}>
                                    <li>{lang.press_1}</li>
                                </Link>
                                <Link to={urlLang + '/press'}>
                                    <li>{lang.press_2}</li>
                                </Link>
                                <Link to={urlLang + '/press/newspapers'} onClick={() => this.props.setSection('pressNews')}>
                                    <li>{lang.press_3}</li>
                                </Link>
                                <Link to={urlLang + '/press/magazines'} onClick={() => this.props.setSection('pressMagazine')}>
                                    <li>{lang.press_4}</li>
                                </Link>
                            </ul>
                        </span>
                    </div>
                </div>



                <div>
                    <span className="footer__last--mobile">
                        <span>
                            <p className="p-bold">{lang.last_section_1}</p>
                            <p>{lang.last_section_2}</p>
                        </span>

                        {/* <span>
                            <p className="p-bold">{lang.last_section_3}</p>
                            <p>{lang.last_section_4}</p>
                            <p className="p-bold">{lang.last_section_5}</p>
                            <p>{lang.last_section_6}</p>
                        </span> */}

                        <span>
                            <p className="p-bold">{lang.last_section_3}</p>
                            <p style={{ whiteSpace: 'nowrap' }}>{lang.last_section_4}</p>
                        </span>
                        <span>
                            <p className="p-bold">{lang.last_section_5}</p>
                            <p>{lang.last_section_6}</p>
                        </span>

                        <span>
                            <p className="p-bold">{lang.last_section_7}</p>
                            <p>{lang.last_section_8}</p>
                        </span>

                        <p>{lang.last_section_9}</p>
                    </span>

                    <span className="footer__last--mobile">
                        <span>
                            <p>{lang.last_section_10}</p>
                            <p className="p-bold">{lang.last_section_11}</p>
                            <p>{lang.last_section_12}</p>
                        </span>

                        <p className="p-bold"  >{lang.last_section_13}</p>
                    </span>
                </div>

                <div className="footer__icons--mobile">
                    <div className='footer__icons'>
                        <ul>
                            <li onMouseEnter={() => this.setState({ youtube: true })}
                                onMouseLeave={() => this.setState({ youtube: false })}>
                                <a href="https://www.youtube.com/channel/UC_mPSmRo7szeRiEKsVXyzqQ" target="_blank" rel="noopener noreferrer">
                                    {youtube ?
                                        <img src={youtube_hovered} alt="" /> :
                                        <img src={youtubeImg} alt="" />}
                                </a></li>
                            <li onMouseEnter={() => this.setState({ facebook: true })}
                                onMouseLeave={() => this.setState({ facebook: false })}>
                                <a href="https://www.facebook.com/KremboWings/" target="_blank" rel="noopener noreferrer">
                                    {facebook ?
                                        <img src={facebook_hovered} alt="" /> :
                                        <img src={facebookImg} alt="" />}
                                </a></li>
                            <li onMouseEnter={() => this.setState({ instagram: true })}
                                onMouseLeave={() => this.setState({ instagram: false })}>
                                <a href="https://www.instagram.com/krembowings/" target="_blank" rel="noopener noreferrer">
                                    {instagram ?
                                        <img src={instagram_hovered} alt="" /> :
                                        <img src={instagramImg} alt="" />}
                                </a></li>
                            <li onMouseEnter={() => this.setState({ twitter: true })}
                                onMouseLeave={() => this.setState({ twitter: false })}>
                                <a href="https://twitter.com/krembowings" target="_blank" rel="noopener noreferrer">
                                    {twitter ?
                                        <img src={twitter_hovered} alt="" /> :
                                        <img src={twitterImg} alt="" />}
                                </a></li>
                        </ul>
                        <Link to={urlLang + '/donate'}
                            onClick={() => { this.props.resetNavbar(true, true, false) }}>
                            <button>{lang.donate}</button>
                        </Link>
                        <span>
                            <a href={link} target="_blank" rel="noopener noreferrer"><figure><img src={shape1} alt="" /></figure></a>
                            <figure><img src={shape2} alt="" /></figure>
                        </span>
                    </div>
                </div>
            </section>
        );
    }
}

export default Footer;
