import React, { Component } from 'react';

import pdf from '../../../images/signup/pdf.svg'
import view from '../../../images/signup/view.png'


class DocsMap extends Component {
    render() {
        const { lang , doc } = this.props
       
        return (
            <div className='docs__element'>
            <div>
                <figure>
                    <img src={doc.img} alt="document"/>
                    <img src={pdf} alt="pdf"/>
                </figure>
                <h3>{lang[doc.text]}</h3>
              <a href={`${doc.link}`} target="_blank" rel="noopener noreferrer"><button><img alt="view" src = {view}/>{lang.view}</button></a>
            </div>
            </div>
        );
    }
}

export default DocsMap;
