import React, { Component } from 'react'

class TextAreaInput extends Component {
    constructor(){
        super()
        this.state = {

        }
    }

    componentWillReceiveProps(nextProps){
        const {state_name, validation, value, id_error, error_msg} = this.props

        if(nextProps.validate != this.props.validate){
            let output
           
             if(value && validation === 'id'){
                let  check = value.length >= 9
              
                this.setState({
                    validation_output:check,
                    error_msg:id_error
                })
                output = true
            }else if(!value){
                this.setState({
                    validation_output:true,
                    error_msg:error_msg
                })
                output = true
            }
        
           
           
        }
    }
    


    render() {
        const { value, state_name, name, big, error_msg } = this.props
        const {validation_output} = this.state
        return (
            <div className={big ? 'tutor__textarea__input tutor__textarea__input__big' : 'tutor__textarea__input'}>
                <h3>{name}</h3>
                <textarea
                value = {value}
                onFocus = {() => this.setState({
                    validation_output:false
                })}
                    onChange={(e) => this.props.updateForm(state_name, e.target.value)}
                ></textarea>
                 {validation_output ? <h5 className='error__msg'>{error_msg}</h5> : ''}
            </div>
        )
    }
}

export default TextAreaInput