import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import './donationthanks.css'

import Thanks from '../../images/krembo-thanks.png'
import Logo from '../../images/navbar/logo.svg'


class SignupThanks extends Component {
    constructor() {
        super()

        this.state = {
            redirected: false
        }
    }


    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        const url = document.URL.split('?')
        if (url[1]) {
            const params = url[1].split('&')
            const personalID = params[5].split('=')[1]
            const uniqueID = params[0].split('=')[1]
        } else {
            this.setState({ redirected: true })
        }
    }

    render() {
        const { redirected } = this.state

        return (
            <div className="donation-thanks">
                {redirected ? <Redirect to="/"/> : null}

                <div className="donation-thanks__signup">
                    <img src={Logo} alt="logo" />
                    <h1>{this.props.lang.success}</h1>
                </div>

                <Link to="/"><button><p>{this.props.lang.BTN}</p></button></Link>
            </div>
        );
    }
}

export default SignupThanks;