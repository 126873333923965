import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Logo from '../../images/navbar/logo.svg'

class RegisterFailed extends Component {
   
    render() {
        
        const { lang } = this.props
      
        return (
            <div className="register__succes register__failed">


                <div className='register__succcess__content'>
                    <img src={Logo} alt="logo" />
                    <h1>{lang.failed}</h1>
                </div>

                <span>
                <Link to="/"><button>{lang.back_to}</button></Link>
                <Link to="/he/register"><button>{lang.back_to_register}</button></Link>
                </span>
            </div>
        )
    }
}

export default RegisterFailed