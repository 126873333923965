import React from 'react'
import Check from '../../../images/event/check.png'
import Checkbox from '@material-ui/core/Checkbox';

const CustomCheckBox = (props) => {
    return (
        <div className={props.checkBoxClass}>
            

            <figure className='checkbox__figure'>
                <Checkbox
                    type="checkbox"
                    checked={props.value}
                    onChange={(e) => {
                        props.onCheck(e, props.form);
                        props.validate(props.formObj, props.validationIndex, props.form)
                    }
                    }
                    name={props.name}

                />
            </figure>


            <p>{props.text}</p>
        </div>

    );
};

export default CustomCheckBox;