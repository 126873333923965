import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import Logo from '../../images/navbar/logo.svg'
import { getCookie, removeCookie, setCookie } from '../../tools/cookies';
import { isArray } from 'lodash'
import axios from 'axios'
import { API } from '../../tools/keys';
import './parts/parts.css'


class RegisterSuccess extends Component {
    constructor() {
        super()
        this.state = {
            redirected: false,
            initUnload: true
        }
    }



    onUnload = (event) => {
     

    }



    async componentDidMount() {
        window.scroll(0, 0)
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("beforeunload", this.onUnload, false);
        const params = Object.fromEntries(new URLSearchParams(window.location.search));
        if(params.parents_approval) {

        } else {
            let cookie = localStorage.getItem('member_info')
            if(!isArray(cookie) ) cookie = [cookie]
            if (cookie) {
                this.setState({ loading: true })
              
                await Promise.all(cookie.map(async n => {
                  
                    await axios.post(`${API}/bin/createdoc?type=member`, { bin_id: n })
                    return true
                }))
                this.setState({ loading: false })
            } else {
    
            }
            
        }


    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload, false);

        removeCookie('member_info')
        removeCookie('members_info')
    }



    render() {

        const { lang } = this.props
        const { redirected, loading } = this.state
        return (
            !loading ? <div className="register__succes">
                {redirected ? <Redirect to="/" /> : null}

                <div className='register__succcess__content'>
                    <img src={Logo} alt="logo" />
                    <h1>{lang.success}</h1>
                    <Link to="/"><button>{lang.back_to}</button></Link>
                </div>


            </div>
                :
                <section className='success__loader'>
                    <aside>
                    <div id="loader">
                        <div id="shadow"></div>
                        <div id="box"></div>

                    </div>
                    <span>
                        <h3>{lang.LOADING_H3}</h3>
                        <h2>{lang.LOADING_H2}</h2>
                    </span>
                    </aside>
                </section>
        )
    }
}

export default RegisterSuccess