import React, { Component } from 'react';
import {Link} from 'react-router-dom'


import participants1 from '../../../../images/mainpage/sections/participants1.png'
import participants2 from '../../../../images/mainpage/sections/participants2.png'
import participants3 from '../../../../images/mainpage/sections/participants3.png'
import participants4 from '../../../../images/mainpage/sections/participants4.png'
import participants5 from '../../../../images/mainpage/sections/participants5.png'
import participants6 from '../../../../images/mainpage/sections/participants6.png'


import './sections.css'



class Participants extends Component {
    render() {
        const { lang } = this.props
     
        return (
            <section className='participants__wrapper'>
            <h1 className="hidden-h1">{lang.PARTICIPANTS_H1}</h1>
                {/* <img src={participants_figure} alt="" className='participants__figure'/> */}
                <div className='participants__text__section'>
                    <h1>{lang.PARTICIPANTS_H1}</h1>
                    <h2>{lang.PARTICIPANTS_H2}</h2>
                    <p className='participants__no__margin'>{lang.PARTICIPANTS_P}</p>
                    <h3>{lang.PARTICIPANTS_H3}</h3>
                    <p className='participants__no__margin'>{lang.PARTICIPANTS_P2_SMALL}</p>
                    <p>{lang.PARTICIPANTS_P2}</p>
                    <h2>{lang.PARTICIPANTS_H2_2}</h2>
                    <p>{lang.PARTICIPANTS_P3}</p>
                    <h2>{lang.PARTICIPANTS_H2_3}</h2>
                    <p>{lang.PARTICIPANTS_P4}</p>
                    <h2>{lang.PARTICIPANTS_H2_4}</h2>
                    <p>{lang.PARTICIPANTS_P5}</p>
                    <Link to={`/${lang.langString}/register`} onClick={()=>{
                        document.body.className = document.body.className.replace("hidden__body","");}}><button className='sections__button'>{lang.PARTICIPANTS_btn}</button></Link>
                </div>
                <div className='participants__images'>
                    <figure><img src={participants1} alt=""/></figure>
                    <figure><img src={participants2} alt=""/></figure>
                    <figure><img src={participants3} alt=""/></figure>
                    <figure><img src={participants4} alt=""/></figure>
                    <figure><img src={participants5} alt=""/></figure>
                    <figure><img src={participants6} alt=""/></figure>
                </div>
            </section>
        );
    }
}

export default Participants;
