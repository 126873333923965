import React, { Component } from 'react';



import poland1 from '../../../../images/mainpage/sections/poland1.png'
import poland2 from '../../../../images/mainpage/sections/poland2.png'
import poland3 from '../../../../images/mainpage/sections/poland3.png'
import poland4 from '../../../../images/mainpage/sections/poland4.png'
import poland5 from '../../../../images/mainpage/sections/poland5.png'
import poland6 from '../../../../images/mainpage/sections/poland6.png'


import './sections.css'



class PolinTravel extends Component {
    render() {
        const { lang } = this.props
        return (
            <section className='poland__wrapper'>
             <h1 className="hidden-h1">{lang.POLAND_H1}</h1>
            {/* <img src={poland_figure} alt="" className='poland__figure'/> */}
                <div className='poland__text__section'>
                    <h1>{lang.POLAND_H1}</h1>
                    <h2>{lang.POLAND_H2}</h2>
                    <p>{lang.POLAND_P1}</p>
                    <p>{lang.POLAND_P2}</p>
                    <p>{lang.POLAND_P3}</p>
                    <h3>{lang.POLAND_H3}</h3>
                    <p>{lang.POLAND_P4}</p>
                </div>
                <div className='poland__images'>
                    <figure><img src={poland1} alt=""/></figure>
                    <figure><img src={poland2} alt=""/></figure>
                    <figure><img src={poland3} alt=""/></figure>
                    <figure><img src={poland4} alt=""/></figure>
                    <figure><img src={poland5} alt=""/></figure>
                    <figure><img src={poland6} alt=""/></figure>
                </div>
            </section>
        );
    }
}

export default PolinTravel;
