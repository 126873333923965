import React, { Component } from 'react';

import DocsMap from './Parts/DocsMap'

import docs from '../../text/signup/docs'

import mobile__img from '../../images/signup/122.png'


import './signup.css'
import {Link} from 'react-router-dom'
import Logo from '../../images/krembo-icon.svg'

class SignUp extends Component {
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        const { lang,selected_language } = this.props
        let urlLang = selected_language === 'עברית' ? '/he' : selected_language === 'English' ? '/en' : selected_language === 'عربى' ? '/ar' : null
        return (
            lang ? <section className='signup__wrapper'>
                <header>
                    <div className='signup__header__text'>
                        <h1>{lang.header_h1}</h1>
                        <p>{lang.header_text_1}</p>
                        <p>{lang.header_text_2}</p>
                        <p className='mobile__signup__text'>{lang.header_text_1} {lang.header_text_2}</p>
                        <h5>{lang.header_small}</h5>
                        <Link to={urlLang + '/register'}>
                           
                            <button> 
                            <img src={Logo} alt=""/>
                               <h3> {lang.header_btn}</h3>
                                
                                </button></Link>
                    </div>
                </header>


                <section className='signup__docs__wrapper'>
                    <h1>{lang.docs_h1}</h1>
                    <div className='signup__docs__flex'>
                        {docs ? docs.map((n, i) => <DocsMap key={i} doc={n} lang={lang} />) : null}
                    </div>
                </section>

                <section className='signup__adopt'>
                <img src={mobile__img} alt="" className='mobile__img__signup'/>
                    <span>
                        <h2>{lang.adopt_h2}</h2>
                       <Link to= {urlLang + '/donate'} onClick = {() => this.props.resetNavbar(true, true,false)} ><button>{lang.adopt_btn}</button></Link>
                    </span>
                </section>
            </section> : null
        );
    }
}

export default SignUp;
