import React, { Component } from 'react';
import CountUp from 'react-countup';
import SectionsMobile from './Parts/SectionsMobile'
import MobileStories from './Parts/MobileStories'
import MobileSponsors from './Parts/MobileSponsors'
import SectionPart from './Parts/SectionsPart'
import StoriesSlider from './Parts/StoriesSlider'
import SponsorsSlider from './Parts/SponsorsSlider'
import VideosIframe from './Parts/VideosIframe'
import AwardsPart from './Parts/AwardsPart'
import { MAIN_PAGE_IMAGES } from '../../tools/keys'
import partnerDocs from '../../docs/reports/Partners and Donors.pdf'
import whiteLogo from '../../images/mainpage/logo-white.svg'
import SectionsContainer from './Parts/Sections/SectionsContainer'
//main page pic - revert to this when banner over
import MainBg from '../../images/mainpage/mainBg.png'
// import MainBgBannerDesk from '../../images/mainpage/mainBannerDesk2.jpg'
// import MainBgBannerMob from '../../images/mainpage/mainBannerMob.jpg'
// import MainBgBannerDeskEng from '../../images/mainpage/enghomepic2.jpg'
// import MainBgBannerMobEng from '../../images/mainpage/enghomepicmob.jpg'

import MainBgBannerDesk from '../../images/new_banner.jpeg'
import MainBgBannerMob from '../../images/mobile-banner.jpg'
import MainBgBannerDeskEng from '../../images/desktop-banner-english.jpg'
import MainBgBannerMobEng from '../../images/mobile-banner-english.jpg'

import Shape1 from '../../images/mainpage/shape1.png'
import Shape2 from '../../images/mainpage/shape2.png'
import people from '../../images/mainpage/Active.svg'
import house from '../../images/mainpage/Branches.svg'
import './mainpage.css'
import { Link } from 'react-router-dom'
//
import Small_shape from '../../images/about/small_shape.png'
import Big_shape from '../../images/about/big_shape.png'

import Values_shape from '../../images/about/Path_9.png'
import valueImg1 from '../../images/about/wheel-chaircopy.svg'
import valueImg2 from '../../images/about/Group54.svg'
import valueImg3 from '../../images/about/icon22.svg'
import valueImg4 from '../../images/about/Group11Copy.svg'

import bigImg from '../../images/knowladge/vision_big.png'

import vision1 from '../../images/knowladge/icons/hands.png'
import vision2 from '../../images/knowladge/icons/fist.png'
import vision3 from '../../images/knowladge/icons/heart.png'
import vision4 from '../../images/knowladge/icons/people.png'
import vision5 from '../../images/knowladge/icons/Shape.png'
import vision6 from '../../images/knowladge/icons/israel.svg'



class MainPage extends Component {
    constructor() {
        super()

        this.home1 = React.createRef();
        this.home2 = React.createRef();
        this.home22 = React.createRef();
        this.home3 = React.createRef();
        this.home4 = React.createRef();

        this.state = {
            show_section: false,
            imgLoaded: false,
            section_id: '',
            iframe: false,
            url: '',
            page_loaded: false,
            section_open: false,
            bannerMobile: false
        }
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        const { section } = this.props

        if (section) {
            this.scrollToMyRef(section)
        }

        if (window.innerWidth < 600) {
            this.setState({ bannerMobile: true })
        } else {
            this.setState({ bannerMobile: false })
        }
        window.addEventListener('resize', this.resize)
        window.scrollTo(0, 0)
        setTimeout(() => {
            this.setState({
                page_loaded: true
            })
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        // this.forceUpdate()
        if (window.innerWidth < 600) {
            this.setState({ bannerMobile: true })
        } else {
            this.setState({ bannerMobile: false })
        }
    }

    SetSectionID = (id) => {
        this.setState({
            show_section: true,
            section_id: id
        })
    }



    componentWillReceiveProps(nextProps) {

    }



    scrollToMyRef = (section) => {
        if (section === 'home1') {
            let viewportOffset = this.home1.current.getBoundingClientRect();
            let top = viewportOffset.top;

            window.scrollTo({
                top: top - 150,
                behavior: "smooth"
            })
        }
        else if (section === 'home2') {
            let viewportOffset = this.home2.current.getBoundingClientRect();
            let top = viewportOffset.top;
            window.scrollTo({
                top: top - 150,
                behavior: "smooth"
            })
        }
        else if (section === 'home22') {
            let viewportOffset = this.home22.current.getBoundingClientRect();
            let top = viewportOffset.top;
            window.scrollTo({
                top: top - 150,
                behavior: "smooth"
            })
        }
        else if (section === 'home3') {
            let viewportOffset = this.home3.current.getBoundingClientRect();
            let top = viewportOffset.top;
            window.scrollTo({
                top: top - 150,
                behavior: "smooth"
            })
        }
        else if (section === 'home4') {
            let viewportOffset = this.home4.current.getBoundingClientRect();
            let top = viewportOffset.top;
            window.scrollTo({
                top: top - 150,
                behavior: "smooth"
            })
        }

    }



    CloseSectionID = () => {
        this.setState({
            show_section: false,
            section_id: ''
        })
        let body = document.querySelector('body')
        body.classList.remove('hidden__body')
    }


    closeIframe = (e) => {
        if (e.target.classList.value === 'iframe__overlay' || 'iframe__close__btn') {
            this.setState({
                url: '',
                iframe: false
            })
        }

    }


    playIframeVideo = (url) => {
        this.setState({
            url: url,
            iframe: true
        })
    }

    OpenSection = () => {
        let body = document.querySelector('body')
        body.classList.add('hidden__body')
    }




    render() {
        const {
            lang,
            selected_language,
            section
        } = this.props
        const {
            show_section,
            section_id,
            iframe,
            url,
            page_loaded,
            bannerMobile
        } = this.state
        let urlLang = selected_language === 'עברית' ?
            '/he' : selected_language === 'English' ?
                '/en' : selected_language === 'عربى' ?
                    '/ar' : null



        const bannerSrc =
            (urlLang.includes('he') &&
                !bannerMobile) ||
                (urlLang.includes('ar') &&
                    !bannerMobile) ?
                MainBgBannerDesk :
                (urlLang.includes('he') &&
                    bannerMobile) ||
                    (urlLang.includes('ar') &&
                        bannerMobile) ?
                    MainBgBannerMob :
                    (urlLang.includes('en') &&
                        !bannerMobile) ?
                        MainBgBannerDeskEng :
                        (urlLang.includes('en') &&
                            bannerMobile) ?
                            MainBgBannerMobEng :
                            MainBgBannerDesk


        // let lang2 = {
        //     MAIN_PAGE_HEADING_PART_1: `כ-3,000 ילדים בעלי צרכים מיוחדים מחכים להצטרף`,
        //     MAIN_PAGE_HEADING_PART_2: `ל׳כנפיים של קרמבו׳`,
        //     eng1: `More than 3000 children with disabilities are waiting to join`,
        //     eng2: '`Krembo Wings`'
        // }

        return (
            <section className='main__page__wrapper'>
                <button className='click__placeholder'
                    onClick={() => { this.props.resetNavbar(true, false, false) }}
                ></button>
                {iframe && url ?
                    <VideosIframe
                        closeIframe={this.closeIframe}
                        url={url} /> : null}
                {show_section ?
                    <SectionsContainer
                        lang={lang}
                        section_id={section_id}
                        CloseSectionID={this.CloseSectionID} /> :
                    null}
                <header
                    onClick={() => {
                        window.location = urlLang + '/donate'
                    }}
                    className='main__page__header'>
                    <img src={this.state.bannerMobile ?
                        bannerSrc : bannerSrc}
                        alt="four members of krembo wings"
                        onLoad={() => {
                            this.setState({ imgLoaded: true });
                        }}
                        className={this.state.imgLoaded ?
                            "main__page__header__bg main__page__header__bg--active" :
                            "main__page__header__bg"} />


                    <div className='main__page__header__main-heading'>
                        {/* <h1>
                            <span>
                                {lang.MAIN_PAGE_HEADING_PART_1}
                            </span>
                            <span>
                                {lang.MAIN_PAGE_HEADING_PART_2}
                            </span>
                        </h1> */}

                        <aside className="banner__custom">
                            {/* <Link to={urlLang + '/signup'}>
                                <button
                                    className='banner__custom__btn banner__custom__btn--1'>{lang.TOP_PAGE_NUTTON_SIGNUP}</button>
                            </Link>
                            <Link
                                to={urlLang + '/donate'}
                                >
                                <button
                                    className='banner__custom__btn banner__custom__btn--2'>{lang.TOP_PAGE_NUTTON_DONATE}</button>
                            </Link> */}
                        </aside>
                    </div>





                    <div className='main__page__numbers'>
                        <aside className='big__number__main'>
                            <img src={people} alt="kids" />
                            <h2>
                                {page_loaded ?
                                    <CountUp
                                        start={0}
                                        end={6500}
                                        duration={3}
                                        separator=","
                                        decimals={0} >

                                    </CountUp> : '0'}
                            </h2>

                            <span>
                                <h3>{lang.TOP_PAGE_NUMBERS_RIGHT_H2}</h3>
                                <h4 className="krembo__wings__title__h1">{lang.TOP_PAGE_NUMBERS_RIGHT_H3}</h4>
                            </span>
                        </aside>

                        <aside className='small__number__main'>
                            <img src={house} alt="house" />
                            <h2> {page_loaded ?
                                <CountUp
                                    start={0}
                                    end={75}
                                    duration={3}
                                    separator=","
                                    decimals={0} ></CountUp> : '0'}</h2>
                            <span>
                                <h3>{lang.TOP_PAGE_NUMBERS_LEFT_H2}</h3>
                                <h4>{lang.TOP_PAGE_NUMBERS_LEFT_H3}</h4>
                            </span>
                        </aside>

                    </div>
                </header>




                <div className='main__page__sections'>

                    <h1 ref={this.home1}
                        className='section__h1'>{lang.our_sections}</h1>

                    <div className='main__page__sections__flex'>
                        <SectionPart
                            lang={lang}
                            SetSectionID={this.SetSectionID}
                            CloseSectionID={this.CloseSectionID}
                            OpenSection={this.OpenSection} />
                        <SectionsMobile
                            lang={lang}
                            SetSectionID={this.SetSectionID}
                            CloseSectionID={this.CloseSectionID}
                            OpenSection={this.OpenSection} />
                    </div>



                </div>

                <div className='main__page__video'>

                    <section>
                        <aside>

                            <VideosIframe
                                remove_bars={true}
                                url='https://www.youtube-nocookie.com/embed/eFAjkvzx1Tw?controls=0&showinfo=0&rel=0&loop=1' />
                        </aside>
                        <div>
                            <h2>{lang.BIG_VIDEO_H1}</h2>
                            <br />
                            <p>{lang.VIDEO_SECTION_P1}</p>
                            <br />
                            <h2>{lang.VIDEO_SECTION_H2} <span>{lang.VIDEO_SECTION_PAYBOX}</span></h2>
                            <br />
                            <p>{lang.VIDEO_SECTION_P2}</p>

                            <Link
                                to={urlLang + '/donate'}
                                >
                                <button

                                >{lang.DONATE}</button>
                            </Link>
                        </div>
                    </section>

                </div>

                {MAIN_PAGE_IMAGES ?
                    <div className='mainpage__img__section'>
                        <h2 ref={this.home2}>{lang.MAIN_DONATE_CHANGE}</h2>
                        <aside>
                            <figure> <img
                                className='mainpage__img__section__big__img'
                                src={MAIN_PAGE_IMAGES[0]} alt="" /></figure>

                            <div className='mainpage__img__section__bottom'><figure>
                                <img src={valueImg1}
                                    alt="megaphone and krembo wings logo" />
                            </figure>
                                <h3>{lang.value4_h3}</h3>
                                <p>{lang.value4_p}</p>
                            </div>

                        </aside>
                        <aside>
                            <figure>  <img
                                className='mainpage__img__section__big__img'
                                src={MAIN_PAGE_IMAGES[1]} alt="" /></figure>


                            <div className='mainpage__img__section__bottom'><figure>
                                <img src={valueImg3} alt="" />
                            </figure>
                                <h3>{lang.value3_h3}</h3>
                                <p>{lang.value3_p}</p>
                            </div>
                        </aside>
                        <aside>
                            <figure><img
                                className='mainpage__img__section__big__img'
                                src={MAIN_PAGE_IMAGES[2]} alt="" /></figure>

                            <div className='mainpage__img__section__bottom'>
                                <figure>
                                    <img src={valueImg2}
                                        alt="two heads and a heart" />
                                </figure>
                                <h3>{lang.value2_h3}</h3>
                                <p>{lang.value2_p}</p>
                            </div>
                        </aside>
                        <aside>
                            <figure><img
                                className='mainpage__img__section__big__img'
                                src={MAIN_PAGE_IMAGES[3]} alt="" /></figure>
                            <div
                                className='mainpage__img__section__bottom'
                            >
                                <figure>
                                    <img
                                        src={valueImg4}
                                        alt="wheelchair" />
                                </figure>
                                <h3>{lang.value1_h3}</h3>
                                <p>{lang.value1_p}</p>
                            </div>

                        </aside>
                    </div>
                    : null}
                {/* <div className='about__values__flex'>
                    <span>
                       
                       
                      
                     
                    </span>

                    <Link to={urlLang + '/donate'}>
                        <button className='sponsors__donate'
                            onClick={() => { this.props.resetNavbar(true, false, false) }}>{lang.DONATE}</button>
                    </Link>

                </div> */}


                <div className='mainpage__slider__stories'>
                    <h1 className='section__h1'>{lang.STORIES_H1}</h1>
                    <div className='stories__slider__box'>
                        <StoriesSlider lang={lang}
                            playIframeVideo={this.playIframeVideo} />
                    </div>
                    <div className='stories__mobile__slider'>
                        <MobileStories lang={lang}
                            playIframeVideo={this.playIframeVideo} />
                    </div>
                    <img src={Shape1} alt='long blue line'
                        className='mainpage__stories__big__img' />
                    <img src={Shape2} alt="heart"
                        className='mainpage__stories__small__img' />
                </div>

                {/*  */}





                {/*  */}

                <div className="mainpage__vision__wrapper">
                    <h1 ref={this.home22}>{lang.vision_header}</h1>
                    <section className="mainpage__vision__wrapper__first">
                        <img src={bigImg} alt="vision" />
                        <div>
                            <h4>{lang.create_h2}</h4>
                            <span>
                                <h6>{lang.create1_h3}</h6>
                                <p>{lang.create1_p}</p>
                            </span>
                            <span>
                                <h6>{lang.create2_h3}</h6>
                                <p>{lang.create2_p}</p>
                            </span>
                            <span>
                                <h6>{lang.create3_h3}</h6>
                                <p>{lang.create3_p}</p>
                            </span>
                        </div>
                    </section>

                    <section className="mainpage__vision__wrapper__second">
                        <span>
                            <div>
                                <img src={vision4} alt="manifesto" />
                                <h5>{lang.circle6_h3}</h5>
                                <p>{lang.circle6_p}</p>
                            </div>
                            <div>
                                <img src={vision5} alt="manifesto" />
                                <h5>{lang.circle1_h3}</h5>
                                <p>{lang.circle1_p}</p>
                            </div>
                            <div>
                                <img src={vision3} alt="manifesto" />
                                <h5>{lang.circle2_h3}</h5>
                                <p>{lang.circle2_p}</p>
                            </div>
                            {/* </span>

                        <span> */}
                            <div>
                                <img src={vision1} alt="manifesto" />
                                <h5>{lang.circle4_h3}</h5>
                                <p>{lang.circle4_p}</p>
                            </div>
                            <div>
                                <img src={vision2} alt="manifesto" />
                                <h5>{lang.circle5_h3}</h5>
                                <p>{lang.circle5_p}</p>
                            </div>
                            <div>
                                <img src={vision6} alt="manifesto" />
                                <h5>{lang.circle3_h3}</h5>
                                <p>{lang.circle3_p}</p>
                            </div>
                        </span>

                    </section>
                </div>

                {/*  */}

                <div className='mainpage__sponsors__wrapper'>

                    <h1 className='section__h1'
                        ref={this.home3}>{lang.PARTNERS}</h1>
                    <div className='sponsors__slider'>
                        <SponsorsSlider lang={lang} />
                    </div>

                    <div className='mobile__sponsors__slider'>
                        <MobileSponsors lang={lang} />
                    </div>
                    <a href={partnerDocs}
                        target="_blank"
                        rel="noopener noreferrer">
                        <button className='sponsors__list'>{lang.FULL_LIST}</button></a>
                </div>

                <div className='mainpage__awards'>
                    <h1 className='section__h1'
                        ref={this.home4}>{lang.AWARDS_H1}</h1>
                    <AwardsPart lang={lang} />
                </div>
            </section>
        );
    }
}

export default MainPage;
