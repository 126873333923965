

import pdf1 from '../../docs/signup/מכתב פתיחת שנה לפעילים והוריהם 20-21.pdf'
import pdf2 from '../../docs/signup/מדיניות ביטולים 20-21.pdf'
import pdf3 from '../../docs/signup/טופס ביטול והחזר דמי רישום שנתיים.pdf'
import pdf4 from '../../docs/signup/מדיניות ביטולים.pdf'
import pdf5 from '../../docs/signup/טופס ביטול הרשמה לסמינרים ומפעלים.pdf'
import pdf6 from '../../docs/signup/tofesBitul.71a66602.pdf'
import pdf7 from '../../docs/signup/חוזר למחנה קיץ 2019.pdf'
import pdf8 from '../../docs/signup/טופס ביטול הרשמה למחנה קיץ וורד.pdf'


import img1 from '../../images/signup/1.png'
import img2 from '../../images/signup/2.png'
import img3 from '../../images/signup/3.png'
import img4 from '../../images/signup/4.png'
import img5 from '../../images/signup/5.png'
import img6 from '../../images/signup/6.png'


const docs = [
    {
        text:'doc1_text',
        img:img1,
        link:pdf1
    },
    {
        text:'doc2_text',
        img:img2,
        link:pdf2
    },
    {
        text:'doc3_text',
        img:img3,
        link:pdf3
    },
    {
        text:'doc4_text',
        img:img4,
        link:pdf4
    },
    // {
    //     text:'doc5_text',
    //     img:img5,
    //     link:pdf5
    // },
    // {
    //     text:'doc6_text',
    //     img:img6,
    //     link:pdf6
    // },
    // {
    //     text:'doc7_text',
    //     img:img6,
    //     link:pdf7
    // },
    // {
    //     text:'doc8_text',
    //     img:img6,
    //     link:pdf8
    // }
]   

export default docs