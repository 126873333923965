import React from 'react';
import Radio from '@material-ui/core/Radio';

const CustomDoubleCheckBox = (props) => {
    return (
        <div className={props.doubleCheckBoxClass}>
            <span>
           <figure>
           <Radio
                    type="checkbox"
                   name={props.name}
                    checked={!props.value}
                    onChange={(e) => {
                        props.onDoubleCheck(e, props.form, false);
                        props.validate(props.formObj, props.validationIndex, props.form)
                    }}

                />
           </figure>
               
                    <span name={props.name}></span>
                <p>{props.text[0]}</p>
            </span>

            <span>
                <figure>
                <Radio
                    type="checkbox"
                   name={props.name}
                    checked={props.value &&
                        typeof props.value !== "object" ? true : ''}
                    onChange={(e) => {
                        props.onDoubleCheck(e, props.form, true);
                        props.validate(props.formObj, props.validationIndex, props.form)
                    }}

                />
                </figure>
              
                    <span name={props.name}></span>
                <p>{props.text[1]}</p>
            </span>
        </div>
    );
};

export default CustomDoubleCheckBox;