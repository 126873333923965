import React, { Component } from 'react'
import BranchesOpen from './BranchesOpen'
import Education from './Education'
import Service from './Service'
import Participants from './Participants'
import PolinTravel from './PolinTravel'
import SummerCamp from './SummerCamp'

import Scrollbar from 'react-scrollbars-custom'
import { connect } from 'react-redux'
import * as actions from '../../../../actions/actions'



import figure1 from '../../../../images/mainpage/sections/summer_figure.svg'
import service_figure from '../../../../images/mainpage/sections/service_figure.svg'
import poland_figure from '../../../../images/mainpage/sections/poland_figure.svg'
import participants_figure from '../../../../images/mainpage/sections/participants_figure.svg'
import education_figure from '../../../../images/mainpage/sections/training_figure.svg'
import brancnFigure from '../../../../images/mainpage/sections/branch_figure.svg'

import './sections.css'

class SectionsContainer extends Component {
    constructor() {
        super()
        this.state = {
            figure: false
        }
    }


    ClickOutside(e) {
        let check = e.target.classList.value === 'sections__overlay'
        if (check) {
            this.props.CloseSectionID()
        }
    }

    render() {
        const { lang, section_id } = this.props
        const { selected_language } = this.props.main
        const { figure } = this.state
        return (
            <div className='sections__wrapper' onClick={(e) => this.ClickOutside(e)}>

                <figure className='section__close__btn section__close__btn--outher' onClick={() => this.props.CloseSectionID()}><div></div><div></div></figure>
                <figure className='sections__overlay'></figure>
                <div className='section__container'>
                    {section_id === 1 ? <aside className='sections__figure'> {lang.SUMMER_CAMP_LEFT}</aside> :
                        section_id === 2 ? <aside className='sections__figure'> {lang.NEW_BRANCH_STICKER}</aside> : null
                    }


                    {section_id === 1 ? <img src={figure1} alt="" className='summer__figure general__figure'/> : null}
                    {section_id === 2 ? <img src={brancnFigure} alt="" className='branch__figure general__figure' /> : null}
                    {section_id === 3 ? <img src={education_figure} alt="" className='education__figure general__figure'/> : null}
                    {section_id === 4 ? <img src={participants_figure} alt="" className='participants__figure general__figure'/> : null}
                    {section_id === 5 ? <img src={poland_figure} alt="" className='poland__figure general__figure'/> : null}
                    {section_id === 6 ? <img src={service_figure} alt="" className='service__figure general__figure' /> : null}


                    <Scrollbar style={{ height: '100%' }} noScrollX rtl={selected_language === 'English' || selected_language === 'english'}>
                        <figure className='section__close__btn section__close__btn--inner' onClick={() => this.props.CloseSectionID()}><div></div><div></div></figure>
                        {section_id === 1 ? <SummerCamp CloseSectionID={this.props.CloseSectionID} lang={lang} selected={selected_language } /> : null}
                        {section_id === 2 ? <BranchesOpen CloseSectionID={this.props.CloseSectionID} lang={lang} selected={selected_language } /> : null}
                        {section_id === 3 ? <Education CloseSectionID={this.props.CloseSectionID} lang={lang} selected={selected_language } /> : null}
                        {section_id === 4 ? <Participants CloseSectionID={this.props.CloseSectionID} lang={lang} selected={selected_language } /> : null}
                        {section_id === 5 ? <PolinTravel CloseSectionID={this.props.CloseSectionID} lang={lang} selected={selected_language } /> : null}
                        {section_id === 6 ? <Service CloseSectionID={this.props.CloseSectionID} lang={lang} selected={selected_language } /> : null}
                    </Scrollbar>
                </div>

            </div>
        );
    }
}




function stateToProps({ main }) {
    return { main }
}

export default connect(stateToProps, actions)(SectionsContainer)