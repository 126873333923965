import React, { Component } from 'react';
import Iframe from 'react-iframe'



class VideosIframe extends Component {
    constructor(){
        super()
        this.state = {
            button:false
        }
    }


    componentDidMount(){
        if(this.props.removeBars) {
            this.setState({ remove_bars: true })
        }
        setTimeout(() => {
                this.setState({
                    button:true
                })
        }, 700);
    }
    render() {
        const { url } = this.props
        const { button, remove_bars } = this.state
        return (
            url ?
                <div className='iframe__overlay' onClick={(e) => this.props.closeIframe(e)}>
                    <div 
                    className={`
                    ${url ? 'iframe__wrapper iframe__wrapper--active'  : 'iframe__wrapper iframe__wrapper--disabled'}
                    ${!remove_bars ? 'video-wrapper' : ''}
                    `}>
                       {button  ? <button  className='iframe__close__btn' onClick={(e) => this.props.closeIframe(e)}><div></div>
                            <div></div></button> : null}
                        <Iframe url={url}

                            allowFullScreen
                            className="iframe__window"
                            display="initial"
                            position="relative" />
                    </div>
                </div> : null
        );
    }
}

export default VideosIframe;