import React from 'react'
import { DIFF_SUM, DIFF_SUM_EN } from './FormHoliday'

export default props => {
    const {
        onClick,
        amount,
        radio_amount
    } = props
    return <div 
    onClick={() => onClick(amount)}
    className={`${radio_amount===amount ? 'checked_radio_donation_amount' : ''} radio_box_donation_amount`}>
    {/* <div className="Radio_button_amount">
        {radio_amount === amount ? <div /> : ''}
    </div> */}
<p> {amount}</p> <p>{amount === DIFF_SUM || amount == DIFF_SUM_EN ? '' : '₪ '}</p>
        </div>
}