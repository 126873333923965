import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TimeInput from 'react-time-input';


class HoursSelect extends Component {



    onTimeChangeHandler = (val) => {

       const {state_name} = this.props
        this.props.updateForm(state_name, val)
    }


    render() {




        const { name, state_name, value } = this.props


        return (
            <div className='tutor__input hour__selector'>
                <h3>{name}</h3>
                <TimeInput
                    initTime={value}
                    ref="TimeInputWrapper"
                    className='form-control'

                    onTimeChange={this.onTimeChangeHandler}
                />
            </div>
        )
    }
}

export default HoursSelect