import React, { Component } from 'react'
import TutorSelect from './small-parts/TutorSelect';
import TutorInput from './small-parts/TutorInput';
import RadioInput from './small-parts/RadioInput';

class EducationalFramerwork extends Component {
    constructor(){
        super()
        this.state = {

        }
    }

    submitForm = async (e, val) => {
        let check = ''
        this.setState({
            errors:[]
        })
        const { has_errors } = this.state
        e.preventDefault()
        this.setState({
            validate: true
        })
        setTimeout(() => {
            this.setState({
                validate: false
            })
        }, 100);
        setTimeout(async ()  => {
            check = await document.querySelector('.error__msg')
           if(check){
               
           }else{
          
          this.props.changeSection(2)
           }
        }, 100);
       
     
     
    };





    render () {

        const {validate} = this.state
        const {values, lang} = this.props
        const {
            program_type,
            program_name,
            hostel_name,
            pickup_place,
            member_at_moadonit} = values
        return (
            <form className='tutor__framework__form'
            onSubmit = {(e) => this.submitForm(e)}
            > 
                    <section>
                    <TutorSelect
                    name = {lang.FRAMEWORK}
                    options = {lang.PROGRMS_TYPES}
                    value = {program_type}
                    state_name = 'program_type'
                    updateForm = {this.props.updateForm}
                    
                    />
                     <TutorInput 
                      updateForm = {this.props.updateForm}
                      value = {program_name}
                      state_name = 'program_name'
                      name = {lang.FRAMEWORK_NAME}
                      type = {'text'}
                      validate={validate}
                      error_msg = {lang.ERR}
                      />
                      <TutorInput 
                      updateForm = {this.props.updateForm}
                      value = {hostel_name}
                      state_name = 'hostel_name'
                      name = {lang.HOSTEL}
                      type = {'text'}
                     
                      />
                       <TutorSelect
                    name = {lang.PICKUP_PLACE}
                    options = {lang.PICKUP_PLACES}
                    value = {pickup_place}
                    state_name = 'pickup_place'
                    updateForm = {this.props.updateForm}
                   
                    />
                    </section>

                    <section>
                <RadioInput
                value = {member_at_moadonit ? member_at_moadonit : 'לא'}
                state_name = 'member_at_moadonit'
                options = {['כן', 'לא']}
                name ={lang.MOADONIT}
                updateForm = {this.props.updateForm}
                />
                    </section>
                    <aside class='tutor__form__submit'>
                   <h2>↙</h2>
               <input type="submit" value = 'המשך'/>
               </aside>
            </form>
        )
    }
}

export default EducationalFramerwork