import React, { Component } from 'react';


import serviceBig from '../../../../images/mainpage/sections/service_big.png'
import service_figure from '../../../../images/mainpage/sections/service_figure.svg'

import './sections.css'


class Service extends Component {
    render() {
        const { lang } = this.props
        return (
            <section className='service__wrapper'>
             <h1 className="hidden-h1">{lang.SERVICE_H1}</h1>
                {/* <img src={service_figure} alt="" className='service__figure' /> */}
                <div className='service__text'>
                    <h1>{lang.SERVICE_H1}</h1>
                    <h2>{lang.SERVICE_H2}</h2>
                    <p>{lang.SERVICE_P}</p>
                    <p>{lang.SERVICE_P2}</p>
                    <h3>{lang.SERVICE_H3}</h3>
                </div>
                <figure>
                    <img src={serviceBig} alt="" className='service__big__img' />
                </figure>
            </section>


        );
    }
}

export default Service;
