import React, { Component } from 'react';



import summer from '../../../images/mainpage/icons/summer.svg'
import poland from '../../../images/mainpage/icons/poland.svg'
import training from '../../../images/mainpage/icons/training.svg'
import students from '../../../images/mainpage/icons/students.svg'
import service from '../../../images/mainpage/icons/service.svg'
import branches from '../../../images/mainpage/icons/branches.svg'


class SectionPart extends Component {
    render() {
        const { lang } = this.props

        return (
            <section className='mainpage__section__wrap'>
                <div >
                    <figure>
                        <img src={summer} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S1_H2}</h3>
                    <h4>{lang.MAIN_S1_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(1); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
                <div >
                    <figure>
                        <img src={branches} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S2_H2}</h3>
                    <h4>{lang.MAIN_S2_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(2); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
                <div >
                    <figure>
                        <img src={training} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S3_H2}</h3>
                    <h4>{lang.MAIN_S3_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(3); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
                <div>
                    <figure>
                        <img src={students} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S4_H2}</h3>
                    <h4>{lang.MAIN_S4_H3}</h4>

                    <button  onClick = {() => {this.props.SetSectionID(4); this.props.OpenSection()}}> {lang.MAIN_S1_READ}</button>
                </div>
                <div >
                    <figure>
                        <img src={poland} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S5_H2}</h3>
                    <h4>{lang.MAIN_S5_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(5); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
                <div >
                    <figure>
                        <img src={service} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S6_H2}</h3>
                    <h4>{lang.MAIN_S6_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(6); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
            </section>
        );
    }
}

export default SectionPart;
