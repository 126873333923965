import React, { Component } from 'react';

class AwardsPart extends Component {
    render() {
        const { lang } = this.props
        const awards = [
            {
                img: '',
                year: '2004',
                text: lang.AWARD1
            },
            {
                img: '',
                year: '2005',
                text: lang.AWARD2
            },
            {
                img: '',
                year: '2007',
                text: lang.AWARD3
            },
            {
                img: '',
                year: '2008',
                text: lang.AWARD4
            },
            {
                img: '',
                year: '2008',
                text: lang.AWARD5
            },
            {
                img: '',
                year: '2009',
                text: lang.AWARD6
            },
            {
                img: '',
                year: '2010',
                text: lang.AWARD7
            },
            {
                img: '',
                year: '2011',
                text: lang.AWARD8
            },
            {
                img: '',
                year: '2013',
                text: lang.AWARD9
            },
            {
                img: '',
                year: '2014',
                text: lang.AWARD10
            },
        ]
        return (
            <section className='award__flex'>
                {awards ? awards.map((n, i) => <div key={i}>
                    <figure/>
                    <h2>{n.year}</h2>
                    <h3>{n.text}</h3>

                </div>) : null}
            </section>
        );
    }
}

export default AwardsPart;