
import React, { Component } from 'react';
import citites from '../../../text/israel_cities/cities';
import countries from '../../../text/countries/countries';



class CustomInput extends Component {

    constructor(props) {
        super(props)

        this.state = {
            className: this.props.inputClass + '__placeholder',
            cities_arr: []
        }

        this.Input = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        const { value } = this.props
        document.addEventListener('mousedown', this.handleClickOutside);
        if (value) {
            this.setState({ className: this.props.inputClass + '__placeholder--active ' + this.props.inputClass + '__placeholder' })

        } else {
            this.setState({ className: this.props.inputClass + '__placeholder' })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', () => { });
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                options_box: false
            })
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.value && nextProps.value !== this.props.value) {
            this.setState({ className: this.props.inputClass + '__placeholder--active ' + this.props.inputClass + '__placeholder' })

        }
        else if (!nextProps.value && nextProps.value !== this.props.value) {
            this.setState({ className: this.props.inputClass + '__placeholder' })
        }
    }

    onInputFocus = () => {
        this.setState({ className: this.props.inputClass + '__placeholder--active ' + this.props.inputClass + '__placeholder' })
    }

    onInputBlur = () => {
        if (!this.props.value) {
            this.setState({ className: this.props.inputClass + '__placeholder' })
        }
        this.props.validate(this.props.formObj, this.props.validationIndex, this.props.form)
    }
    onMouseWheel = () => {
        this.Input.current.blur()
    }


    onChangeCity = (e, form) => {
        const { show_options, name } = this.props
      
        let to_filter = show_options === 'city' ? citites : show_options == 'country' ? countries : []
        this.props.onInputChange(e, form)
        const filter_value = e.target.v
        let arr = to_filter.filter(m => m.name.indexOf(filter_value) === 0)

        this.setState({
            cities_arr: arr,
            options_box: true
        })
    }
    render() {

        const {
            show_options,
            placeholder,
            inputClass,
            type, name,
            form,
            value,
            tabIndex,
            mikud
        } = this.props
        const { cities_arr, options_box } = this.state
        return (
            <div
                ref={this.setWrapperRef}
                style={{ position: 'relative', height: '44px' }}>

                <input
                    ref={this.Input}
                    tabIndex={tabIndex}
                    value={value}
                    
                    onWheel={this.onMouseWheel}
                    onChange={(e) => show_options ? this.onChangeCity(e, form) : this.props.onInputChange(e, form)}
                    name={name}
                    className={inputClass}
                    type={type}
                    onFocus={() => { this.onInputFocus() }}
                    onBlur={() => { this.onInputBlur() }} />
                <p className={this.state.className}>{placeholder}</p>
               
                {show_options && cities_arr.length > 0 ?
                    <ul className={options_box ? 'custom__input__options custom__input__options__active' : 'custom__input__options'}>
                        {
                            cities_arr.map((m, i) =>
                                <li key={i}
                                    onClick={() => {
                                        this.props.onInputChange(m.name, form, 'city', name)
                                        this.setState({
                                            options_box: false
                                        })
                                    }}

                                >{m.name}

                                </li>)
                        }
                    </ul>
                    : null}
                {/* {
                props.type === 'number' ? 
                <div>
                    <span>up</span>
                    <span>down</span>
                </div>
                :
                null

            } */}
            </div>
        );
    }
}

export default CustomInput;