// import partner1 from '../../images/mainpage/Partners/AzrieliCenterLogo.svg'
// import partner2 from '../../images/mainpage/Partners/BankLeumiLogo.svg'
// import partner3 from '../../images/mainpage/Partners/bezeq.svg'
import partner4 from '../../images/mainpage/Partners/checkpoint.svg'
// import partner5 from '../../images/mainpage/Partners/co.co.svg'
// import partner6 from '../../images/mainpage/Partners/ey.svg'
// import partner7 from '../../images/mainpage/Partners/hot Copy.svg'
// import partner8 from '../../images/mainpage/Partners/howden.svg'
// import partner9 from '../../images/mainpage/Partners/howden Copy.svg'
// import partner10 from '../../images/mainpage/Partners/hsbc.svg'
// import partner11 from '../../images/mainpage/Partners/IsracartNewLogo.svg'
// import partner12 from '../../images/mainpage/Partners/keren azrieli.svg'
// import partner13 from '../../images/mainpage/Partners/logodiscount.svg'
// import partner14 from '../../images/mainpage/Partners/misrad haavoda.svg'
// import partner15 from '../../images/mainpage/Partners/misrad haavoda Copy.svg'
// import partner16 from '../../images/mainpage/Partners/pro staregy.svg'
// import partner17 from '../../images/mainpage/Partners/SanDisk Copy.svg'
// import partner18 from '../../images/mainpage/Partners/SanDisk Copy 3.svg'
import partner19 from '../../images/mainpage/Partners/shikun.svg'
import partner20 from '../../images/mainpage/Partners/ShufersalLogo.svg'
// import partner21 from '../../images/mainpage/Partners/sohnut.jpg'
// import partner22 from '../../images/mainpage/Partners/teva.svg'
// import partner23 from '../../images/mainpage/Partners/The birmingham.svg'
// import partner24 from '../../images/mainpage/Partners/The birmingham Copy.svg'
// import partner25 from '../../images/mainpage/Partners/victory.svg'
// import partner26 from '../../images/mainpage/Partners/watson.svg'
// import partner27 from '../../images/mainpage/Partners/waze.svg'
// import partner28 from '../../images/mainpage/Partners/אדמה מכתשים.svg'
// import partner29 from '../../images/mainpage/Partners/אי די בי.svg'
// import partner30 from '../../images/mainpage/Partners/אלדן.svg'
// import partner31 from '../../images/mainpage/Partners/אמות השקעות.svg'
// import partner32 from '../../images/mainpage/Partners2/aroma.svg'
// import partner33 from '../../images/mainpage/Partners/ביטוח לאומי קרנות.svg'
// import partner34 from '../../images/mainpage/Partners/בית איזי שפירא.svg'
// import partner35 from '../../images/mainpage/Partners/גוינט ישראל.svg'
// import partner36 from '../../images/mainpage/Partners/התאחדות בוני הארץ.svg'
// import partner37 from '../../images/mainpage/Partners/וקסלר קודינציק אנוך ושות.svg'
// import partner38 from '../../images/mainpage/Partners/לוטוס ניהול קרנות.svg'
// import partner39 from '../../images/mainpage/Partners/מוזס.svg'
// import partner40 from '../../images/mainpage/Partners/מתן משקיעים בקהילה.svg'
// import partner41 from '../../images/mainpage/Partners/נס טכנולוגיות.svg'
// import partner42 from '../../images/mainpage/Partners/סוכנות ביטוח דוידוף.svg'
import partner43 from '../../images/mainpage/Partners/siif.svg'
// import partner44 from '../../images/mainpage/Partners/סנו.svg'
// import partner45 from '../../images/mainpage/Partners/סנטר פארם.svg'
// import partner46 from '../../images/mainpage/Partners/פדרציה היהודית של בירמינגהם.svg'
// import partner47 from '../../images/mainpage/Partners/פדרצית ברואורד.svg'
// import partner48 from '../../images/mainpage/Partners/פלייטק.svg'
// import partner49 from '../../images/mainpage/Partners/פנינסולה.svg'
// import partner50 from '../../images/mainpage/Partners/פרודלים.svg'
// import partner51 from '../../images/mainpage/Partners/פריגו ישראל.svg'
// import partner52 from '../../images/mainpage/Partners/קרן פלדה.svg'
// import partner53 from '../../images/mainpage/Partners/קרן קאהן.svg'
// import partner54 from '../../images/mainpage/Partners/קרן קימת לישראל.svg'
import partner55 from '../../images/mainpage/Partners/קרן תד אריסון.svg'
// import partner56 from '../../images/mainpage/Partners/קרן תמורה.svg'
// import partner57 from '../../images/mainpage/Partners/רוזן ענק הביטוח.svg'
// import partner58 from '../../images/mainpage/Partners/שותפות שיקגו.svg'
// import partner59 from '../../images/mainpage/Partners/שלמור אבנון עמיחי.svg'
import partner60 from '../../images/mainpage/Partners/Bank-Hapoalim-Logo.jpg'
// import partner61 from '../../images/mainpage/Newlogos/Assoc_Logo_tag.png'
// import partner62 from '../../images/mainpage/Newlogos/Dora.png'
// import partner63 from '../../images/mainpage/Newlogos/High Res Flag - Embassy 1.png'
// import partner64 from '../../images/mainpage/Newlogos/JCDlogo.png'
// import partner65 from '../../images/mainpage/Newlogos/JCFC_logo_HORZ-C.png'
// import partner67 from '../../images/mainpage/Newlogos/JFGKC Logo_2C.png'
// import partner68 from '../../images/mainpage/Newlogos/JWF_Logo_Horiz.png'
// import partner70 from '../../images/mainpage/Newlogos/logo pro victimis.png'
// import partner71 from '../../images/mainpage/Newlogos/Partnership_logo.png'
// import partner73 from '../../images/mainpage/Newlogos/Schocken_Logo.png'
// import partner74 from '../../images/mainpage/Partners2/Jewish Federation of Southern Arizona.png'
// import partner75 from '../../images/mainpage/Newlogos/Vancouver NEW logo Jun07.png'
// import partner76 from '../../images/mainpage/Newlogos/Zarrow logo.png'
import partner77 from '../../images/mainpage/Newlogos/Ikea.png'
// import partner78 from '../../images/mainpage/Newlogos/jnf uk.jpg'
// import partner79 from '../../images/mainpage/Newlogos/בזק.png'
import partner80 from '../../images/mainpage/Newlogos/אל על.png'
// import partner81 from '../../images/mainpage/Newlogos/sundisk.jpg'
// import partner82 from '../../images/mainpage/Newlogos/בנק מזרחי טפחות.jpg'
// import partner83 from '../../images/mainpage/Partners/new1.jpg'
// import partner84 from '../../images/mainpage/Partners/new2.jpg'
import mitos from '../../images/mainpage/Partners/מיתוס.png'
import sohnut from '../../images/mainpage/Partners/sohnut.svg'
import macdonalds from '../../images/mainpage/Partners2/MC.svg'

import ahevra from '../../images/mainpage/Partners2/ahevra akalkalit.svg'
import akerem from '../../images/mainpage/Partners2/akeren lehaifa.svg'
import amirigan from '../../images/mainpage/Partners2/amirigan.svg'
import ast from '../../images/mainpage/Partners2/AST.svg'
import bazan from '../../images/mainpage/Partners2/bazan.svg'
import betizi from '../../images/mainpage/Partners2/beit izi.svg'
import booksnbaum from '../../images/mainpage/Partners2/boksnbaum.svg'
import boneihaarez from '../../images/mainpage/Partners2/boney haarez.svg'
import ahbottomline from '../../images/mainpage/Partners2/bottomline.svg'
import braha from '../../images/mainpage/Partners2/braha.svg'
import broshnir from '../../images/mainpage/Partners2/broshnir.svg'
import cafecafe from '../../images/mainpage/Partners2/cafecafe.svg'
import cal from '../../images/mainpage/Partners2/cal.svg'
import christianembassy from '../../images/mainpage/Partners2/christian embassy.svg'
import ClalBituachlogo from '../../images/mainpage/Partners2/Clal_Bituach_logo.svg'
import dalia from '../../images/mainpage/Partners2/dalia.svg'
import davidof from '../../images/mainpage/Partners2/davidof.svg'
import delek from '../../images/mainpage/Partners2/delek.svg'
import deloitte from '../../images/mainpage/Partners2/deloitte.svg'
import DIC from '../../images/mainpage/Partners2/DIC.svg'
import ecogreen from '../../images/mainpage/Partners2/ecogreen.svg'
import egud from '../../images/mainpage/Partners2/egud.svg'
import emda from '../../images/mainpage/Partners2/emda.svg'
import ferrero from '../../images/mainpage/Partners2/ferrero.svg'
import fiverr from '../../images/mainpage/Partners2/fiverr.svg'
import fulda from '../../images/mainpage/Partners2/fulda.svg'
import ganir from '../../images/mainpage/Partners2/ganir.svg'
import golbary from '../../images/mainpage/Partners2/golbary.svg'
import goldman from '../../images/mainpage/Partners2/goldman.svg'
import goodpharm from '../../images/mainpage/Partners2/good pharm.svg'
import google from '../../images/mainpage/Partners2/google.svg'
import guliver from '../../images/mainpage/Partners2/guliver.svg'
import hanamal from '../../images/mainpage/Partners2/hanamal.svg'
import harelbituah from '../../images/mainpage/Partners2/harel bituah.svg'
import hilan from '../../images/mainpage/Partners2/hilan.svg'
import hodasharon from '../../images/mainpage/Partners2/hodasharon.svg'
import hotLogo from '../../images/mainpage/Partners2/hot logo.svg'
import israelcanada from '../../images/mainpage/Partners2/israelcanada.svg'
import jassen from '../../images/mainpage/Partners2/jassen.svg'
import JCFarizona from '../../images/mainpage/Partners2/JCF arizona.svg'
import JCFofcalgary from '../../images/mainpage/Partners2/JCF of calgary.svg'
import JFkansas from '../../images/mainpage/Partners2/JF kansas.svg'
import JFbroward from '../../images/mainpage/Partners2/JF broward.svg'
import JFpittsburgh from '../../images/mainpage/Partners2/JF pittsburgh.svg'
import JFportland from '../../images/mainpage/Partners2/JF portland.svg'
import JFvancouver from '../../images/mainpage/Partners2/JF vancouver.svg'
import jnf from '../../images/mainpage/Partners2/jnf.svg'
import jonson from '../../images/mainpage/Partners2/jonson.svg'
import kerem from '../../images/mainpage/Partners2/kerem.svg'
import kerenazrieli from '../../images/mainpage/Partners2/keren azrieli.svg'
import kerendora from '../../images/mainpage/Partners2/keren dora.svg'
import kereniosef from '../../images/mainpage/Partners2/keren iosef.svg'
import kerenjerusalem from '../../images/mainpage/Partners2/keren jerusalem.svg'
import kerenmeshpahatarmoni from '../../images/mainpage/Partners2/keren meshpahat armoni.svg'
import kranot from '../../images/mainpage/Partners2/kranot.svg'
import kranotbituahleumi from '../../images/mainpage/Partners2/kranot bituah leumi.svg'
import kvuzatsamelet from '../../images/mainpage/Partners2/kvuzat samelet.svg'
import landver from '../../images/mainpage/Partners2/landver.svg'
import lead from '../../images/mainpage/Partners2/lead.svg'
import Lexus from '../../images/mainpage/Partners2/Lexus.svg'
import LS from '../../images/mainpage/Partners2/L&S.svg'
import macabitelaviv from '../../images/mainpage/Partners2/macabi telaviv.svg'
import matan from '../../images/mainpage/Partners2/matan.svg'
import medison from '../../images/mainpage/Partners2/medison.svg'
import meieden from '../../images/mainpage/Partners2/mei eden.svg'
import meitav from '../../images/mainpage/Partners2/meitav.svg'
import melisron from '../../images/mainpage/Partners2/melisron.svg'
import melonotdan from '../../images/mainpage/Partners2/melonotdan.svg'
import menaeletaligotlekaduregel from '../../images/mainpage/Partners2/menaelet aligot lekaduregel.svg'
import mench from '../../images/mainpage/Partners2/mench.svg'
import menora from '../../images/mainpage/Partners2/menora.svg'
import migdal from '../../images/mainpage/Partners2/migdal.svg'
import miniline from '../../images/mainpage/Partners2/miniline.svg'
import misradahinuh from '../../images/mainpage/Partners2/misrad ahinuh.svg'
import misradhahinuh from '../../images/mainpage/Partners2/misrad hahinuh.svg'
import misradharevaha from '../../images/mainpage/Partners2/misrad harevaha.svg'
import narayever from '../../images/mainpage/Partners2/narayever.svg'
import Ness from '../../images/mainpage/Partners2/Ness.svg'
import oradenergy from '../../images/mainpage/Partners2/orad energy.svg'
import paradigm from '../../images/mainpage/Partners2/paradigm.svg'
import playtika from '../../images/mainpage/Partners2/playtika.svg'
import pninsula from '../../images/mainpage/Partners2/pninsula.svg'
import poalimon from '../../images/mainpage/Partners2/poalimon.svg'
import prodalim from '../../images/mainpage/Partners2/prodalim.svg'
import Prostrategy from '../../images/mainpage/Partners2/Pro strategy.svg'
import rayq from '../../images/mainpage/Partners2/ray q.svg'
import renuar from '../../images/mainpage/Partners2/renuar.svg'
import saltpapermuseum from '../../images/mainpage/Partners2/saltpaper museum.svg'
import saycom from '../../images/mainpage/Partners2/saycom.svg'
import sckipio from '../../images/mainpage/Partners2/sckipio.svg'
import siftah from '../../images/mainpage/Partners2/siftah.svg'
import silverfamily from '../../images/mainpage/Partners2/silver family.svg'
import sohnut2 from '../../images/mainpage/Partners2/sohnut.svg'
import sohnutkiryatgat from '../../images/mainpage/Partners2/sohnut kiryat gat.svg'
import spireglobal from '../../images/mainpage/Partners2/spireglobal.svg'
import spotoption from '../../images/mainpage/Partners2/spotoption.svg'
import StraussNewLogo from '../../images/mainpage/Partners2/StraussNewLogo.svg'
import tamara from '../../images/mainpage/Partners2/tamara.svg'
import tasa from '../../images/mainpage/Partners2/tasa.svg'
import teaman from '../../images/mainpage/Partners2/teaman.svg'
import telol from '../../images/mainpage/Partners2/telol.svg'
import tempo from '../../images/mainpage/Partners2/tempo.svg'
import theassociated from '../../images/mainpage/Partners2/the associated.svg'
import Tm from '../../images/mainpage/Partners2/T&m.svg'
import tollmans from '../../images/mainpage/Partners2/tollmans.svg'
import topgroup from '../../images/mainpage/Partners2/top group.svg'
import Toyota from '../../images/mainpage/Partners2/Toyota.svg'
import USAe from '../../images/mainpage/Partners2/USA e.svg'
import veridis from '../../images/mainpage/Partners2/veridis.svg'
import vexler from '../../images/mainpage/Partners2/vexler.svg'
import wix from '../../images/mainpage/Partners2/wix.png'
import yairhenionim from '../../images/mainpage/Partners2/yair henionim.svg'
import yakovsalomon from '../../images/mainpage/Partners2/yakov salomon.svg'
import yakveyramatagolan from '../../images/mainpage/Partners2/yakvey ramat agolan.svg'
import zarrowfoundation from '../../images/mainpage/Partners2/zarrow foundation.svg'
import קצאא from '../../images/mainpage/Partners2/קצאא.svg'
import maslobti from '../../images/mainpage/Partners2/קרן מסלובטי.svg'
import rodman from '../../images/mainpage/Partners2/קרן רודרמן.svg'
import sonol from '../../images/mainpage/Partners2/sonol.svg'
import chicago from '../../images/mainpage/Partners2/JUF Chicago.svg'
import BLMSTN from '../../images/mainpage/Partners2/BLMSTN.svg'
import shookit from '../../images/mainpage/Partners2/Shookit.png'
import gandel from '../../images/mainpage/Partners2/Gandel.png'
import weshoes from '../../images/mainpage/Partners2/weshoes.svg'
import reviva from '../../images/mainpage/Partners/reviva.svg'




const sponsors = [
    sohnut,
    mitos,
    partner80,
    partner20,
    partner4,
    partner60,
    partner55,
  
    partner77,
    partner43,
    sohnutkiryatgat,
    JFportland,
    chicago,
    JFpittsburgh,
    reviva,
    partner19,


    macdonalds,
    ahevra,
    akerem,
    amirigan,
    ast,
    bazan,
    betizi,
    booksnbaum,
    boneihaarez,
    ahbottomline,
    braha,
    broshnir,
    cafecafe,
    cal,
    christianembassy,
    ClalBituachlogo,
    dalia,
    davidof,
    delek,
    deloitte,
    DIC,
    ecogreen,
    egud,
    emda,
    ferrero,
    fiverr,
    fulda,
    ganir,
    golbary,
    goldman,
    goodpharm,
    google,
    guliver,
    hanamal,
    harelbituah,
    hilan,
    hodasharon,
    hotLogo,
    israelcanada,
    jassen,
    JCFarizona,
    JCFofcalgary,
    JFkansas,
    JFbroward,

 
    JFvancouver,
    jnf,
    jonson,
    kerem,
    kerenazrieli,
    kerendora,
    kereniosef,
    kerenjerusalem,
    kerenmeshpahatarmoni,
    kranot,
    kranotbituahleumi,
    kvuzatsamelet,
    landver,
    lead,
    Lexus,
    LS,
    macabitelaviv,
    matan,
    medison,
    meieden,
    meitav,
    melisron,
    melonotdan,
    menaeletaligotlekaduregel,
    mench,
    menora,
    migdal,
    miniline,
    misradahinuh,
    misradhahinuh,
    misradharevaha,
    narayever,
    Ness,
    oradenergy,
    paradigm,
    playtika,
    pninsula,
    poalimon,
    prodalim,
    Prostrategy,
    rayq,
    renuar,
    saltpapermuseum,
    saycom,
    sckipio,
    siftah,
    silverfamily,
    sohnut2,
   
    spireglobal,
    spotoption,
    StraussNewLogo,
    tamara,
    tasa,
    teaman,
    telol,
    tempo,
    theassociated,
    Tm,
    tollmans,
    topgroup,
    Toyota,
    USAe,
    veridis,
    vexler,
    wix,
    yairhenionim,
    yakovsalomon,
    yakveyramatagolan,
    zarrowfoundation,
    קצאא,
    maslobti,
    rodman,
    sonol,
  
    BLMSTN,
    shookit,
    gandel,
    weshoes
]

export default sponsors