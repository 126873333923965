import React, { Component } from 'react';
import { isEmail } from 'validator';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paypal from '../../../images/donate/paypal.svg';
import Card_1 from './1.jpg';
import Card_2 from './2.jpg';
import Card_3 from './3.jpg';
import Card_4 from './4.jpg';

import Card_En_1 from './1_en.jpg';
import Card_En_2 from './2_en.jpg';
import Card_En_3 from './3_en.jpg';
import Card_En_4 from './4_en.jpg';

import axios from 'axios';
import validator from 'validator';
import Error_img from '../../../images/error/Shape.svg';
import Error_line from '../../../images/error/line_header.png';

import nis from '../../../images/donate/nis.png';
import check from '../../../images/event/check.png';
import penImg from '../../../images/pen.png';
import phoneImg from '../../../images/phone.png';
import hetz from '../../../images/hetz.png';
import RadioButtonKrembo from './RadioButtonKrembo';

export const DIFF_SUM = 'סכום אחר';
export const DIFF_SUM_EN = 'Other';

class FormHoliday extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      name: '',
      email: '',
      phone: '',
      blessing: '',
      address: '',
      sender_email: '',
      sender_name: '',
      city: '',
      business_number: '',
      total: '',
      id: '',
      receiver_name: '',
      receiver_email: '',
      id_pristine: true,
      notes: '',
      subscribed: true,
      receiver_email_pristine: true,
      project: 'ברכה לחג',
      phone_pristine: true,
      sender_email_pristine: true,
      name_pristine: true,
      sender_name_pristine: true,
      radio_amount: undefined,
      total_pristine: true,
      email_pristine: true,
      bad_email: false,
      bad_phone: false,
      finished: false,
      finish_url: '',
      disabled_btn: false,
      firm_donation: false,
      business_number_pristine: true,
      bad_business_number: false,
      min_total_err: true,
      receiver_name_pristine: true,
      focused_total: false,
      focused_name: false,
      focused_phone: false,
      focused_address: false,
      focused_city: false,
      focused_email: false,
      focused_id: false,
      focused_area: false,
      enable_igul_letova: false,

      showMissingInputsMEssage: false,
    };
  }

  componentDidUpdate() {
    if (
      (this.state.radio_amount === DIFF_SUM ||
        this.state.radio_amount === DIFF_SUM_EN) &&
      !this.state.focus_input_amount
    ) {
      this.setState({ focus_input_amount: true });
      const input = document.getElementById('donation_amount_form__');
      if (input) input.focus();
    }
  }
  scroll = (e) => {
    this.setState({ height_of_pop_up: window.scrollY - 120 });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', this.scroll);
    const url = window.location.pathname.split('/')[1];
    if (url === 'error') {
      this.setState({
        error_box: true,
      });
    }
  }

  openPopUp = (e) => {
    e.preventDefault();
  };

  submitForm = async (e) => {
    e.preventDefault();

    const {
      name,
      phone,
      city,
      address,
      email,
      total,
      notes,
      id,
      subscribed,
      radio_amount,
      round_up,
      receiver_email,
      receiver_name,
      sender_email,
      sender_name,
      selected_card,
    } = this.state;
    const { project, selected_language } = this.props;
    if (!sender_name) this.setState({ sender_name_pristine: false });
    if (!sender_email) this.setState({ sender_email_pristine: false });
    if (!receiver_email) this.setState({ receiver_email_pristine: false });
    if (!receiver_name) this.setState({ receiver_name_pristine: false });
    if (!selected_card) this.setState({ selected_card_error: true });

    if (selected_language === 'English') {
      if (
        sender_name &&
        isEmail(sender_email) &&
        isEmail(receiver_email) &&
        receiver_name &&
        selected_card
      ) {
        const res = await axios.post(
          'https://api.krembo.org.il/new-year-donation',
          this.state
        );
        if (res.data.ok) {
          window.location.href =
            'https://secured.israelgives.org/donate/KremboWingsDonations';
        }
      }
    } else {
      if (!name) this.setState({ name_pristine: false });

      if (!phone) this.setState({ phone_pristine: false });
      if (
        !total &&
        !(
          radio_amount &&
          radio_amount !== DIFF_SUM &&
          radio_amount !== DIFF_SUM_EN
        )
      )
        this.setState({ total_pristine: false });
      if (!isEmail(email)) {
        this.setState({ email_pristine: false });
      }
      if (!id) this.setState({ id_pristine: false });
      if (
        total == 0 &&
        !(
          radio_amount &&
          radio_amount !== DIFF_SUM &&
          radio_amount !== DIFF_SUM_EN
        )
      )
        this.setState({ min_total_err: false });

      if (!validator.isEmail(email) && isEmail(email)) {
        this.setState({
          bad_email: true,
        });
      } else if (!validator.isMobilePhone(phone) && phone.length >= 1) {
        this.setState({
          bad_phone: true,
        });
      } else if (
        isEmail(email) &&
        phone &&
        name &&
        (total || (radio_amount && radio_amount !== DIFF_SUM)) &&
        id &&
        isEmail(receiver_email) &&
        receiver_name &&
        selected_card &&
        sender_email &&
        receiver_email &&
        sender_name
      ) {
        this.goToDonation();
        // this.setState({ enable_igul_letova: true })
      } else {
        this.setState({ showMissingInputsMEssage: true });
      }
    }
  };
  goToDonation = (round_up) => {
    const {
      name,
      phone,
      city,
      address,
      email,
      total,
      notes,
      id,
      subscribed,
      radio_amount,
      business_number,
      blessing,
      sender_email,
      sender_name,
      receiver_name,
      receiver_email,
      selected_card,
    } = this.state;
    const { project, selected_language } = this.props;
    this.setState({ round_up, enable_igul_letova: false });
    setTimeout(() => {
      this.setState({ disabled_btn: true });
      // axios.post(`https://api.krembo.org.il/donate`, { name, phone, email, total, notes, project, city, address }).then(res => {
      // axios.post(`http://127.0.0.1:3060/donate`, {
      axios
        .post(`https://api.krembo.org.il/donate`, {
          name,
          phone,
          selected_card,
          blessing,
          sender_email,
          sender_name,
          receiver_name,
          receiver_email,
          email,
          business_number: id,
          total:
            radio_amount &&
            radio_amount !== DIFF_SUM &&
            radio_amount !== DIFF_SUM_EN
              ? radio_amount
              : total,
          notes,
          project: 'ברכה לחג',
          city,
          address,
          id,
          round_up,
          language: selected_language,
          subscribed,
        })
        .then((res) => {
          if (res.data.ok) {
            this.setState({ finished: true });
            this.setState({ finish_url: res.result });

            window.location.replace(res.data.result);
            setTimeout(() => {
              this.setState({ disabled_btn: false });
            }, 4000);

            setTimeout(() => {
              this.setState({ submitted: true });
            }, 7500);
          } else if (res.data.result === 'This email already exists') {
            this.setState({
              global_warning: res.data.result,
              tooltip: true,
              submit: false,
              finished: true,
            });
            const hidden_warning_email = document.querySelector(
              '#hidden_warning_email'
            );
            hidden_warning_email.style.display = 'block';
            setTimeout(() => {
              hidden_warning_email.style.display = 'none';
            }, 3000);
          } else {
            this.setState({
              registration_failed: true,
              submit: false,
              finished: true,
            });
          }
        });
    }, 200);
  };

  increment() {
    const { total, radio_amount } = this.state;
    if (
      radio_amount &&
      (radio_amount === DIFF_SUM || radio_amount === DIFF_SUM_EN)
    )
      this.setState({
        total: Number(total) + 50,
      });
  }

  decrement() {
    const { total, radio_amount } = this.state;
    if (
      radio_amount &&
      (radio_amount === DIFF_SUM || radio_amount === DIFF_SUM_EN) &&
      total >= 50
    )
      this.setState({
        total: Number(total) - 50,
      });
  }
  chooseImg = (selected_card) => {
    this.setState({ selected_card, selected_card_error: false });
  };
  setRadio = (radio_amount) => {
    const { enable_igul_letova } = this.state;
    if (!enable_igul_letova)
      this.setState({
        radio_amount,
        total: '',
        focus_input_amount:
          radio_amount === DIFF_SUM || radio_amount === DIFF_SUM_EN
            ? false
            : true,
      });
  };
  render() {
    const {
      focused_name,
      focused_phone,
      focused_address,
      focused_city,
      focused_email,
      focused_id,
      focused_area,
      focused_total,
      name,
      name_pristine,
      phone,
      phone_pristine,
      min_total_err,
      email,
      email_pristine,
      total,
      total_pristine,
      bad_email,
      bad_phone,
      disabled_btn,
      notes,
      id,
      id_pristine,
      address,
      city,
      subscribed,
      showMissingInputsMEssage,
      error_box,
      radio_amount,
      round_up,
      height_of_pop_up,
      enable_igul_letova,
      selected_card,
      receiver_name,
      focused_receiver_name,
      receiver_name_pristine,
      receiver_email_pristine,
      receiver_email,
      blessing,
      focused_blessing,
      focused_receiver_email,
      sender_email,
      sender_email_pristine,
      focused_sender_email,
      sender_name,
      focused_sender_name,
      sender_name_pristine,
      selected_card_error,
    } = this.state;
    const is_mobile = window.innerWidth <= 728;
    const {
      lang,
      PROJECTS,
      toggle_select,
      project,
      selected_language,
    } = this.props;
    console.log(is_mobile);
    return (
      <form onSubmit={this.submitForm}>
        {enable_igul_letova && !is_mobile ? (
          <div
            style={{
              top: height_of_pop_up ? `${height_of_pop_up}px` : '',
            }}
            className='pop_up_form_igul'
          >
            <h2>
              {selected_language === 'English'
                ? 'Would you like to also donate using Igol Letova?'
                : 'האם תרצה/י לעגל לטובת כנפיים של קרמבו?'}
            </h2>
            <div className='button_container_igul'>
              <div
                onClick={() => this.goToDonation(true)}
                className='accept_button_igul'
              >
                {selected_language === 'English'
                  ? `Yes`
                  : 'אני מעוניין/ת לעגל לטובה'}
              </div>
              <div onClick={() => this.goToDonation(false)}>
                {selected_language === 'English'
                  ? `I'm not interested`
                  : ' אני לא מעוניין/ת'}
              </div>
            </div>
            <p>
              {selected_language === 'English'
                ? `Igul Letova is an intiative where every credit transaction rounds up - for example, you bought NIS 9.80 it is then rounded up to 10 NIS and you donated 0.20 NIS for Krembo Wings! On average it is about 4 NIS a month. These are single shekels that make a huge change! You can cancel at any moment by calling * 6360`
                : `עיגול לטובה. היא יוזמה בה כל רכישה לא עגולה באשראי מתעגלת לשקל הקרוב - לדוג' קנית ב9.80 ש״ח שילמת 10 ש״ח וכך תרמת 20 אג׳ למען כנפיים של קרמבו. בממוצע מדובר בכ-5 שקלים בחודש. מדובר בשקלים בודדים שעושים שינוי עצום! ניתן לבטל ברגע באמצעות *6360`}
            </p>
          </div>
        ) : (
          ''
        )}

        {error_box ? (
          <div className='error__box'>
            <div>
              <img src={Error_img} alt='' />
              <h1>{lang.error_top}</h1>
              <h1 className='error__line'>{lang.bottom}</h1>
              <aside
                onClick={() => this.setState({ error_box: false })}
                type='button'
              >
                {lang.try_again}
              </aside>
            </div>
          </div>
        ) : null}
        <span className='donate__form__mobile__hidden'>
          <figure></figure>
          <h1>{lang.h1}</h1>
          <p>{lang.text1}</p>
          <h5>{lang.small_text}</h5>
        </span>

        <div className='donate__info__flex'>
          <h1>{lang.form_holiday_card}​</h1>
          <div className='holiday_card_details_Contnainer'>
            <div className='holiday_Card_Containers'>
              <img
                className={selected_card === '1' ? 'selected_holiday_card' : ''}
                onClick={() => this.chooseImg('1')}
                src={selected_language === 'English' ? Card_En_1 : Card_1}
              />
              <img
                className={selected_card === '2' ? 'selected_holiday_card' : ''}
                onClick={() => this.chooseImg('2')}
                src={selected_language === 'English' ? Card_En_2 : Card_2}
              />
              <img
                className={selected_card === '3' ? 'selected_holiday_card' : ''}
                onClick={() => this.chooseImg('3')}
                src={selected_language === 'English' ? Card_En_3 : Card_3}
              />
              <img
                className={selected_card === '4' ? 'selected_holiday_card' : ''}
                onClick={() => this.chooseImg('4')}
                src={selected_language === 'English' ? Card_En_4 : Card_4}
              />
              {selected_card_error ? (
                <h4 className='warning_msg'>{lang.choose_a_blessing}</h4>
              ) : (
                ''
              )}
            </div>
            {/* reciever ================================================================================================= */}
            <div className='donator__information'>
              <h2>{lang.who_to_send_holiday_card_to}</h2>
              <span className={focused_receiver_name ? 'focused__input' : null}>
                <input
                  type='text'
                  value={this.state.receiver_name}
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ receiver_name: e.target.value })
                      : ''
                  }
                  onFocus={() =>
                    this.setState({
                      receiver_name_pristine: true,
                      focused_receiver_name: true,
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      receiver_name_pristine: receiver_name.length >= 1,
                      focused_receiver_name: receiver_name.length >= 1,
                    })
                  }
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_receiver_name ? '#4a90e2' : '#424242'}
                        d='M18 10c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 8-3.58 8-8zm2 0c0 5.53-4.49 10-10.01 10S0 15.53 0 10 4.47 0 9.99 0C15.52 0 20 4.47 20 10zm-8.47-1.53a.744.744 0 0 1 .01-1.06l.883-.88a1 1 0 0 1 1.414 0l.882.88c.291.29.291.77 0 1.06a.758.758 0 0 1-1.063 0l-.531-.53-.531.53a.758.758 0 0 1-1.063 0zm-6.28 4.28c-.19-.33.06-.75.44-.75h8.63c.39 0 .63.42.44.75a5.495 5.495 0 0 1-4.755 2.75 5.495 5.495 0 0 1-4.755-2.75zm1.63-4.81l-.53.53c-.29.29-.77.29-1.06 0a.754.754 0 0 1 0-1.06l.89-.88a.996.996 0 0 1 1.41 0l.88.88c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0z'
                      />
                    </g>
                  </g>
                </svg>
                {!receiver_name_pristine && !receiver_name ? (
                  <h4 className='warning_msg'>{lang.name_insert}</h4>
                ) : null}
                <label className='form-label'>
                  <span>{lang.receiver_name}</span>
                </label>
              </span>
              <span
                className={focused_receiver_email ? 'focused__input' : null}
              >
                <input
                  type='text'
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ receiver_email: e.target.value })
                      : ''
                  }
                  value={this.state.receiver_email}
                  onFocus={() =>
                    this.setState({
                      receiver_email_pristine: true,
                      bad_receiver_email: false,
                      focused_receiver_email: true,
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      receiver_email_pristine: isEmail(receiver_email),
                      focused_receiver_email: receiver_email.length > 0,
                    })
                  }
                />
                {!receiver_email_pristine && !isEmail(receiver_email) ? (
                  <h4 className='warning_msg'>{lang.email_insert}</h4>
                ) : bad_email && receiver_email ? (
                  <h4 className='warning_msg'>{lang.bad_email}</h4>
                ) : null}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='16'
                  viewBox='0 0 20 16'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_receiver_email ? '#4a90e2' : '#424242'}
                        d='M17.6 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L2.4 4.25a.85.85 0 1 1 .9-1.44L10 7l6.7-4.19a.85.85 0 1 1 .9 1.44zM18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z'
                      />
                    </g>
                  </g>
                </svg>
                <label className='form-label'>
                  <span>{lang.receiver_email}</span>
                </label>
              </span>

              <h2>{lang.who_sent_it_holiday_card_to}</h2>

              <span className={focused_sender_name ? 'focused__input' : null}>
                <input
                  type='text'
                  value={this.state.sender_name}
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ sender_name: e.target.value })
                      : ''
                  }
                  onFocus={() =>
                    this.setState({
                      sender_name_pristine: true,
                      focused_sender_name: true,
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      sender_name_pristine: sender_name.length >= 1,
                      focused_sender_name: sender_name.length >= 1,
                    })
                  }
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_sender_name ? '#4a90e2' : '#424242'}
                        d='M18 10c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 8-3.58 8-8zm2 0c0 5.53-4.49 10-10.01 10S0 15.53 0 10 4.47 0 9.99 0C15.52 0 20 4.47 20 10zm-8.47-1.53a.744.744 0 0 1 .01-1.06l.883-.88a1 1 0 0 1 1.414 0l.882.88c.291.29.291.77 0 1.06a.758.758 0 0 1-1.063 0l-.531-.53-.531.53a.758.758 0 0 1-1.063 0zm-6.28 4.28c-.19-.33.06-.75.44-.75h8.63c.39 0 .63.42.44.75a5.495 5.495 0 0 1-4.755 2.75 5.495 5.495 0 0 1-4.755-2.75zm1.63-4.81l-.53.53c-.29.29-.77.29-1.06 0a.754.754 0 0 1 0-1.06l.89-.88a.996.996 0 0 1 1.41 0l.88.88c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0z'
                      />
                    </g>
                  </g>
                </svg>
                {!sender_name_pristine && !sender_name ? (
                  <h4 className='warning_msg'>{lang.name_insert}</h4>
                ) : null}
                <label className='form-label'>
                  <span>{lang.sender_name}</span>
                </label>
              </span>
              <span className={focused_sender_email ? 'focused__input' : null}>
                <input
                  type='text'
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ sender_email: e.target.value })
                      : ''
                  }
                  value={this.state.sender_email}
                  onFocus={() =>
                    this.setState({
                      sender_email_pristine: true,
                      bad_sender_email: false,
                      focused_sender_email: true,
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      sender_email_pristine: isEmail(sender_email),
                      focused_sender_email: sender_email.length > 0,
                    })
                  }
                />
                {!sender_email_pristine && !isEmail(sender_email) ? (
                  <h4 className='warning_msg'>{lang.email_insert}</h4>
                ) : bad_email && sender_email ? (
                  <h4 className='warning_msg'>{lang.bad_email}</h4>
                ) : null}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='16'
                  viewBox='0 0 20 16'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_email ? '#4a90e2' : '#424242'}
                        d='M17.6 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L2.4 4.25a.85.85 0 1 1 .9-1.44L10 7l6.7-4.19a.85.85 0 1 1 .9 1.44zM18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z'
                      />
                    </g>
                  </g>
                </svg>
                <label className='form-label'>
                  <span>{lang.sender_email}</span>
                </label>
              </span>
              <span
                className={
                  focused_blessing
                    ? 'focused__input project__notes'
                    : 'project__notes'
                }
              >
                <textarea
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ blessing: e.target.value })
                      : ''
                  }
                  value={this.state.blessing}
                  onFocus={() => this.setState({ focused_blessing: true })}
                  onBlur={() =>
                    this.setState({ focused_blessing: blessing.length >= 1 })
                  }
                />
                <label className='form-label'>
                  <span>{lang.blessing}</span>
                </label>
              </span>
            </div>
          </div>
          {/* reciever ================================================================================================= */}
          {selected_language === 'English' ? (
            ''
          ) : (
            <div className='donation__details '>
              <h2 className='donati_amounti'>
                {selected_language === 'English'
                  ? 'Donation amount'
                  : 'סכום לתרומה'}
              </h2>
              <div className='radio_box_donati first_donati_box'>
                {[50, 100, 180].map((n) => (
                  <RadioButtonKrembo
                    onClick={this.setRadio}
                    radio_amount={radio_amount}
                    amount={n}
                  />
                ))}
              </div>
              <div className='radio_box_donati first_donati_box'>
                {[250, 500, 1000].map((n) => (
                  <RadioButtonKrembo
                    onClick={this.setRadio}
                    radio_amount={radio_amount}
                    amount={n}
                  />
                ))}
              </div>
              <div className='radio_box_donati first_donati_box'>
                {[1500, 2500, 3000].map((n) => (
                  <RadioButtonKrembo
                    onClick={this.setRadio}
                    radio_amount={radio_amount}
                    amount={n}
                  />
                ))}
              </div>
              <div className='radio_box_donati second_donati_box'>
                {[selected_language === 'English' ? DIFF_SUM_EN : DIFF_SUM].map(
                  (n) => (
                    <RadioButtonKrembo
                      onClick={this.setRadio}
                      radio_amount={radio_amount}
                      amount={n}
                    />
                  )
                )}
                <div className='donation_amount-Radio'>
                  <div
                    onClick={() => {
                      this.setState({
                        radio_amount:
                          selected_language === 'English'
                            ? DIFF_SUM_EN
                            : DIFF_SUM,
                      });
                    }}
                    className={
                      focused_total
                        ? 'donation__amount focused__input'
                        : 'donation__amount'
                    }
                  >
                    <input
                      type='number'
                      className='total_donation_input_other'
                      id='donation_amount_form__'
                      disabled={
                        radio_amount !== DIFF_SUM &&
                        radio_amount !== DIFF_SUM_EN
                      }
                      name='total'
                      onChange={(e) =>
                        e.target.value.toString().length &&
                        !enable_igul_letova <= 6
                          ? this.setState({ total: e.target.value })
                          : ''
                      }
                      value={total < 1 ? '' : total}
                      onFocus={() =>
                        this.setState({
                          total_pristine: true,
                          min_total_err: true,
                          focused_total: true,
                        })
                      }
                    />
                    {!total_pristine && !total ? (
                      <h4 className='warning_msg'>{lang.insert_total}</h4>
                    ) : !min_total_err ? (
                      <h4 className='warning_msg'>{lang.min_amount}</h4>
                    ) : null}
                    <aside>
                      <figure
                        onClick={() => {
                          this.setState({ focused_total: true });
                          this.increment();
                        }}
                      ></figure>
                      <figure
                        onClick={() => {
                          this.setState({ focused_total: true });
                          this.decrement();
                        }}
                        className={total === 1 ? 'total__figure__blured' : null}
                      ></figure>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selected_language === 'English' ? (
            ''
          ) : (
            <div className='donator__information'>
              <h2>{lang.person_details}</h2>
              <span className={focused_name ? 'focused__input' : null}>
                <input
                  type='text'
                  value={this.state.name}
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ name: e.target.value })
                      : ''
                  }
                  onFocus={() =>
                    this.setState({ name_pristine: true, focused_name: true })
                  }
                  onBlur={() =>
                    this.setState({
                      name_pristine: name.length >= 1,
                      focused_name: name.length >= 1,
                    })
                  }
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_name ? '#4a90e2' : '#424242'}
                        d='M18 10c0-4.42-3.58-8-8-8s-8 3.58-8 8 3.58 8 8 8 8-3.58 8-8zm2 0c0 5.53-4.49 10-10.01 10S0 15.53 0 10 4.47 0 9.99 0C15.52 0 20 4.47 20 10zm-8.47-1.53a.744.744 0 0 1 .01-1.06l.883-.88a1 1 0 0 1 1.414 0l.882.88c.291.29.291.77 0 1.06a.758.758 0 0 1-1.063 0l-.531-.53-.531.53a.758.758 0 0 1-1.063 0zm-6.28 4.28c-.19-.33.06-.75.44-.75h8.63c.39 0 .63.42.44.75a5.495 5.495 0 0 1-4.755 2.75 5.495 5.495 0 0 1-4.755-2.75zm1.63-4.81l-.53.53c-.29.29-.77.29-1.06 0a.754.754 0 0 1 0-1.06l.89-.88a.996.996 0 0 1 1.41 0l.88.88c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0z'
                      />
                    </g>
                  </g>
                </svg>
                {!name_pristine && !name ? (
                  <h4 className='warning_msg'>{lang.name_insert}</h4>
                ) : null}
                <label className='form-label'>
                  <span>{lang.name}</span>
                </label>
              </span>
              <span className={focused_email ? 'focused__input' : null}>
                <input
                  type='text'
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ email: e.target.value })
                      : ''
                  }
                  value={this.state.email}
                  onFocus={() =>
                    this.setState({
                      email_pristine: true,
                      bad_email: false,
                      focused_email: true,
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      email_pristine: isEmail(email),
                      focused_email: email.length > 0,
                    })
                  }
                />
                {!email_pristine && !isEmail(email) ? (
                  <h4 className='warning_msg'>{lang.email_insert}</h4>
                ) : bad_email && email ? (
                  <h4 className='warning_msg'>{lang.bad_email}</h4>
                ) : null}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='16'
                  viewBox='0 0 20 16'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_email ? '#4a90e2' : '#424242'}
                        d='M17.6 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L2.4 4.25a.85.85 0 1 1 .9-1.44L10 7l6.7-4.19a.85.85 0 1 1 .9 1.44zM18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z'
                      />
                    </g>
                  </g>
                </svg>
                <label className='form-label'>
                  <span>{lang.email}</span>
                </label>
              </span>
              <span className={focused_phone ? 'focused__input' : null}>
                <input
                  type='number'
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ phone: e.target.value })
                      : ''
                  }
                  value={this.state.hpone}
                  onFocus={() =>
                    this.setState({
                      phone_pristine: true,
                      bad_phone: false,
                      focused_phone: true,
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      phone_pristine: phone.length >= 1,
                      focused_phone: phone.length >= 1,
                    })
                  }
                />
                {!phone_pristine && !phone ? (
                  <h4 className='warning_msg'>{lang.phone_insert}</h4>
                ) : bad_phone && phone ? (
                  <h4 className='warning_msg'>{lang.bad_phone}</h4>
                ) : null}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='22'
                  viewBox='0 0 16 22'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_phone ? '#4a90e2' : '#424242'}
                        fillOpacity='.8'
                        d='M8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM2 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 18c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
                      />
                    </g>
                  </g>
                </svg>
                <label className='form-label'>
                  <span>{lang.phone}</span>
                </label>
              </span>
              <span className={focused_address ? 'focused__input' : null}>
                <input
                  type='text'
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ address: e.target.value })
                      : ''
                  }
                  value={this.state.address}
                  onFocus={() => this.setState({ focused_address: true })}
                  onBlur={() =>
                    this.setState({ focused_address: address.length >= 1 })
                  }
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='19'
                  viewBox='0 0 18 19'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_address ? '#4a90e2' : '#424242'}
                        d='M16 13.002h-2v-2h2zm0 4h-2v-2h2zm-6-12H8v-2h2zm0 4H8v-2h2zm0 4H8v-2h2zm0 4H8v-2h2zm-6-8H2v-2h2zm0 4H2v-2h2zm0 4H2v-2h2zm8-8v-5.17c0-.53-.21-1.04-.59-1.41L9.7.712a.996.996 0 0 0-1.41 0l-1.7 1.7c-.38.38-.59.89-.59 1.42v1.17H0v14h18v-10h-2z'
                      />
                    </g>
                  </g>
                </svg>
                <label className='form-label'>
                  <span>{lang.address}</span>
                </label>
              </span>
              <span className={focused_city ? 'focused__input' : null}>
                <input
                  type='text'
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ city: e.target.value })
                      : ''
                  }
                  value={this.state.city}
                  onFocus={() => this.setState({ focused_city: true })}
                  onBlur={() =>
                    this.setState({ focused_city: city.length >= 1 })
                  }
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='20'
                  viewBox='0 0 14 20'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_city ? '#4a90e2' : '#424242'}
                        d='M7 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5zM7 0C3.13 0 0 3.13 0 7c0 4.17 4.42 9.92 6.24 12.11.4.48 1.13.48 1.53 0C9.58 16.92 14 11.17 14 7c0-3.87-3.13-7-7-7z'
                      />
                    </g>
                  </g>
                </svg>
                <label className='form-label'>
                  <span>{lang.city}</span>
                </label>
              </span>

              <span className={focused_id ? 'focused__input' : null}>
                <input
                  type='text'
                  value={id}
                  onChange={(e) =>
                    !enable_igul_letova
                      ? this.setState({ id: e.target.value })
                      : ''
                  }
                  onFocus={() =>
                    this.setState({
                      focused_id: true,
                      email_id: true,
                      bad_id: false,
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      focused_id: id,
                      id_pristine: id.length >= 1,
                    })
                  }
                />
                {!id_pristine && !id ? (
                  <h4 className='warning_msg'>{lang.id_insert}</h4>
                ) : null}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                >
                  <g>
                    <g opacity='.8'>
                      <path
                        fill={focused_id ? '#4a90e2' : '#424242'}
                        d='M16 14v1c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1v-1c0-2.66 5.33-4 8-4s8 1.34 8 4zM4 4c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4z'
                      />
                    </g>
                  </g>
                </svg>
                <label className='form-label'>
                  <span>{lang.id}</span>
                </label>
              </span>
            </div>
          )}

          {selected_language === 'English' ? (
            ''
          ) : (
            <span
              className={
                focused_area
                  ? 'focused__input project__notes'
                  : 'project__notes'
              }
            >
              <textarea
                onChange={(e) =>
                  !enable_igul_letova
                    ? this.setState({ notes: e.target.value })
                    : ''
                }
                value={this.state.notes}
                onFocus={() => this.setState({ focused_area: true })}
                onBlur={() =>
                  this.setState({ focused_area: notes.length >= 1 })
                }
              />
              <label className='form-label'>
                <span>{lang.notes}</span>
              </label>
            </span>
          )}
        </div>

        <span className='event__newslatter'>
          <div
            className={subscribed ? 'event__newslatter__active' : null}
            onClick={() => this.setState({ subscribed: !subscribed })}
          >
            {subscribed ? <img alt='check' src={check} /> : null}
          </div>
          <p>{lang.newlatter_p}</p>
        </span>

        <div
          className={
            disabled_btn
              ? 'submit__projects submit__projects--disabled'
              : 'submit__projects'
          }
        >
          {enable_igul_letova && is_mobile ? (
            <div className='igul_letova_mobile'>
              <h2>
                {selected_language === 'English'
                  ? 'Would you like to also donate using Igol Letova?'
                  : 'האם תרצה/י לעגל לטובת כנפיים של קרמבו?'}
              </h2>
              <div className='button_container_igul'>
                <div
                  onClick={() => this.goToDonation(true)}
                  className='accept_button_igul'
                >
                  {selected_language === 'English'
                    ? `Yes`
                    : 'אני מעוניין/ת לעגל לטובה'}
                </div>
                <div onClick={() => this.goToDonation(false)}>
                  {selected_language === 'English'
                    ? `I'm not interested`
                    : ' אני לא מעוניין/ת'}
                </div>
              </div>
              <p>
                {selected_language === 'English'
                  ? `Igul Letova is an intiative where every credit transaction rounds up - for example, you bought NIS 9.80 it is then rounded up to 10 NIS and you donated 0.20 NIS for Krembo Wings! On average it is about 4 NIS a month. These are single shekels that make a huge change! You can cancel at any moment by calling * 6360`
                  : `עיגול לטובה. היא יוזמה בה כל רכישה לא עגולה באשראי מתעגלת לשקל הקרוב - לדוג' קנית ב9.80 ש״ח שילמת 10 ש״ח וכך תרמת 20 אג׳ למען כנפיים של קרמבו. בממוצע מדובר בכ-5 שקלים בחודש. מדובר בשקלים בודדים שעושים שינוי עצום! ניתן לבטל ברגע באמצעות *6360`}
              </p>
            </div>
          ) : (
            <input type='submit' value={lang.submit} />
          )}
          {disabled_btn ? (
            <div className='lds-ring'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : null}
          {showMissingInputsMEssage ? (
            <p className='warning_msg'>{lang.generalWarningMessage}</p>
          ) : null}
        </div>
      </form>
    );
  }
}

export default FormHoliday;
