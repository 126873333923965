import React, { Component } from "react";
import TutorInput from "./small-parts/TutorInput";
import DatePicker from "./small-parts/DatePicker";
import TutorNumberWithBtns from "./small-parts/TutorNumberWithBtns";
import HoursSelect from "./small-parts/HoursSelect";
import TutorSelect from "./small-parts/TutorSelect";
import moment from 'moment'
import axios from 'axios'
import { API } from "../../../tools/keys";

class MemberInfo extends Component {
  constructor() {
    super()
    this.state = {

    }
  }


  submitForm = async (e, val) => {
    let check = ''
    this.setState({
      errors: []
    })
    const { has_errors } = this.state
    e.preventDefault()
    this.setState({
      validate: true
    })
    setTimeout(() => {
      this.setState({
        validate: false
      })
    }, 100);


 setTimeout(async () => {
      check = await document.querySelector('.error__msg')
      if (check) {

      } else {

        this.checkIfMemberExists()
    
      }
    }, 100);

  }

  checkIfMemberExists = async () => {
   if(!this.state.checked){
    const { member_id_number } = this.props.values
    const res1 = await axios.get(`${API}/member/getByTeudatZeutTutor?id=${member_id_number}`)
    const {
      result,
      ok
    } = res1.data
    

    if (!ok && result.indexOf('member id exists') > -1) {
      let id = result.split('_id:')[1].split(',')[0]
    
   
       this.props.sendExistsInfo(true, id)
      this.props.changeSection(1)
    } else {
       this.props.sendExistsInfo(false)
      this.props.changeSection(1)
    }
    this.setState({
      checked:true
    })
   }
   
  }




  render() {
    const { validate } = this.state
    const { values, lang } = this.props

    const {
      member_first_name,
      member_last_name,
      member_id_number,
      member_start_date,
      member_back_home_hours,
      member_type,
      children_amount_in_family,
      member_email
    } = values




    return (
      <form className="tutor__member__info"
        onSubmit={e => this.submitForm(e)}
      >
        <section class="short__part">
          <TutorInput
            updateForm={this.props.updateForm}
            value={member_first_name}
            state_name="member_first_name"
            name={lang.NAME}
            type={"text"}
            validation="first_name"
            validate={validate}
            error_msg={lang.ERR}
          />
          <TutorInput
            updateForm={this.props.updateForm}
            value={member_last_name}
            state_name="member_last_name"
            name={lang.LAST_NAME}
            type={"text"}
            validation="last_name"
            validate={validate}
            error_msg={lang.ERR}
          />
          <TutorInput
            updateForm={this.props.updateForm}
            value={member_id_number ? member_id_number : ''}
            state_name="member_id_number"
            name={lang.ID}
            type={"number"}
            validation="id"
            validate={validate}
            error_msg={lang.ERR}
            id_error = {lang.ID_ERR}
          />
          <TutorInput
            updateForm={this.props.updateForm}
            value={member_email ? member_email : ''}
            state_name="member_email"
            name={lang.EMAIL}
            type={"text"}
            validation="email"
            email_error = {lang.EMAIL_ERR}
            validate={validate}
            error_msg={lang.ERR}
          />
          
        </section>
        <section class="long__part">
        <TutorSelect
            updateForm={this.props.updateForm}
            value={member_type}
            state_name="member_type"
            name={lang.TYPE}
            options={["חניך", "חונך"]}
            validation="member_type"
            validate={validate}
            error_msg={lang.ERR}
          />
          <DatePicker
            name={lang.START_DATE}
            value={member_start_date}
            state_name={"member_start_date"}
            updateForm={this.props.updateForm}
          />
          <HoursSelect
            updateForm={this.props.updateForm}
            value={member_back_home_hours ? member_back_home_hours : ''}
            state_name="member_back_home_hours"
            name={lang.BACK_HOME_TIME}
            type={"number"}
          />
          <TutorNumberWithBtns
            updateForm={this.props.updateForm}
            value={children_amount_in_family}
            state_name="children_amount_in_family"
            name={lang.BROTHERS}
            type={"number"}
            arrows={true}
          />
        </section>
        <aside class="tutor__form__submit">
          <h2>↙</h2>
          <input type="submit" value="המשך" />
        </aside>
      </form>
    )
  }
}

export default MemberInfo;
