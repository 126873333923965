import React, { Component } from 'react';
import Slider from "react-slick";


import sponsors from '../../../text/mainpage/sponsors_icons'



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





class SponsorsSlider extends Component {
    constructor(){
        super()
        this.state = {
            sponsors:''
        }
    }
    componentDidMount(){
        let formatted_sponsors =[]
        let size = 15
       if(sponsors){
        for (var i = 0; i < sponsors.length; i += size) {
            formatted_sponsors.push(sponsors.slice(i, i + size));
        }
        this.setState({
            sponsors:formatted_sponsors
        })
       }
    }
    render() {
       
const { sponsors } = this.state
const { lang } = this.props
       




        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 700,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false

        };
        return (
            <Slider {...settings}>

             
          {/* {sponsors ? sponsors.length > 0 ? sponsors.map((n, i) => <figure key={i}><img src={n}   /></figure>) :null  :null } */}
         
          {sponsors ? sponsors.length > 0 ? sponsors.map((n, i) => <div key={`logo-sponsor-div-${i}`}>
           
              {n.map((n, i) =>  <figure key={`sponsors-slider-${i}`}><img src={n} alt={`sponsor logo`}/><div className='special__text'>{n.includes('Ikea') ?lang.special : null}</div></figure>)}
          </div>) :null  :null }
              
       
            
     
      
            </Slider>
        );
            }
        }
        
        export default SponsorsSlider;
