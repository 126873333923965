import React, { Component } from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




import img1 from '../../../images/videos/herem.png'
import img2 from '../../../images/videos/makeover.png'
import img3 from '../../../images/videos/aava.png'
import img4 from '../../../images/videos/kdoshim.png'
import img5 from '../../../images/videos/outsider.png'
import img6 from '../../../images/videos/tik.png'
import img7 from '../../../images/videos/sisters.png'
import img8 from '../../../images/videos/shona'


import Play from '../../../images/mainpage/icons/play.png'



class MobileStories extends Component {
    render() {
        
        const { lang } = this.props
        const stories = [{
            img: img1,
            title: lang.STORY1_TITLE,
            name: lang.STORY1_NAME,
            description: lang.STORY1_TEXT,
            link: 'https://www.youtube.com/embed/X3NmFCnI8qY?autoplay=1',
            link_text: lang.STORY_LINK
        
        },
        {
            img: img2,
            title: lang.STORY2_TITLE,
            name: lang.STORY2_NAME,
            description: lang.STORY2_TEXT,
            link: 'https://www.youtube.com/embed/2UE1rQn7gu0?autoplay=1',
            link_text: lang.STORY_LINK
        
        },
        {
            img: img3,
            title: lang.STORY3_TITLE,
            name: lang.STORY3_NAME,
            description: lang.STORY3_TEXT,
            link: 'https://www.youtube.com/embed/Yj2Aot2_Zoo?autoplay=1',
            link_text: lang.STORY_LINK
        
        },
        {
            img: img4,
            title: lang.STORY4_TITLE,
            name: lang.STORY4_NAME,
            description: lang.STORY4_TEXT,
            link: 'https://www.youtube.com/embed/k8R45SkGErk?autoplay=1',
            link_text: lang.STORY_LINK
        
        },
        {
            img: img5,
            title: lang.STORY5_TITLE,
            name: lang.STORY5_NAME,
            description: lang.STORY5_TEXT,
            link: 'https://www.youtube.com/embed/Vc4hwpeiDmk?autoplay=1',
            link_text: lang.STORY_LINK
        
        },
        {
            img: img6,
            title: lang.STORY6_TITLE,
            name: lang.STORY6_NAME,
            description: lang.STORY6_TEXT,
            link: 'https://www.youtube.com/embed/7LDBVbp4t_w?autoplay=1',
            link_text: lang.STORY_LINK
        
        },
        {
            img: img7,
            title: lang.STORY7_TITLE,
            name: lang.STORY7_NAME,
            description: lang.STORY7_TEXT,
            link: 'https://www.youtube.com/embed/rL4mBlwYyE4?autoplay=1',
            link_text: lang.STORY_LINK
        
        },
        {
            img: img8,
            title: lang.STORY8_TITLE,
            name: lang.STORY8_NAME,
            description: lang.STORY8_TEXT,
            link: 'https://www.youtube.com/embed/uc2dHg9uJlw?autoplay=1',
            link_text: lang.STORY_LINK
        
        },
        
        ]

        var settings = {
            dots: true,
            arrows: true,
            infinite: true,
            speed: 700,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable:false,
            rtl: lang.sliderHebrew

        };
       
        return (
            <Slider {...settings}>
               {stories ? stories.map((n,i) => <div key = {i} className='mainpage__story__element'>
                <div>
                <figure onClick = {() => this.props.playIframeVideo(n.link)}>
                    <img src={n.img} alt=""/>
                    <button
                    aria-label="play"
                    ><img src={Play} alt=""/></button>
                </figure>
                <h2>{n.title}</h2>
                <h3>{n.name}</h3>
                <p>{n.description}</p>

                <a href="https://www.youtube.com/channel/UC_mPSmRo7szeRiEKsVXyzqQ"
                 rel="noopener noreferrer" target='_blank'>
                     <button    aria-label="play">{n.link_text}</button></a>
                </div>
               </div>)  :null}
            </Slider>
        );
    }
}

export default MobileStories;
