import React, { Component } from 'react'
import TextAreaInput from './small-parts/TextAreaInput';
import TutorSelect from './small-parts/TutorSelect';


import Check from '../../../images/tutor/check.svg'
import RadioInput from './small-parts/RadioInput';


class DiagnosticDetails extends Component {
    constructor() {
        super()
        this.state = {
            alergys: [],
            errors:[]
        }
    }


    addToAlergys = (val) => {
        const { alergys } = this.state
        let arr = []
        if (alergys.includes(val)) {
            arr = alergys.filter(m => m !== val)

        } else {
            arr = [...alergys, val]
        }
        this.setState({
            alergys: arr
        })
        this.props.updateForm('aditional_alergys', arr)
    }




    submitForm = async (e, val) => {
        let check = ''
        this.setState({
            errors:[]
        })
        const { has_errors } = this.state
        e.preventDefault()
        this.setState({
            validate: true
        })
        setTimeout(() => {
            this.setState({
                validate: false
            })
        }, 100);
        setTimeout(async ()  => {
            check = await document.querySelector('.error__msg')
           if(check){
               
           }else{
            //    this.create()
          this.props.createBody()
           }
        }, 100);
       
     
     
    };


    changePage = () => {
      
    }


  

    render() {
        const { validate , errors} = this.state
       
        const { values, lang } = this.props
        const { client_type, diagnosis_description, aditional_alergys } = values
        const {
            disability_type,
            disability_description,
            disabled_compensation,
            mobility_compensation,
            situation_require_reference,
            has_epilepsy,
            challenging_behavior,
            regular_medication_description,
            has_alergy,
            alergy_type,
            special_abilities,
            additional_frames,
            notes
        } = values


        return (
            <form className='tutor__diagnostical__form'
                onSubmit={e => this.submitForm(e)}
            >
                <section>
                    <TutorSelect
                        updateForm={this.props.updateForm}
                        value={disability_type ? disability_type : ''}
                        state_name='disability_type'
                        name={lang.CLIENT_TYPE}
                        options={lang.CLIENT_TYPE_OPTIONS}
                        validate={validate}
                        error_msg={lang.ERR}
                       
                    />
                    <TextAreaInput
                        updateForm={this.props.updateForm}
                        value={disability_description ? disability_description : ''}
                        state_name='disability_description'
                        name={lang.DIA_DESC}
                        validate={validate}
                        error_msg={lang.ERR}
                       
                    />
                    <TutorSelect
                        updateForm={this.props.updateForm}
                        value={disabled_compensation ? disabled_compensation : ''}
                        state_name='disabled_compensation'
                        name={lang.DISABLED_COMPENSATION}
                        options={lang.DISABLED_COMPENSATION_OPTIONS}
                        validate={validate}
                        error_msg={lang.ERR}
                       
                    />
                    <TutorSelect
                        updateForm={this.props.updateForm}
                        value={mobility_compensation ? mobility_compensation :''}
                        state_name='mobility_compensation'
                        name={lang.MOBILITY_COMPENSATION}
                        options={lang.MOBILITY_COMPENSATION_OPTIONS}
                        validate={validate}
                        error_msg={lang.ERR}
                       
                    />
                    <TextAreaInput
                        updateForm={this.props.updateForm}
                        value={situation_require_reference ? situation_require_reference :''}
                        state_name='situation_require_reference'
                        name={lang.REQUIRE_REFERENCE}
                        validate={validate}
                        error_msg={lang.ERR}
                       

                    />

                    <div className='tutor__check__box'>
                        <figure
                            className={has_epilepsy ? 'tutor__check__active' : ''}
                            onClick={() => this.props.updateForm('has_epilepsy', has_epilepsy ? false : true)}
                        ><img src={Check} alt="" /></figure>
                        <h4>{lang.EPILEPSY}</h4>
                    </div>

                    <TextAreaInput
                        updateForm={this.props.updateForm}
                        value={challenging_behavior}
                        state_name='challenging_behavior'
                        name={lang.CHALLENGING_BEHAVIOUR}

                    />
                    <TextAreaInput
                        updateForm={this.props.updateForm}
                        value={regular_medication_description}
                        state_name='regular_medication_description'
                        name={lang.REGULAR_MEDICINE}

                    />
                </section>

                <section>
                    <RadioInput
                        value={has_alergy ? has_alergy : 'לא'}
                        state_name='has_alergy'
                        options={['כן', 'לא']}
                        name={lang.ALERGYS}
                        updateForm={this.props.updateForm}
                    />
                    {has_alergy === 'כן' ? <TextAreaInput
                        updateForm={this.props.updateForm}
                        value={alergy_type}
                        state_name='alergy_type'
                        name={lang.ALERGYS_DECRIPTION}

                    /> : ''}
                    <TextAreaInput
                        updateForm={this.props.updateForm}
                        value={special_abilities}
                        state_name='special_abilities'
                        name={lang.HOBBYS}

                    />
                    <TextAreaInput
                        updateForm={this.props.updateForm}
                        value={additional_frames}
                        state_name='additional_frames'
                        name={lang.ADDITIONAL_CLASSES}

                    />
                    <TextAreaInput
                        updateForm={this.props.updateForm}
                        value={notes}
                        state_name='notes'
                        name={lang.NOTES}
                        big={true}

                    />
                </section>
                <aside class='tutor__form__submit tutor__form__submit__last'>
                   
                    <input type="submit" value='סיום' />
                </aside>
            </form>
        )
    }
}

export default DiagnosticDetails