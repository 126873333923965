import React, { Component } from 'react'
import { Link } from 'react-router-dom'


import education_figure from '../../../../images/mainpage/sections/training_figure.svg'

import './sections.css'




class Education extends Component {

    componentWillUnmount() {
        this.props.CloseSectionID()
    }
    
    render() {
        const { lang,selected } = this.props
     
        let urlLang = selected === 'עברית' ? 'he' : selected === 'English' ? 'en' : selected === 'عربى' ? 'ar' : null
        return (
            <section className='training__wrapper'>
            {/* <img src={education_figure} alt="" className='education__figure'/> */}
              <div className='training__teams'>
                    <h1>{lang.TRAINIGN_H1}</h1>
                    <h2>{lang.TRAINIGN_H2}</h2>
                    <p>{lang.TRAINING_P_1}</p>
                    <p>{lang.TRAINING_P_2}</p>
                    <p>{lang.TRAINING_P_3}</p>
                    <h4>{lang.TRAINING_H3}</h4>
                    <p>{lang.TRAINING_P_4}</p>
                    <p>{lang.TRAINING_P_5}</p>
                    <p>{lang.TRAINING_P_6}</p>
                    <Link to={'/' + urlLang + '/signup'}>
                        <button className='sections__button'>{lang.TRAINING_JOIN}</button>
                    </Link>
                </div>
                <div className='training__milestones'>
                    <span>
                        <h3>{lang.TRAINING_MILESTONES_H2}</h3>
                        <p>{lang.TRAINING_MILESTONES_P_1}</p>
                        <p>{lang.TRAINING_MILESTONES_P_2}</p>
                        <p>{lang.TRAINING_MILESTONES_P_3}</p>
                        <p>{lang.TRAINING_MILESTONES_P_4}</p>
                        {lang.sliderHebrew ? 
                            <p>{lang.TRAINING_MILESTONES_P_5}</p>
                            :
                            null
                        }
                        {lang.sliderHebrew ? 
                            <p>{lang.TRAINING_MILESTONES_P_6}</p>
                            :
                            null
                        }
                        
                        
                    </span>
                    <span>
                        <h3>{lang.TRAINING_MILESTONES_2_H2}</h3>
                        <p>{lang.TRAINING_MILESTONES2_P1}</p>
                        {lang.sliderHebrew ? 
                            <p>{lang.TRAINING_MILESTONES2_P2}</p>
                            :
                            null
                        }
                        {lang.sliderHebrew ? 
                            <p>{lang.TRAINING_MILESTONES2_P3}</p>
                            :
                            null
                        }
                        {lang.sliderHebrew ? 
                            <p>{lang.TRAINING_MILESTONES2_P4}</p>
                            :
                            null
                        }
                        {lang.sliderHebrew ? 
                            <p>{lang.TRAINING_MILESTONES2_P5}</p>
                            :
                            null
                        }
                        {lang.sliderHebrew ? 
                            <p>{lang.TRAINING_MILESTONES2_P6}</p>
                            :
                            null
                        }
                        

                    </span>
                    <Link to={'/' + urlLang + '/signup'}>
                      <button className='sections__button'>{lang.TRAINING_JOIN}</button>
                    </Link>
                    
                </div>
               
              
            </section>
        );
    }
}

export default Education;
