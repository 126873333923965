import React, { Component } from 'react'
import validator from 'validator'

class TutorInput extends Component {
    constructor(){
        super()
        this.state = {

        }
    }


    componentWillReceiveProps(nextProps){
        const {state_name, validation, value, id_error, error_msg, email_error} = this.props

        if(nextProps.validate != this.props.validate){
           
           
             if(value && validation === 'id'){
                let  check = value.length !==  9
              
                this.setState({
                    validation_output:check,
                    error_msg:id_error
                })
            }
           else if(value && validation === 'email'){
                let  check = !validator.isEmail(value)
              
                this.setState({
                    validation_output:check,
                    error_msg:email_error
                })
            }else if(!value){
                this.setState({
                    validation_output:true,
                    error_msg:error_msg
                })
            }
           

        }
    }
    
    render() {
        const { state_name, value, name, required, type, arrows } = this.props
        const {validation_output, error_msg} = this.state
        return (
            <div className='tutor__input'>
                <h3>{name}</h3>
                <aside>
                <input type={type}
                onFocus = {() => this.setState({validation_output:false})}
                    value={value }
                    onChange={e => this.props.updateForm(state_name, e.target.value)}
                />
                   {validation_output ? <h5 className='error__msg'>{error_msg}</h5> : ''}
                </aside>
             
            </div>
        )
    }
}

export default TutorInput