import React, { Component } from 'react'


import Chevron from '../../../../images/tutor/chevron.svg'

class TutorNumberWithBtns extends Component {
    constructor(){
        super()
        this.state = {
              val:1
        }
    }


    
    render() {
        const { state_name, value, name, required, type, arrows } = this.props
        const {val} = this.state
        return (
            <div className='tutor__input tutor__number__input'>
                <h3>{name}</h3>
                <aside>
                <input type={type}
                    value={value ? value : val }
                    onChange={e => this.props.updateForm(state_name, Number(e.target.value))}
                />
                <span>
                            <button
                            type ='button'
                            onClick = {() => this.props.updateForm(state_name, value ? value +1 : val +1 )}>
                               <img src={Chevron} alt=""/>
                            </button>
                            <button
                            className={
                                value && value <= 1 ? 'tutor__number__input__opacity' : 
                               !value &&  val && val <= 1 ? 'tutor__number__input__opacity' : null
                        }
                              type ='button'
                            onClick = {() => value >= 1 ? this.props.updateForm(state_name, value ? value - 1 : val - 1) : ''}>
                                <img src={Chevron} alt=""/>
                            </button>
                        </span>
                </aside>
              
                        
                   
                
            </div>
        )
    }
}

export default TutorNumberWithBtns