import React, { Component } from 'react'

class RadioInput extends Component {
    render() {
        const { name, value, state_name, options,error, error_msg } = this.props
        return (
            <div className=' radio__input'>
                <h3>{name}</h3>
                <ul>
                    {
                        options && options.length > 0 ?
                            options.map((n, i) => <li key={i}
                                
                            >
                                <figure 
                                onClick={() => this.props.updateForm(state_name, n)}
                                className={value && value === n ? 'radio__active'  :''}>
                                    <aside></aside>
                                </figure>
                                <h4>{n}</h4>
                            </li>)
                            : null
                    }
                </ul>
              
            </div>
        )
    }
}

export default RadioInput