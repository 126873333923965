import React, { Component } from 'react'

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment'



class DatePicker extends Component {
    constructor() {
        super()
        this.state = {
            selectedDate: moment(),
            setSelectedDate: moment()
        }
    }

    handleDateChange = (date) => {
        const {state_name} = this.props
        this.setState({
            selectedDate: date
        })
        this.props.updateForm(state_name, date)

    }

    componentDidMount(){
        const body = document.querySelector('body')
        body.classList.add('tutor__body')
    }
    componentWillUnmount(){
        const body = document.querySelector('body')
        body.classList.remove('tutor__body')
    }

    render() {
        const { selectedDate } = this.state
        const { name, value, state_name } = this.props
        return (
            <div className=' tutor__datepicker'>
                <h3>{name}</h3>
                <aside>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                        <KeyboardDatePicker
                         autoOk
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            className = {value ? '' : 'datepicker__hidden__title'}
                            value={value ? value : selectedDate}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />


                    </MuiPickersUtilsProvider>
                    {!value ? <h3 className='datepikcer__placeholder'>DD/MM/YY</h3> : null}
                </aside>
            </div>
        );
    }
}
export default DatePicker