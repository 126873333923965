import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/actions';
import { Helmet } from 'react-helmet';
//import all routes
import Navbar from './Navbar/Navbar';
import MainPage from './MainPage/MainPage';
import Donate from './Donate/Donate';
import SignUp from './SignUp/SignUp';
import Contact from './Contact/Contact';
import DonationThanks from './donationThanks/DonationThanks';
import SignUpThanks from './donationThanks/SignupThanks';
import Footer from './Footer/Footer';
import RegisterSuccess from './Register/RegisterSucces';
import RegisterFailed from './Register/RegisterFailed';
import RegisterTrial from './Register/RegisterTrial';
import TutorRegister from './tutor_register/TutorRegister';
import RegisterNew from './RegisterNew/RegisterNew';
import DonateHoliday from './DonateHoliday/DonateHoliday';
import { getCookie } from '../tools/cookies';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //redirect: false
            section: '',
            active_navbar_white_page: false,
            hide__footer: false,
            active_navbar_black_page: false,
            yellow: true,
            special_mobile_nav: false,
        };
    }

    async componentDidMount() {
        let language = 'heb';
        try {
            language = getCookie('language');
        } catch (err) {}

        if (
            window.location.pathname === '/' ||
            window.location.pathname.length < 3
        ) {
            if (language) {
                console.log(language);
                if (language === 'arabic' || language === 'ar') {
                    await this.props.getLanguage('arabic').then(() => {
                        this.props.history.push('/ar');
                    });
                } else if (language === 'english' || language === 'en') {
                    await this.props.getLanguage('eng').then(() => {
                        this.props.history.push('/en');
                    });
                } else if (language === 'hebrew' || language === 'heb') {
                    await this.props.getLanguage('heb').then(() => {
                        this.props.history.push('/he');
                    });
                }
            } else {
                await this.props.getLanguage('heb').then(() => {
                    this.props.history.push('/he');
                });
            }
        } else {
            let newUrlLang = window.location.pathname.slice(0, 3);
            if (newUrlLang === '/he') {
                this.props.getLanguage('heb');
            } else if (newUrlLang === '/ar') {
                this.props.getLanguage('arabic');
            } else if (newUrlLang === '/en') {
                this.props.getLanguage('eng');
            } else {
                this.props.getLanguage('heb');
            }
        }

        let url = window.location.pathname.split('/')[2];

        if (url === 'donate') {
            this.setState({
                hide__footer: true,
                active_navbar_white_page: 'donate',
                yellow: false,
            });
        }
        let url_error = window.location.pathname.split('/')[1];
        if (url_error === 'error') {
            this.setState({
                hide__footer: true,
                active_navbar_white_page: 'donate',
                yellow: false,
            });
        }
    }

    hideNavbar = () => {
        this.setState({
            active_navbar_white_page: 'donate',
        });
    };

    hideFooter = () => {
        this.setState({
            hide_footer: true,
        });
    };

    setSection = (section) => {
        this.setState({
            section: section,
            select_language: false,
        });
    };

    resetNavbar = (elem, footer, yellow) => {
        this.setState({
            active_navbar_white_page: elem,
            hide__footer: footer,
            yellow: yellow,
        });
    };

    closeYelowThing = () => {
        this.setState({ yellow: false });
    };

    render() {
        const {
            redirect,
            section,
            active_navbar_white_page,
            hide__footer,
            active_navbar_black_page,
        } = this.state;

        console.log('this.state', this.props);
        const { language, managers, vaad, selected_language } = this.props.main;

        if (language.LANGUAGE === 'english') {
            document.documentElement.style.setProperty('--dir', 'ltr');
        }
        if (language.LANGUAGE === 'hebrew') {
            document.documentElement.style.setProperty('--dir', 'rtl');
        }
        if (language.LANGUAGE === 'arabic') {
            document.documentElement.style.setProperty('--dir', 'rtl');
        }

        let current_language = language.LANGUAGE;

        return language && managers && vaad ? (
            <div
                className={
                    language.LANGUAGE === 'english'
                        ? ' dashboard__wrapper__eng'
                        : null
                }
            >
                <Helmet>
                    <link
                        rel="alternate"
                        hreflang={
                            current_language === 'hebrew'
                                ? 'he'
                                : current_language === 'english'
                                ? 'en'
                                : 'ar'
                        }
                    />
                    <title>
                        {current_language === 'hebrew'
                            ? 'כנפיים של קרמבו - תנועת נוער לצעירים עם ובלי צרכים מיוחדים'
                            : current_language === 'english'
                            ? 'Krembo Wings - Youth movement for children and youth with and without disabilities'
                            : 'حركة "أجنحة كرمبو" هي حركة الشبيبة الأولى والوحيدة من نوعها في العالم التي تقود التغيير الاجتماعي من أجل الأولاد والشبيبة مع أو بدون الاحتياجات الخاصة، ويشترك فيها آلاف من الأولاد والشبيبة ومتطوعي الخدمة الوطنية-المدنية والطلاب.'}
                    </title>
                    <meta
                        name="description"
                        content={
                            current_language === 'hebrew'
                                ? 'כנפיים של קרמבו הינה תנועת נוער, מובילת שינוי חברתי, הראשונה והיחידה מסוגה בעולם, לילדים ונוער עם ובלי צרכים מיוחדים. בתנועה אלפי פעילים - ילדים ובני נוער, צעירים בשנת שירות וסטודנטים.'
                                : current_language === 'english'
                                ? 'Krembo Wings is the only all-inclusive youth movement in the world which brings children and youth with disabilities together with their able-bodied peers for weekly social activities. We welcome more than 6,500 members ages 7-22, students and pre-army gap year young adults.'
                                : ''
                        }
                    />
                </Helmet>
                <Router>
                    <Navbar
                        lang={language.navbar}
                        setSection={this.setSection}
                        select_language={this.state.select_language}
                        getLanguage={this.props.getLanguage}
                        resetNavbar={this.resetNavbar}
                        selected_language={selected_language}
                        active_navbar_white_page={active_navbar_white_page}
                        active_navbar_black_page={active_navbar_black_page}
                        yellow={this.state.yellow}
                    />

                    <div className="dashboard__wrapper">
                        <Switch>
                            {/* <Route path='/:lang/symbol' component={() => <Symbol lang={language.symbol} />} /> */}
                            {/* <Route path='/:lang/vision' component={() => <Vision lang={language.vision} />} /> */}
                            {/* <Route path='/:lang/activity' component={() => <Activity lang={language.activity} />} /> */}
                            {/* <Route path='/:lang/educationalconcept' component={() => <Educational lang={language.educational} />} /> */}
                            {/* Contact us  */}

                            <Route
                                path="/:lang/"
                                component={() => (
                                    <Contact
                                        lang={language.contact}
                                        language={language.LANGUAGE}
                                        managers={managers}
                                        vaad={vaad}
                                    />
                                )}
                            />

                            <Route
                                path="/:lang/contact-iframe"
                                component={() => (
                                    <Contact
                                        lang={language.contact}
                                        managers={managers}
                                        vaad={vaad}
                                        to_iframe={true}
                                    />
                                )}
                            />
                            {/* -------------End  Contact us  */}

                            {/* <Route
                path='/:lang/signup'
                component={() => (
                  <SignUp
                    resetNavbar={this.resetNavbar}
                    selected_language={
                      selected_language
                    }
                    lang={language.signup}
                  />
                )}
              /> */}
                            {/* <Route path='/:lang/about' component={() => <About lang={language.about} section={section} managers={managers} vaad={vaad} />} />
                                <Route path='/:lang/about/:anchor' component={() => <About lang={language.about} section={section} managers={managers} vaad={vaad} />} />
                                <Route path='/:lang/branches' component={() => <Branches lang={language.branches} selected_language={selected_language} />} />
                           <Route path='/:lang/press' component={() => <FromPress lang={language.press} section={section} />} />
                                <Route path='/:lang/press/:section' component={() => <FromPress lang={language.press} section={section} />} />
                                <Route path='/:lang/reports' component={() => <Reports lang={language.reports} />} />
                                <Route path='/:lang/tickets' component={() => <Event lang={language.event} />} />
                                <Route path='/:lang/friends' component={() => <Event lang={language.event} />} />
                                <Route path='/:lang/extratickets' component={() => <Event lang={language.event} />} /> */}
                            {/* <Route path='/registration-success' component={() => <RegisterSuccess lang={language.REGISTER_SUCCESS} />} />
                                <Route path='/registration-failed' component={() => <RegisterFailed lang={language.REGISTER_SUCCESS} selected_language={selected_language} />} />
                                <Route path='/sign-success' component={() => <SignUpThanks lang={language.thanks} />} />
                                <Route path='/error' component={() => <Donate resetNavbar={this.resetNavbar} lang={language.donate} />} />
                                <Route path='/:lang/donate-iframe' component={() => <Donate resetNavbar={this.resetNavbar} lang={language.donate} selected_language={selected_language} to_iframe={true} />} />
                                <Route path='/:lang/donate-holiday-iframe' component={() => <DonateHoliday resetNavbar={this.resetNavbar} lang={language.donate} selected_language={selected_language} to_iframe={true} />} />
                                <Route  path='/:lang/register' component={() => <RegisterNew language={language} />} />
                                <Route  path='/:lang/form-new-web' component={() => <RegisterNew language={language} />}  />
                                
                                <Route path='/:lang/tutor-registration' component={() => <TutorRegister lang={language.TUTOR} />} /> */}
                            {/* 

                                <Route path='/:lang/register-parents-approval' component={() => <RegisterNoPay lang={language.register} />} />
                                <Route path='/:lang/workshops/form' component={() => <WorkshopsForm lang={language.workshops} resetNavbar={this.resetNavbar} />} />
                                <Route path='/:lang/workshops' component={() => <Workshops lang={language.workshops} resetNavbar={this.resetNavbar} />} />

                                <Route path='/:lang' component={() => <MainPage section={section} yellow={this.state.yellow} selected_language={selected_language} lang={language.mainpage} resetNavbar={this.resetNavbar} />} /> */}

                            {/* <Route path='/' component={() => <MainPage section={section} yellow={this.state.yellow} selected_language={selected_language} lang={language.mainpage} resetNavbar={this.resetNavbar} />} /> */}

                            {/* donation */}
                            {/* <Route
                path='/:lang/contact-iframe'
                component={() => (
                  <Contact
                    lang={language.contact}
                    managers={managers}
                    vaad={vaad}
                    to_iframe={true}
                  />
                )}
              />
              <Route
                path='/success'
                component={() => (
                  <DonationThanks
                    lang={language.thanks}
                  />
                )}
              />
              <Route
                path='/error'
                component={() => (
                  <Donate
                    resetNavbar={this.resetNavbar}
                    lang={language.donate}
                  />
                )}
              />
              <Route
                path='/'
                component={() => (
                  <Donate
                    resetNavbar={this.resetNavbar}
                    lang={language.donate}
                    selected_language={
                      selected_language
                    }
                  />
                )}
              /> */}
                            {/* donation end */}

                            {/* <Route path='/' component={() => <Contact lang={language.contact} managers={managers} vaad={vaad} />} /> */}

                            {/* REGISTER */}
                            {/* <Route path='/:lang/register-trial' component={() => <RegisterTrial lang={language.register} />} />
                                <Route path='/registration-failed' component={() => <RegisterFailed lang={language.REGISTER_SUCCESS} selected_language={selected_language} />} />
                                <Route path='/registration-success' component={() => <RegisterSuccess lang={language.REGISTER_SUCCESS} />} />
                                <Route  path='/' component={() => <RegisterNew language={language}  />}  />  */}
                            {/* REGISTRE END */}
                        </Switch>
                    </div>

                    {/* {!hide__footer ?
                            <Footer resetNavbar={this.resetNavbar}
                                selected_language={selected_language}
                                lang={language.footer}
                                setSection={this.setSection}
                                managers={managers} /> : null} */}
                </Router>
                {redirect ? <Redirect to="/:lang" /> : null}
            </div>
        ) : null;
    }
}

function mapStateToProps({ main }) {
    return { main };
}
export default connect(
    mapStateToProps,
    actions,
)(Dashboard);
