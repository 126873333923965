import img1 from '../images/mainpage/main1.png';
import img2 from '../images/mainpage/main2.png';
import img3 from '../images/mainpage/main3.png';
import img4 from '../images/mainpage/main4.png';

import about1 from '../images/about/about_vision1.png';
import about2 from '../images/about/about_vision2.png';
import about3 from '../images/about/about_vision3.png';
import about4 from '../images/about/about_vision4.png';

export const API =
  'https://krembo-reg-api.herokuapp.com';
// export const API = 'http://localhost:3040'
export const API_PHOTOS =
  'https://kremboapi.herokuapp.com';

export const MAIN_PAGE_IMAGES = [
  img1,
  img2,
  img3,
  img4,
];

export const PROJECTS = [
  'destination_1',
  'destination_2',
  'destination_3',
  'destination_4',
  'destination_5',
  'destination_6',
];

export const languages = [
  {
    lang: 'heb',
    text: 'עב',
  },
  {
    lang: 'eng',
    text: 'EN',
  },
  {
    lang: 'arabic',
    text: 'عربى',
  },
];

export const about_images = [
  about1,
  about2,
  about3,
  about4,
];

export const MONTHS = [
  'ינואר',
  'פברואר',
  'מרץ',
  'אפריל',
  'מאי',
  'יוני',
  'יולי',
  'אוגוסט',
  'ספטמבר',
  'אוקטובר',
  'נובמבר',
  'דצמבר',
];

export const add_to_db = [
  'situation_require_reference',
  'special_abilities,additional_frames',
  'challenging_behavior',
  'disability_description',
  'disability_type',
  'disabled_compensation',
  'hostel_name',
  'member_at_moadonit',
  'member_back_home_hours',
  'children_amount_in_family',
  'member_start_date',
  'member_type',
  'mobility_compensation',
  'notes',
  'pickup_place',
  'program_name',
  'program_type',
  'regular_medication_description',
];

export const determinePriceByCityName = (
  cityName
) => {
  switch (true) {
    case cityName === 'Ofakim' ||
      cityName === 'אופקים':
      return 250;
      break;
    case cityName === 'Dimona' ||
      cityName === 'דימונה':
      return 200;
      break;
    case cityName === 'Ashdod' ||
      cityName === 'אשדוד':
      return 400;
      break;
    case cityName === 'Ashkelon' ||
      cityName === 'אשקלון':
      return 400;
      break;
    case cityName === 'Beer Sheva' ||
      cityName === 'באר שבע':
      return 400;
      break;
    case cityName === 'Beit She’an' ||
      cityName === 'בית שאן':
      return 250;
      break;
    case cityName === 'בת ים' ||
      cityName === 'Bat Yam':
      return 400;
      break;
    case cityName === 'גבעתיים' ||
      cityName === 'Givatayim':
      return 500;
      break;
    case cityName === 'Hod HaSharon' ||
      cityName === 'הוד השרון':
      return 500;
      break;
    case cityName === 'Herzliya' ||
      cityName === 'הרצליה':
      return 500;
      break;
    case cityName === 'Hadera' ||
      cityName === 'חדרה':
      return 400;
      break;
    case cityName === 'East Holon' ||
      cityName === 'חולון מזרח':
      return 400;
      break;
    case cityName === 'West Holon' ||
      cityName === 'חולון מערב':
      return 400;
      break;
    case cityName === 'Haifa' ||
      cityName === 'חיפה':
      return 500;
      break;
    case cityName === 'Tiberias' ||
      cityName === 'טבריה':
      return 400;
      break;
    case cityName === 'Tira' ||
      cityName === 'טירה':
      return 150;
      break;
    case cityName === 'Yavne' ||
      cityName === 'יבנה':
      return 400;
      break;
    case cityName === 'Yehud' ||
      cityName === 'יהוד':
      return 500;
      break;
    case cityName === 'Yokne’am' ||
      cityName === 'יקנעם':
      return 400;
      break;
    case cityName === 'Jerusalem' ||
      cityName === 'ירושלים':
      return 250;
      break;
    case cityName === 'Kochav Yair-Tzur Yigal' ||
      cityName === 'כוכב יאיר-צור יגאל':
      return 500;
      break;
    case cityName === 'Kfar Saba' ||
      cityName === 'כפר סבא':
      return 500;
      break;
    case cityName === 'Karmiel' ||
      cityName === 'כרמיאל':
      return 400;
      break;
    case cityName === 'Lod' || cityName === 'לוד':
      return 250;
      break;
    case cityName === 'Modi’in' ||
      cityName === 'מודיעין':
      return 500;
      break;
    case cityName === 'Ness Ziona' ||
      cityName === 'נס ציונה':
      return 500;
      break;
    case cityName === 'Netanya' ||
      cityName === 'נתניה':
      return 400;
      break;
    case cityName === 'Ar’ara in the Negev' ||
      cityName === 'ערערה בנגב':
      return 100;
      break;
    case cityName === 'Petah Tikva' ||
      cityName === 'פתח תקוה':
      return 400;
      break;
    case cityName === 'Tzfat' ||
      cityName === 'צפת':
      return 250;
      break;
    case cityName === 'Kiryat Shmona' ||
      cityName === 'קרית שמונה':
      return 400;
      break;
    case cityName === 'Kiryat Ono' ||
      cityName === 'קרית אונו':
      return 500;
      break;
    case cityName === 'Kiryat Gat' ||
      cityName === 'קרית גת':
      return 250;
      break;
    case cityName === 'Rosh HaAyin' ||
      cityName === 'ראש העין':
      return 400;
      break;
    case cityName === 'Rahat' ||
      cityName === 'רהט':
      return 100;
      break;
    case cityName === 'North Ramat Gan' ||
      cityName === 'רמת גן צפון':
      return 425;
      break;
    case cityName === 'South Ramat Gan' ||
      cityName === 'רמת גן דרום':
      return 425;
      break;
    case cityName === 'Ra’anana' ||
      cityName === 'רעננה':
      return 500;
      break;
    case cityName === 'Sderot' ||
      cityName === 'שדרות':
      return 125;
      break;
    case cityName === 'South Tel Aviv' ||
      cityName === 'תל אביב דרום':
      return 250;
      break;
    case cityName === 'North Tel Aviv' ||
      cityName === 'תל אביב צפון':
      return 500;
      break;
    case cityName === 'Majd al-Krum' ||
      cityName === 'מג´ד אל-כרום':
      return 125;
      break;
    case cityName === 'Akko' ||
      cityName === 'עכו':
      return 250;
      break;
    case cityName === 'Lev HaSharon' ||
      cityName === 'לב השרון':
      return 500;
      break;
    case cityName === 'Rehovot' ||
      cityName === 'רחובות':
      return 400;
      break;
    case cityName === 'Ramla' ||
      cityName === 'רמלה':
      return 250;
      break;
    case cityName === 'Jaffa' ||
      cityName === 'יפו':
      return 250;
      break;
    case cityName === 'East Rishon LeZion' ||
      cityName === 'ראשון לציון מזרח':
      return 400;
      break;
    case cityName === 'West Rishon LeZion' ||
      cityName === 'ראשון לציון מערב':
      return 500;
      break;
    case cityName === 'Yarka' ||
      cityName === 'ירכא':
      return 125;
      break;
    case cityName === 'Hura' ||
      cityName === 'חורה':
      return 100;
      break;
    case cityName === 'Ramat HaSharon' ||
      cityName === 'רמת השרון':
      return 500;
      break;
    case cityName === 'Ma’ale Adumim' ||
      cityName === 'מעלה אדומים':
      return 400;
      break;
    case cityName === 'Kiryat Malachi' ||
      cityName === 'קריית מלאכי':
      return 250;
      break;
    case cityName === 'Eilat' ||
      cityName === 'אילת':
      return 400;
      break;
    case cityName === 'Bnei Shimon' ||
      cityName === 'בני שמעון':
      return 400;
      break;
    case cityName === 'Ariel' ||
      cityName === 'אריאל':
      return 400;
      break;
    case cityName === 'Mate Asher' ||
      cityName === 'מטה אשר':
      return 400;
      break;
    case cityName === 'Tel Sheva' ||
      cityName === 'תל שבע':
      return 100;
      break;
    case cityName === 'Kiryat Haim' ||
      cityName === 'קרית חיים':
      return 500;
      break;
    case cityName === 'Shfar’am' ||
      cityName === 'שפרעם':
      return 125;
      break;
    case cityName === 'Or Yehuda' ||
      cityName === 'אור יהודה':
      return 400;
      break;
    case cityName === 'Emek Hefer' ||
      cityName === 'עמק חפר':
      return 500;
      break;
    case cityName === 'Netivot' ||
      cityName === 'נתיבות':
      return 250;
      break;
    case cityName === 'Nazareth Illit' ||
      cityName === 'נצרת עילית' ||
      cityName === 'נוף הגליל':
      return 400;
      break;
    default:
      return 0;
  }
};

export const BASIC_OPTIONS = [
  'חונך (פעיל בשכבה ח-יב)',
  "חניך (קב' חונכויות)",
  'פעיל שכבת ז',
  "פעיל שכבה צעירה ד'-ו",
  'חבר צוות מוביל',
  'חבר צוות מנהל',
];

export const FIRST_OPTIONS = [
  'מרכז סניף',
  'רכז חונכויות ',
  'רכז שכב"ג',
  'רכז מפעלים',
  'רכז שכב״צ ד-ו',
  'רכב שכב״צ ז-ח',
];
export const SECOND_OPTIONS = [
  'מדריך',
  'משלב',
  'רכז תקשורת',
  'צוות מפעלים',
  'צוות תקשורת',
];
