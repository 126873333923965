import React from 'react';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';


const CustomMultipleCheckBox = (props) => {
 
    return (
        <div className={props.multipleCheckBoxClass}
            type="multiplecheckbox" >
            {props.options.map((item, index) =>

                <div className='checkbox__wrapper'>
                    {props.type === 'multiplecheckbox' && !props.shirt_select ?
                        <figure>
                            {!props.checkbox ? < Radio
                                name={props.name}
                                checked={item.checked}
                                onChange={(e) => {
                                    props.onMultiCheck(e, props.form, index, props.multipleValuesAllowed);
                                    props.validate(props.formObj, props.validationIndex, props.form)
                                }}

                            /> : <Checkbox
                            name={props.name}
                            checked={item.checked}
                            onClick={(e) => {
                                props.onMultiCheck(e, props.form, index, props.multipleValuesAllowed);
                                props.validate(props.formObj, props.validationIndex, props.form)
                            }}
                        />}
                        </figure> :
                        props.shirt_select ?
                           
                               <div   
                               className={item.checked ? 'shirt__checked' : ''}
                               id='shirt__select'>
                                    <aside
                              
                             
                                    name={props.name}
                                    checked={item.checked}
                                    onClick={(e) => {
                                        props.onMultiCheck(e, props.form, index, props.multipleValuesAllowed);
                                        props.validate(props.formObj, props.validationIndex, props.form)
                                    }}
                                >
                                   
                                </aside>
                                <p>{item.text}</p>
                               </div>
                          
                            :
                            <figure>
                                <Checkbox
                                    name={props.name}
                                    checked={item.checked}
                                    onClick={(e) => {
                                        props.onMultiCheck(e, props.form, index, props.multipleValuesAllowed);
                                        props.validate(props.formObj, props.validationIndex, props.form)
                                    }}
                                />
                            </figure>
                    }
                    {!props.shirt_select ? <p data-name={props.name}>{item.text}</p> : ''}
                </div>
            )}
        </div>
    );
};

export default CustomMultipleCheckBox;