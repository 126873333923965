import React, { Component } from 'react';


import figure1 from '../../../../images/mainpage/sections/summer_figure.svg'
import Scrollbar from 'react-scrollbars-custom'

class SummerCamp extends Component {

    
    render() {
        const { lang } = this.props
        return (
            <div className='summer__camp_wrapper'>

                <h1 className="summer__camp_wrapper__hidden-h1">{lang.SUMMER_CAMP_H1}</h1>
                {/* {window.innerWidth > 1350 ? 
                  <Scrollbar style={{ height: '100%' }} noScrollX rtl={this.props.selected === 'English' || this.props.selected === 'english'}>
                  <div className='summer__content__box'>
                      <h1>{lang.SUMMER_CAMP_H1}</h1>
                      <h2>{lang.SUMMER_CAMP_H2}</h2>
                      <h3>{lang.SUMMER_CAMP_H3}</h3>
                      <aside>
                          <h3>{lang.SUMMER_CAMP1_H3}</h3>
                          <p>{lang.SUMMER_CAMP1_P}</p>
                          <p>{lang.SUMMER_CAMP1_P2}</p>
                      </aside>
                      <aside>
                          <h3>{lang.SUMMER_CAMP2_H3}</h3>
                          <p>{lang.SUMMER_CAMP2_P}</p>
                          <p>{lang.SUMMER_CAMP2_P2}</p>
                      </aside>
                  </div>
                  </Scrollbar>
                  : */}
           
                  <div className='summer__content__box'>
                      <h1>{lang.SUMMER_CAMP_H1}</h1>
                      <h2>{lang.SUMMER_CAMP_H2}</h2>
                      <h3>{lang.SUMMER_CAMP_H3}</h3>
                      <aside>
                          <h3>{lang.SUMMER_CAMP1_H3}</h3>
                          <p>{lang.SUMMER_CAMP1_P}</p>
                          <p>{lang.SUMMER_CAMP1_P2}</p>
                      </aside>
                      <aside>
                          <h3>{lang.SUMMER_CAMP2_H3}</h3>
                          <p>{lang.SUMMER_CAMP2_P}</p>
                          <p>{lang.SUMMER_CAMP2_P2}</p>
                      </aside>
                  </div>
            
            
                
              
                <div className='summer__figure__part custom-img-container'>

                    <figure></figure>

                </div>


                <div className="mobile-banner">
                    <p>{lang.SUMMER_CAMP_LEFT} </p>
                </div>

            </div>
        );
    }
}

export default SummerCamp;
