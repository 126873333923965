import React, { Component } from 'react'
import MemberInfo from './parts/MemberInfo';
import { Redirect } from 'react-router-dom'

import './tutor.css'
import EducationalFramerwork from './parts/EducationalFramerwork';
import DiagnosticDetails from './parts/DiagnosticDetails';
import Parents from './parts/Parents';
import moment from 'moment'
import axios from 'axios'
import { API } from '../../tools/keys';

let arr = ['פרטי משתתף', 'מסגרת חינוכית', 'פרטי הורים', 'פירוט אבחנה']
class TutorRegister extends Component {
    constructor() {
        super()
        this.state = {
            selected: 'פרטי משתתף',
            success_box:false
        }
    }


    updateForm = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    sendExistsInfo = (val, id) => {
        if(val){
            this.setState({
                member_exists:val,
                id
            })
        }
       
     
    }

    changeSection = (val) => {
        this.setState({
            selected: arr[val]
        })
    }

    createBody = async () => {
        const {member_exists, id} = this.state
     
        const {
            additional_frames,
            alergy_type,
            challenging_behavior,
            disability_description,
            disability_type,
            disabled_compensation,
            first_parent_email,
            first_parent_name,
            first_parent_phone,
            has_alergy,
            has_epilepsy,
            hostel_name,
            member_at_moadonit,
            member_first_name,
            member_back_home_hours,
            member_id_number,
            children_amount_in_family,
            member_last_name,
            member_start_date,
            member_type,
            mobility_compensation,
            notes,
            pickup_place,
            program_name,
            program_type,
            regular_medication_description,
            second_parent_email,
            second_parent_name,
            second_parent_phone,
            situation_require_reference,
            special_abilities,
            member_email

        } = this.state


        let body = await {
            member_email,
            additional_frames,
            alergy_type_tutor_form:alergy_type,
            challenging_behavior,
            disability_description,
            disability_type,
            disabled_compensation,
            first_parent_email,
            first_parent_name,
            first_parent_phone,
            has_alergy_tutor_form:has_alergy,
            has_epilepsy_tutor_form:has_epilepsy,
            hostel_name,
            member_at_moadonit,
            member_first_name,
            member_back_home_hours,
            member_id_number,
            children_amount_in_family,
            member_last_name,
            member_start_date: moment(member_start_date).toISOString(),
            member_type,
            mobility_compensation,
            notes,
            pickup_place,
            program_name,
            program_type,
            regular_medication_description,
            second_parent_email,
            second_parent_name,
            second_parent_phone,
            situation_require_reference,
            special_abilities,
            applied_from_tutor_form:true
        }



        if(member_exists){
            body['id'] = id
            body['status'] = false
            const res = await axios.post(`${API}/member/update`, body)
            const {
                result, ok
            } = res.data
    
            if(ok){
              this.setState({
                  success_box:true
              })
            }
        }else{
            body['status'] = false
            const res = await axios.post(`${API}/member/create`, body)
            const {
                result, ok
            } = res.data
    
            if(ok){
              this.setState({
                  success_box:true
              })
            }
        }
        


    }
    componentWillUnmount(){
        this.setState({
            success_box:false
        })
    }

    render() {

        const { selected, success_box, redirect_to} = this.state
        const { lang } = this.props
        return (
            <div className='tutor__wrapper'>
                {
                   
                    redirect_to === 'home' ? <Redirect to='/he' /> : null
            }
               {success_box ?  <div className='tutor__success'> 
                    <h1>{lang.SUCCESS}</h1>
                    <span>
                        <button onClick = {() => window.location.reload()}>{lang.ONE_MORE}</button>
                        <button onClick = {() => this.setState({
                            redirect_to:'home'
                        })}>{lang.BACK_HOME}</button>
                    </span>
                </div> : null}
                <section className='tutor__content'>
                    <header>
                        <h2>דף חניך</h2>
                    </header>
                    <ul className='totur__nav'>
                        {arr && arr.length > 0 ?
                            arr.map((n, i) =>
                                <li key={i}
                                    className={selected === n ? 'tutor__selected__section' : ''}
                                    onClick={() => this.setState({ selected: n })}
                                >{n}</li>)
                            : null}
                    </ul>
                    <div className='tutor__forms'>
                        {
                            selected === 'פרטי משתתף' ?
                                <MemberInfo
                                    updateForm={this.updateForm}
                                    values={this.state}
                                    lang={lang}
                                    changeSection={this.changeSection}
                                    sendExistsInfo = {this.sendExistsInfo}

                                /> :

                                selected === 'מסגרת חינוכית' ?
                                    <EducationalFramerwork
                                        lang={lang}
                                        updateForm={this.updateForm}
                                        values={this.state}
                                        changeSection={this.changeSection}
                                    /> :
                                    selected === 'פרטי הורים' ?
                                        <Parents
                                            lang={lang}
                                            updateForm={this.updateForm}
                                            values={this.state}
                                            changeSection={this.changeSection}
                                        /> :
                                        selected === 'פירוט אבחנה' ?
                                            <DiagnosticDetails
                                                lang={lang}
                                                updateForm={this.updateForm}
                                                values={this.state}
                                                createBody={this.createBody}
                                            /> : null
                        }
                    </div>
                </section>
            </div>
        )
    }
}

export default TutorRegister