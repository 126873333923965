import React, { Component } from 'react'

class TutorSelect extends Component {
    constructor() {
        super()
        this.state = {
            active: false
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', () => {});
      }
    
    setWrapperRef(node) {
        this.wrapperRef = node;
      }
      handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
          active:false
      })
        }
      }



    componentWillReceiveProps(nextProps){
        const {state_name, validation, value} = this.props
        if(nextProps.validate != this.props.validate){
            let output = false
           if(!nextProps.value){
                this.setState({
                    validation_error:true
                })
                output = true
           }
        
        }
    }



    render() {
        const { options, value, state_name, name , error_msg} = this.props
        const { active, validation_error } = this.state
        return (
            <div className='tutor__input tutor__select'>
                <h3>{name}</h3>
                <section ref={this.setWrapperRef}>
                    <h2 onClick={() => this.setState({
                        active: !active,
                        validation_error:false
                    })}>{value ? value : ''}</h2>
                    <ul className={active ? 'tutor__select__active' : ''}>
                        {
                            options && options.length > 0 ?
                                options.map((n, i) =>
                                    <li key={i} onClick={() => {
                                        this.props.updateForm(state_name, n); this.setState({
                                            active: !active
                                        })
                                    }}>{n}</li>)
                                : null
                        }
                    </ul>
                    {validation_error ? <h5 className='error__msg'>{error_msg}</h5> : false}
                </section>
             
            </div>
        )
    }
}

export default TutorSelect