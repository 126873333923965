import React, { Component } from 'react';
import Slider from "react-slick";


import sponsors from '../../../text/mainpage/sponsors_icons'



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { of } from 'rxjs';


import summer from '../../../images/mainpage/icons/summer.svg'
import poland from '../../../images/mainpage/icons/poland.svg'
import training from '../../../images/mainpage/icons/training.svg'
import students from '../../../images/mainpage/icons/students.svg'
import service from '../../../images/mainpage/icons/service.svg'
import branches from '../../../images/mainpage/icons/branches.svg'


class SectionsMobile extends Component {
    constructor(){
        super()
        this.state = {
           
        }
    }
    componentDidMount(){
      
    }
    render() {
       
const { lang,  } = this.props
       



        var settings = {
            dots: true,
            arrows: true,
            infinite: true,
            speed: 200,
            slidesToShow: 2,
            slidesToScroll: 1,
            draggable: true,
            rtl: lang.sliderHebrew

        };
        return (
            <Slider {...settings} className=' mobile__section__wrap'>

    
<div   className='mobile__Sections__element'>
                    <figure>
                        <img src={summer} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S1_H2}</h3>
                    <h4>{lang.MAIN_S1_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(1); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
                <div   className='mobile__Sections__element'>
                    <figure>
                        <img src={branches} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S2_H2}</h3>
                    <h4>{lang.MAIN_S2_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(2); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
                <div   className='mobile__Sections__element'>
                    <figure>
                        <img src={training} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S3_H2}</h3>
                    <h4>{lang.MAIN_S3_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(3); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
                <div  className='mobile__Sections__element'>
                    <figure>
                        <img src={students} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S4_H2}</h3>
                    <h4>{lang.MAIN_S4_H3}</h4>

                    <button  onClick = {() => {this.props.SetSectionID(4); this.props.OpenSection()}}> {lang.MAIN_S1_READ}</button>
                </div>
                <div   className='mobile__Sections__element'>
                    <figure>
                        <img src={poland} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S5_H2}</h3>
                    <h4>{lang.MAIN_S5_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(5); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
                <div   className='mobile__Sections__element'>
                    <figure>
                        <img src={service} alt=""/>
                    </figure>
                    <h3>{lang.MAIN_S6_H2}</h3>
                    <h4>{lang.MAIN_S6_H3}</h4>

                    <button onClick = {() => {this.props.SetSectionID(6); this.props.OpenSection()}}>{lang.MAIN_S1_READ}</button>
                </div>
     
     
            </Slider>
        );
            }
        }
        
        export default SectionsMobile;
