import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import './donationthanks.css';

import Thanks from '../../images/krembo-thanks.png';
import Logo from '../../images/navbar/logo.svg';

class DonationThanks extends Component {
  constructor() {
    super();

    this.state = {
      redirected: false,
    };
  }

  componentDidMount() {
    window.addEventListener(
      'scroll',
      this.handleScroll
    );
    if (true) {
      const navbar = document.querySelector(
        'section[class="navbar__wrapper"]'
      );
      const footer = document.querySelector(
        'section[class="footer__wrapper"]'
      );
      if (navbar) navbar.remove();
      if (footer) footer.remove();
    }
    const url = document.URL.split('?');
    if (url[1]) {
      const parameters = Object.fromEntries(
        new URLSearchParams(
          window.location.search
        )
      );

      if (parameters && parameters.cardMask) {
        const {
          cardMask,
          uniqueID,
          personalId,
          salesForceId,
          txId,
          mid,
        } = parameters;
        const body = {
          cardMask,
          uniqueID,
          personalId,
        };
        axios.post(
          'https://reg-api.krembo.org.il/did-roundup',
          body
        );
        axios
          .get(
            `https://api.krembo.org.il/donationsuccess/${uniqueID}?personalId=${personalId}&salesForceId=${salesForceId}&txId=${txId}&mid=${mid}`
          )
          //   .get(
          //     `http://localhost:3060/donationsuccess/${uniqueID}?personalId=${personalId}&salesForceId=${salesForceId}`
          //   )
          .then((res) => {
            console.log(
              '🚀 ~ getting  :',
              res.data
            );
          });
      }
    } else {
      this.setState({ redirected: true });
    }
    // Meta Pixel JavaScript code
    const metaPixelScript = document.createElement(
      'script'
    );
    metaPixelScript.id = 'test';
    metaPixelScript.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '622499939042041');
    fbq('track', 'PageView');
    fbq('track', 'Purchase'); 
  `;
    document.head.appendChild(metaPixelScript);

    // Meta Pixel noscript image
    const noscriptImg = document.createElement(
      'noscript'
    );
    noscriptImg.innerHTML = `
    <img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=914305182371932&ev=PageView&noscript=1"
    />
  `;
    document.body.appendChild(noscriptImg);
  }

  render() {
    const { redirected } = this.state;

    return (
      <div className='donation-thanks'>
        {redirected ? <Redirect to='/' /> : null}

        <img
          src={Thanks}
          alt='krembo-thanks-message'
        />

        <a href='https://krembo.org.il'>
          <button>
            <p>{this.props.lang.BTN}</p>
          </button>
        </a>
      </div>
    );
  }
}

export default DonationThanks;
