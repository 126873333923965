


import React, { Component } from 'react';

class CustomDropDown extends Component {
    constructor() {
        super()

        this.state = {
            menuOpen: false
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            menuOpen: !prevState.menuOpen
        }));
    }

    handleClickOutside(event) {
        if (this.wrapperRef &&
            !this.wrapperRef.contains(event.target)) {
            this.setState({ menuOpen: false })
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    render() {
        return (
            <div tabIndex={this.props.tabIndex}
                onClick={() => {
                    if (!this.props.tabIndex) this.toggleMenu()
                }} className={this.props.dropDownClass}
                onFocus={() => this.toggleMenu()} onBlur={() => {
                    if (this.props.tabIndex) this.setState({ menuOpen: false })
                }} ref={this.setWrapperRef}>
                <h6 className={this.state.menuOpen ||
                    this.props.value ?
                    this.props.dropDownClass + "__p--active " + this.props.dropDownClass + "__p" :
                    this.props.dropDownClass + "__p"}>{this.props.placeholder}</h6>
                <p>{this.props.value}</p>
                {this.state.menuOpen ?
                    <div>
                        {this.props.options.map((item, index) => {
                            return   <div onClick={() => this.wrapperRef.blur()}
                                data-name={this.props.name}
                                onClick={(e, item) => {
                                    this.wrapperRef.blur();
                                    this.props.onDropDownChange(e, this.props.form, index);
                                    this.props.validate(this.props.formObj, this.props.validationIndex, this.props.form)
                                }}>{item}</div>
                        })}
                    </div>
                    : null
                }

            </div>
        );
    }
}

export default CustomDropDown;