export const form = (dropDownOptionsArray, placeholdersArray) => {
    return [
        {
            value: '',
            name: 'gender',
            type: 'dropdown',
            options: dropDownOptionsArray[0],
            placeholder: placeholdersArray[0],
            menuOpen: false,
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'month',
            type: 'dropdown',
            menuOpen: false,
            options: dropDownOptionsArray[1],
            placeholder: placeholdersArray[1],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'day',
            type: 'number',
            placeholder: placeholdersArray[2],
            err: false,
            errMessageEmpty: '',
            errMessageInvalid: '',
            required: true
        },
        {
            value: '',
            name: 'year',
            type: 'number',
            placeholder: placeholdersArray[3],
            err: false,
            errMessageEmpty: '',
            errMessageInvalid: '',
            required: true
        },
    ]
}

export const form1 = (dropDownOptionsArray, placeholdersArray) => {
    return [
        {
            value: '',
            name: 'firstName',
            type: 'text',
            no_numbers: true,
            placeholder: placeholdersArray[0],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'lastName',
            type: 'text',
            no_numbers: true,
            placeholder: placeholdersArray[3],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'schoolName',
            type: 'text',
            placeholder: placeholdersArray[1],
            no_numbers: true,
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'mikud',
            type: 'number',
            placeholder: placeholdersArray[2],
            err: false,
            errMessageEmpty: '',
            errMessageInvalid: '',
            required: true
        },
        
        {
            value: '',
            name: 'class',
            type: 'dropdown',
            menuOpen: false,
            placeholder: placeholdersArray[4],
            options: dropDownOptionsArray[5],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'classNumber',
            type: 'text',
            placeholder: placeholdersArray[17],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'branch',
            type: 'dropdown',
            menuOpen: false,
            placeholder: placeholdersArray[5],
            options: dropDownOptionsArray[6],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'id',
            type: 'number',
            placeholder: placeholdersArray[6],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'email',
            type: 'email2',
            placeholder: placeholdersArray[7],
            err: false,
            errMessageEmpty: '',
            errMessageInvalid: '',
            required: true
        },
        {
            value: '',
            name: 'mobillity',
            type: 'dropdown',
            menuOpen: false,
            placeholder: placeholdersArray[8],
            options: dropDownOptionsArray[0],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'phone',
            type: 'tel',
            placeholder: placeholdersArray[9],
            err: false,
            errMessageEmpty: '',
            errMessageInvalid: '',
            required: true
        },
        {
            value: '',
            name: 'city',
            type: 'text',
            no_numbers: true,
            placeholder: placeholdersArray[10],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'street',
            type: 'text',
            placeholder: placeholdersArray[13],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'diet',
            type: 'dropdown',
            menuOpen: false,
            placeholder: placeholdersArray[15],
            options: dropDownOptionsArray[8],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        // {
        //     value: '',
        //     name: 'specialNeeds',
        //     type: 'dropdown',
        //     menuOpen: false,
        //     placeholder: placeholdersArray[11],
        //     options: dropDownOptionsArray[7],
        //     err: false,
        //     errMessageEmpty: '',
        //     required: false
        // },
        // {
        //     value: '',
        //     name: 'vetek',
        //     type: 'dropdown',
        //     menuOpen: false,
        //     placeholder: placeholdersArray[12],
        //     options: dropDownOptionsArray[1],
        //     err: false,
        //     errMessageEmpty: '',
        //     isNewMemeber: false,
        //     required: false
        // },


    ]
}


export const form11 = (dropDownOptionsArray, placeholdersArray) => {
    return [
        {
            value: '',
            name: 'anotherTshirt',
            type: 'dropdown',
            menuOpen: false,
            placeholder: placeholdersArray[0],
            options: dropDownOptionsArray[0],
            err: false,
            intrestedInNewShirtBoolean: false,
            errMessageEmpty: '',
            required: false
        },
        {
            options: [{ text: '10', checked: false },{ text: '12', checked: false }, { text: '14', checked: false }, { text: '16', checked: false }, { text: 'S', checked: false }, { text: 'M', checked: false }, { text: 'L', checked: false }, { text: 'XL', checked: false }, { text: 'XXL', checked: false }, { text: 'XXXL', checked: false }],
            name: 'sizeTshirt',
            type: 'multiplecheckbox',
            placeholder: '',
            multipleValuesAllowed: false,
            err: false,
            errMessageEmpty: '',
            required: false
        },
    ]

}




export const form2 = (textsArray, dropDownOptionsArray, placeholdersArray) => {
    return [
        {
            value: '',
            name: 'parent1fullName',
            type: 'text',
            placeholder: placeholdersArray[0],
            no_numbers: true,
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'parent1placeOfBirth',
            type: 'text',
            placeholder: placeholdersArray[1],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'parent1yearOfArrival',
            type: 'number',
            placeholder: placeholdersArray[2],
            err: false,
            errMessageEmpty: '',
            required: false
        },
        {
            value: '',
            name: 'parent1phone',
            type: 'tel',
            placeholder: placeholdersArray[3],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'parent1workPlace',
            type: 'text',
            placeholder: placeholdersArray[4],
            err: false,
            errMessageEmpty: '',
            required: true
        },
        {
            value: '',
            name: 'parent1email',
            type: 'email2',
            placeholder: placeholdersArray[5],
            err: false,
            errMessageEmpty: '',
            errMessageInvalid: '',
            required: true
        },

       
    ]
}



export const form22 = (placeholdersArray) => {
    return [
        {
            value: '',
            name: 'parent2fullName',
            no_numbers: true,
            type: 'text',
            placeholder: placeholdersArray[0],
            err: false,
            errMessageEmpty: '',
            required: false
        },
        {
            value: '',
            name: 'parent2placeOfBirth',
            type: 'text',
            placeholder: placeholdersArray[1],
            err: false,
            errMessageEmpty: '',
            required: false
        },
        {
            value: '',
            name: 'parent2yearOfArrival',
            type: 'number',
            placeholder: placeholdersArray[2],
            err: false,
            errMessageEmpty: '',
            required: false
        },
        {
            value: '',
            name: 'parent2phone',
            type: 'tel',
            placeholder: placeholdersArray[3],
            err: false,
            errMessageEmpty: '',
            required: false
        },
        {
            value: '',
            name: 'parent2workPlace',
            type: 'text',
            placeholder: placeholdersArray[4],
            err: false,
            errMessageEmpty: '',
            required: false
        },
        {
            value: '',
            name: 'parent2email',
            type: 'email2',
            placeholder: placeholdersArray[5],
            err: false,
            errMessageEmpty: '',
            errMessageInvalid: '',
            required: false
        },
    ]
}


export const form3 = (form3Text) => {
    return [
        {
            value: null,
            name: 'declarationStatus',
            type: 'doublecheckbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text1: form3Text[0],
            text2: form3Text[1],
            required: false
        },
        {
            value: '',
            name: 'alergyDescription',
            type: 'text',
            placeholder: form3Text[2],
            err: false,
            errMessageEmpty: '',
            required: false,
        },
        {
            value: false,
            name: 'hasAlergy',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form3Text[3],
            required: false
        },
        {
            value: '',
            name: 'typeOfAlergy',
            type: 'text',
            placeholder: form3Text[4],
            err: false,
            errMessageEmpty: '',
            required: false,
        },
        {
            value: false,
            name: 'isLeathal',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form3Text[5],
            required: false
        },
        {
            value: false,
            name: 'hasDifficulty',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form3Text[6],
            required: false
        },
        {
            value: '',
            name: 'typeOfDifficulty',
            type: 'text',
            placeholder: form3Text[7],
            err: false,
            errMessageEmpty: '',
            required: false,
        },
        {
            value: false,
            name: 'assistedWithMedicalEquipment',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form3Text[8],
            required: true
        },
        {
            value: '',
            name: 'medicalEquipmentDescription',
            type: 'text',
            placeholder: form3Text[9],
            err: false,
            errMessageEmpty: '',
            required: false,
        },
        {
            value: false,
            name: 'selfAssisted',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form3Text[10],
            required: false
        },
        {
            value: false,
            name: 'consumesDrugs',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form3Text[11],
            required: false
        },
        {
            value: false,
            name: 'hasEpilepsy',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form3Text[12],
            required: false
        },
    ]
}

export const form4 = (form4Text) => {
    return [
        {
            value: false,
            name: 'parentApproval',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form4Text[0],
            required: false
        },
        {
            value: false,
            name: 'parentApproval2',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form4Text[1],
            required: false
        },
        {
            value: false,
            name: 'parentApproval3',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form4Text[2],
            required: false
        },
    ]
}

export const form5 = (form5Text) => {
    return [
        {
            options: form5Text[0],
            name: 'exposureAprroval',
            type: 'multiplecheckbox',
            placeholder: '',
            multipleValuesAllowed: false,
            err: false,
            errMessageEmpty: '',
            required: false
        },
        {
            options: form5Text[1],
            name: 'kindOfExposure',
            type: 'multiplecheckbox',
            placeholder: '',
            multipleValuesAllowed: true,
            err: false,
            errMessageEmpty: '',
            required: false
        },
        {
            value: false,
            name: 'parentApprovalPublicAuth',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form5Text[2],
            required: false
        },
    ]
}

export const form6 = (form6Text) => {
    return [
        {
            value: false,
            name: 'lastFormParentAproval1',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form6Text[1],
            required: true
        },
        {
            value: false,
            name: 'lastFormParentAproval2',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form6Text[2],
            required: true
        },
        {
            value: false,
            name: 'lastFormParentAproval3',
            type: 'checkbox',
            placeholder: '',
            err: false,
            errMessageEmpty: '',
            text: form6Text[3],
            required: true
        },
        {
            value: '',
            name: 'approvedParent',
            type: 'text',
            placeholder: form6Text[4],
            err: false,
            errMessageEmpty: '',
            required: true,
        },
        {
            value: '',
            name: 'approvedParentId',
            type: 'number',
            placeholder: form6Text[5],
            err: false,
            errMessageEmpty: '',
            required: true,
        },
    ]
}