import React, { Component } from 'react'
import './register.css'
import validator from 'validator'
import { Link, withRouter } from 'react-router-dom'
import { Route, Redirect } from 'react-router'

//comp
import CustomInput from './parts/CustomInput'
import CustomDropDown from './parts/CustomDropDown'
import CustomCheckBox from './parts/CustomCheckBox'
import CustomDoubleCheckBox from './parts/CustomDoubleCheckBox'
import CustomMultipleCheckBox from './parts/CustomMultipleCheckBox'
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

// data
import { form, form1, form11, form2, form22, form3, form4, form5, form6 } from './parts/trial_data'
//images

import AlregyGray from '../../images/register/alergy_gray.svg'
import AlregyWhite from '../../images/register/alergy_white.svg'
import ParentsGray from '../../images/register/parents_gray.svg'
import ParentsBlack from '../../images/register/parents_black.svg'
import ParentsWhite from '../../images/register/parents_white.svg'
import PersonalGray from '../../images/register/personal_gray.svg'
import PersonalBlack from '../../images/register/personal_black.svg'
import PersonalWhite from '../../images/register/personal_white.svg'
import moment from 'moment'
import Check from '../../images/event/1.svg'
import axios from 'axios'
import { setCookie, getCookie, removeCookie } from '../../tools/cookies';
import { API } from '../../tools/keys';

import HeaderImg from '../../images/register/bg.png'

import './register-trial.css'

import JOIN_CANCEL_POLICY from '../../docs/new-signup/מכתב פתיחת שנה לפעילים ולהורים התשפ+ קוד לבוש 201920 סופי.pdf'
import Loader from '../Loader/Loader'
class RegisterTrial extends Component {
    constructor(props) {
        super(props)

        this.state = {
            trial_loading:false,
            has_medical: false,
            lethal_alergy: false,
            has_alergy: false,
            hasDisability: false,
            secondForm: false,
            thirdForm: false,
            form: form([this.props.lang.dropDown5, this.props.lang.dropDown10], this.props.lang.form1Placeholders),
            form11: form11([this.props.lang.dropDown8, this.props.lang.dropDown5], this.props.lang.form11Placeholders),
            form1: form1(
                [
                    this.props.lang.dropDown1,
                    this.props.lang.dropDown2,
                    this.props.lang.dropDown3,
                    this.props.lang.dropDown4,
                    this.props.lang.dropDown5,
                    this.props.lang.dropDown6,
                    this.props.lang.dropDown7,
                    this.props.lang.dropDown8,
                    this.props.lang.dropDown13
                ],
                this.props.lang.form2placeholders
            ),
            form2: form2([this.props.lang.declarationStatusText1, this.props.lang.declarationStatusText2, this.props.lang.approvalText], [this.props.lang.dropDown9], this.props.lang.parentPlaceholders),
            form22: form22(this.props.lang.parentPlaceholders),
            form3: form3(this.props.lang.form3Text),
            form4: form4(this.props.lang.form4Text),
            form5: form5(this.props.lang.form5Text),
            form6: form6(this.props.lang.form6Text),
            selected_form: 'personal',
            membership_cost: 425,
            shirt_value: 40,
            members_body_arr: []

        }

    }

    componentDidMount() {
        const clean_state = this.state
        this.setState({
            clean_state
        })

        const cookies_amount = getCookie(`members_amount`)


        if (cookies_amount && cookies_amount.length > 0) {
            for (var i = 0; i < cookies_amount.length; i++) {
                removeCookie(`member_info_${i + 1}`)
            }
            removeCookie(`members_amount`)
        }

        let cookie = getCookie('member_info')
        if (cookie) {
            removeCookie('member_info')
        }
    }

    onInputChange = (e, form, type, option_name) => {
        let name
        let newValue
        if (type === 'city') {
            name = option_name
            newValue = e
        } else {
            name = e.target.name
            newValue = e.target.value

        }

        let newState = this.state[form]
        let maxYear = new Date()
        maxYear = maxYear.getFullYear()

        if (this.state[form][name].type === 'number' || this.state[form][name].type === 'tel') {
            newValue = newValue.replace(/\D/g, '')
        }

        if(this.state[form][name].no_numbers) newValue = newValue.replace(/[0-9]/g, '')

        if (this.state[form][name].type === 'number' && this.state[form][name].name === 'day' && newValue > 31) {
            newValue = 31
        }

        if (this.state[form][name].type === 'number' && this.state[form][name].name === 'year' && newValue > maxYear) {
            newValue = maxYear
        }
        if (this.state[form][name].type === 'number' && this.state[form][name].name === 'parent1yearOfArrival' && newValue > maxYear) {
            newValue = maxYear
        }
        if (this.state[form][name].type === 'number' && this.state[form][name].name === 'parent2yearOfArrival' && newValue > maxYear) {
            newValue = maxYear
        }
        if (this.state[form][name].type === 'number' && this.state[form][name].name === 'disability_percent' && newValue > 100) {
            newValue = 100
        }

        newState[name].value = newValue

        this.setState({
            [form]: newState
        })
    }



    onTextAreaChange = (e, form) => {

        let name = e.target.name
        let newValue = e.target.value
        let newState = this.state[form]



        newState[name].value = newValue

        this.setState({
            [form]: newState
        })
    }

    onDropDownChange = (e, form, index) => {
        let name = e.target.getAttribute('data-name')
        let value = e.target.innerHTML
        let newState = this.state[form]


        if (newState[name].name === 'vetek' && index === 0) {
            newState[name].isNewMemeber = true


            let newForm11 = this.state.form11
            newForm11[0].required = false
            this.setState({ form11: newForm11 })

        } else if (newState[name].name === 'vetek' && index === 1) {
            newState[name].isNewMemeber = false
        }

        if (newState[name].name === 'anotherTshirt' && index === 0) {
            newState[name].intrestedInNewShirtBoolean = true
        } else if (newState[name].name === 'anotherTshirt' && index === 1) {
            newState[name].intrestedInNewShirtBoolean = false
            newState[1].required = false
        }


        newState[name].value = value

        this.setState({
            [form]: newState
        })
    }

    onCheck = (e, form) => {
        let name = e.target.getAttribute('name')

        name = Number(name)
        let newState = this.state[form]
        if (newState[name].name === 'hasAlergy') {
            newState[name + 1].required = !newState[name + 1].required
        }
        if (newState[name].name === 'hasDifficulty') {
            newState[name + 1].required = !newState[name + 1].required
        }
        if (newState[name].name === 'assistedWithMedicalEquipment') {
            newState[name + 1].required = !newState[name + 1].required
        }
        let val = newState[name].value
        newState[name].value = !val
        this.setState({
            [form]: newState
        }, () => { console.log(newState[name + 1]) })

    }

    onDoubleCheck = (e, form, val) => {
        let name = e.target.getAttribute('name')
        let newState = this.state[form]
        newState[name].value = val
        this.setState({
            [form]: newState
        })
    }

    onMultiCheck = (e, form, index) => {
        let name = e.target.getAttribute('name')
        name = Number(name)
        let newState = this.state[form]
        let oldCheckboxState = newState[name].options[index].checked
        if (newState[name].multipleValuesAllowed) {
            newState[name].options[index].checked = !oldCheckboxState
        } else {
            for (let i in newState[name].options) {
                newState[name].options[i].checked = false
            }

            newState[name].options[index].checked = true
        }

        // console.log(newState[name + 1],newState[name].name)
        if (newState[name].name === 'exposureAprroval' && newState[name].options[3].checked) {
            newState[name + 1].required = true
        } else if (newState[name].name === 'exposureAprroval' && !newState[name].options[3].checked) {
            newState[name + 1].required = false
        }

        this.setState({
            [form]: newState
        })
    }

    submitForm = async (e, formsArray, page) => {


        let errorCounter = 0
        e.preventDefault()

        new Promise((resolve, reject) => {
            formsArray.map(form => {
                this.state[form].map(async (item, index) => {
                    await this.validate(this.state[form][index], index, form)
                    if (this.state[form][index].errMessageEmpty || this.state[form][index].errMessageInvalid) {
                  
                        errorCounter++
                    }
                })
            })
         

            resolve()
        }).then(async () => {
            if (errorCounter === 0) {
             
                if (page === 1) {
                  


                    let hasDisability = this.state.form1[this.state.form1.length - 3].value
                    hasDisability = hasDisability.toLowerCase()


                    const { members_body_arr, body_to_send } = this.state

                    this.setState({ selected_form: 'parents', personal_submitted: true })
                 


                } else if (page === 2) {
                    this.setState({ selected_form: 'alergy', parents_submitted: true })
                }
            } else if (page === 3) {

            } else {
               
            }
        })

    }

    validate = async (obj, index, form) => {
        let newState = this.state[form]
            if (obj.type === 'text' || obj.type === 'dropdown') {
                if (!obj.value && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if (obj.type === 'email2') {
                if (!obj.value && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else if (!validator.isEmail(obj.value) && obj.value) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = this.props.lang[obj.name + 'Invalid']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if ((obj.type === 'number' && obj.name === 'id') || (obj.type === 'number' && obj.name === 'approvedParentId')) {
                if (!obj.value && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else if ((obj.value.length < 9 || obj.value.length > 9) && obj.value) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = this.props.lang[obj.name + 'Invalid']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if (obj.type === 'number' && obj.name === 'day') {
                if (!obj.value && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else if ((obj.value < 1 || obj.value > 31) && obj.value) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = this.props.lang[obj.name + 'Invalid']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if (obj.type === 'number' && obj.name === 'year') {
                if (!obj.value && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else if ((obj.value < 1900 || obj.value > 2019) && obj.value) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = this.props.lang[obj.name + 'Invalid']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if (obj.type === 'number' && obj.name === 'mikud') {
                if (!obj.value && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else if ((obj.value.length < 5 || obj.value.length > 7) && obj.value) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = this.props.lang[obj.name + 'Invalid']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if (obj.type === 'tel') {
                if (!obj.value && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else if (!validator.isMobilePhone(obj.value) && obj.value) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = this.props.lang[obj.name + 'Invalid']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    newState[index].errMessageInvalid = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if (obj.type === 'checkbox') {
                if (!obj.value && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if (obj.type === 'doublecheckbox') {
                if (typeof obj.value === 'object' && obj.required) {
                    newState[index].err = true
                    newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                    this.setState({
                        [form]: newState
                    })
                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    this.setState({
                        [form]: newState
                    })
                }
            } else if (obj.type === 'multiplecheckbox') {
                let counter = 0
                if (obj.required) {
                    for (let i in obj.options) {
                        if (obj.options[i].checked) {
                            counter++
                        }
                    }

                    if (counter === 0) {
                        newState[index].err = true
                        newState[index].errMessageEmpty = this.props.lang[obj.name + 'Empty']
                        this.setState({
                            [form]: newState
                        })
                    } else {
                        newState[index].err = false
                        newState[index].errMessageEmpty = ''
                        this.setState({
                            [form]: newState
                        })
                    }

                } else {
                    newState[index].err = false
                    newState[index].errMessageEmpty = ''
                    this.setState({
                        [form]: newState
                    })
                }
            }
          
    }
    createRegisterBody = () => {

        const { form,
            form1,
            form2,
            form3,
            form4,
            form5,
            form6,
            form11,
            form22,
            has_alergy,
            alergy_type,
            lethal_alergy,
            has_medical,
            medical_type,
            trial_know,
            
        } = this.state
        if (form3[0].value === false) {
            form3[1].value = ''
            form3[2].value = false
            form3[3].value = ''
            form3[4].value = false
            form3[5].value = false
            form3[6].value = ''
            form3[7].value = false
            form3[8].value = ''
            form3[9].value = false
            form3[10].value = false
            form3[11].value = false
        }
        const body = {
            gender: form[0].value,
            member_birth_date: this.createDate(),

            member_first_name: form1[0].value,
            member_last_name: form1[1].value,
            school: form1[2].value,
            zipcode: form1[3].value,
            grade: form1[4].value,
            grade_number: form1[5].value,
            branch: form1[6].value,
            member_id_number: form1[7].value,
            member_email: form1[8].value,
            mobillity: form1[9].value,
            member_phone: form1[10].value,
            member_city: form1[11].value,
            member_street: form1[12].value,
            diet: form1[13].value,

            member_special_needs: false,
            member_experience: '',
            shirt_size: '',
            first_parent_name: form2[0].value,
            first_parent_country: form2[1].value,
            first_parent_arrival: form2[2].value,
            first_parent_phone: form2[3].value,
            first_parent_work: form2[4].value,
            first_parent_email: form2[5].value,
            second_parent_name: form22[0].value,
            second_parent_country: form22[1].value,
            second_parent_arrival: form22[2].value,
            second_parent_phone: form22[3].value,
            second_parent_work: form22[4].value,
            second_parent_email: form22[5].value,
            health_declaration: false,
            health_declaration_description: '',
            has_alergy: has_alergy,
            alergy_type: alergy_type,
            leathal_alergy: lethal_alergy,
            difficulty: false,
            difficulty_type: '',
            medical_equipment: has_medical,
            medical_equipment_description: medical_type,
            self_assisted: true,
            consume_medicine: false,
            has_epilepsy: false,
            parents_declartion_activity: false,
            parents_declartion_terms: false,
            parents_declartion_equip: false,
            exposure_aproval: '',
            shirt_size: form11[1].options.filter(n => n.checked)[0] ?  form11[1].options.filter(n => n.checked)[0].text : '',
            exposure_aproval_options: [],
            exposure_data_usage_agreement: trial_know,
            ride_permission_term1: false,
            ride_permission_term2: false,
            ride_permission_term3: false,
            status: true,
            is_trial: true,
            parent_approved_name: '',
            parent_approved_id: ''

        }
        this.setState({
            body_to_send: body
        })
        return body


    }
    selectSize = (value, load) => {
        const { form11, form1, shirt_size } = this.state
        let val = value
        if (value !== shirt_size && !load) {
            let selected = form11[1].options.map(function (val) {
                return {
                    text: val.text,
                    checked: val.text === value ? true : false
                }
            })
            form11[1].options = selected
            form11[1].errMessageEmpty = false
            this.setState({
                shirt_size: value
            })
        } if (!load && value === shirt_size && form1[form1.length - 1].value === "חניך ותיק") {
            form11[0].value = 'לא'
            this.setState({
                shirt_size: ''
            })
        } if (load) {
            let selected = form11[1].options.map(function (val) {
                return {
                    text: val.text,
                    checked: val.text === value ? true : false
                }
            })
            form11[1].options = selected
            form11[1].errMessageEmpty = false
            this.setState({
                shirt_size: value
            })
        }

    }
    createDate = () => {
        const month = this.state.form[1].value

        let elem
        switch (month) {
            case 'ינואר':
                elem = '01'
                break;
            case 'פברואר':
                elem = '02'
                break;
            case 'מרץ':
                elem = '03'
                break;
            case 'אפריל':
                elem = '04'
                break;
            case 'מאי':
                elem = '05'
                break;
            case 'יוני':
                elem = '06'
                break;
            case 'יולי':
                elem = '07'
                break;
            case 'אוגוסט':
                elem = '08'
                break;
            case 'ספטמבר':
                elem = '09'
                break;
            case 'אוקטובר':
                elem = '10'
                break;
            case 'נובמבר':
                elem = '11'
                break;
            case 'דצמבר':
                elem = '12'
                break;

            default:
                break;
        }
        const day = Number(this.state.form[2].value)

        const year = this.state.form[3].value
        const date = `${year}-${elem}-${day < 10 ? `0${day}` : day}T00:00`

        const new_date = moment(date).toISOString()
        return new_date

    }




    addTrialMember = async () => {
        const { body_to_send, trial_know } = this.state
       if(trial_know){
        const body = await this.createRegisterBody()
        this.setState({
            trial_loading:true
        })
        const res = await axios.post(`${API}/member/createtrial`, body)

        const { result, ok } = res.data


        if (ok) {
            this.setState({
                trial_added: true,
                trial_submitted: true,
                trial_loading:false
            })
        }else{
            this.setState({
                trial_loading:false
            })
        }
       }else{
           this.setState({
               trial_know_error:true
           })
       }

    }


    addBrother = async () => {
        const { members_body_arr } = this.state
        const body = await this.createRegisterBody()
        let arr = members_body_arr
        arr = [...arr, body]
        this.setState({
            members_body_arr: arr,
            selected_form: 'personal',

        })
        await this.clearRegisterForm()

        window.scrollTo(0, 0)

    }

    clearRegisterForm = () => {




        this.state.form[0].value = ''
        this.state.form[1].value = ''
        this.state.form[2].value = ''
        this.state.form[3].value = ''
        this.state.form1[0].value = ''

        this.state.form1[3].value = ''
        this.state.form1[1].value = ''
        this.state.form1[2].value = ''
        this.state.form1[4].value = ''
        this.state.form1[5].value = ''
        this.state.form1[6].value = ''
        this.state.form1[7].value = ''
        this.state.form1[8].value = ''
        this.state.form1[9].value = ''
        this.state.form1[10].value = ''
        this.state.form1[11].value = ''
        this.state.form2[0].value = ''
        this.state.form2[1].value = ''
        this.state.form2[2].value = ''
        this.state.form2[3].value = ''
        this.state.form2[4].value = ''
        this.state.form2[5].value = ''
        this.state.form22[0].value = ''
        this.state.form22[1].value = ''
        this.state.form22[2].value = ''
        this.state.form22[3].value = ''
        this.state.form22[4].value = ''
        this.state.form22[5].value = ''
        this.state.form3[0].value = null
        this.state.form3[1].value = ''
        this.state.form3[2].value = false
        this.state.form3[3].value = ''
        this.state.form3[4].value = false
        this.state.form3[5].value = false
        this.state.form3[6].value = ''
        this.state.form3[7].value = false
        this.state.form3[8].value = ''
        this.state.form3[9].value = false
        this.state.form3[10].value = false
        this.state.form3[11].value = false
        this.state.form4[0].value = false
        this.state.form4[1].value = false
        this.state.form4[2].value = false
        this.state.form6[0].value = false
        this.state.form6[1].value = false
        this.state.form6[2].value = false
        this.state.form11[0].value = ''
        let form5_options = this.state.form5[0].options.map(function (val) {
            return {
                ...val,
                checked: false
            }
        })

        this.state.form5[0].options = form5_options
        let form5_options1 = this.state.form5[1].options.map(function (val) {
            return {
                ...val,
                checked: false
            }
        })
        this.state.form5[1].options = form5_options1
        this.state.form5[2].value = false
        let form11_options = this.state.form11[1].options.map(function (val) {
            return {
                ...val,
                checked: false
            }
        })
        this.state.form11[1].options = form11_options
        this.state.form6[3].value = ''
        this.state.form6[4].value = ''
        this.forceUpdate()
    }


    clearExistingMember = () => {
        this.clearRegisterForm()
        this.setState({
            member_exists: false,
            member_exists_local: false
        })
    }



    render() {
        const { form,
            form1,
            form11,
            form2,
            form22,
            form3,
            form4,
            form5,
            trial_loading,
            hasDisability,
            secondForm,
            thirdForm,
            selected_form,
            personal_submitted,
            membership_cost,
            body_to_send,
            shirt_value,
            sending_error,
            redirect,
            has_alergy,
            redirect_to_success,
            member_exists,
            parents_submitted,
            has_medical,
            lethal_alergy,
            trial_know,
            medical_type,
            alergy_type,
            trial_added,
            trial_submitted,
            trial_know_error
        } = this.state


        const { lang } = this.props

        return (
            <div className="register register__trial">
                {
                    trial_added ?
                        <div className='trail__success'>
                            <h1>{lang.TRIAL_SUCCESS}</h1>
                            <Link to="/">
                                <button onClick={() => this.setState({
                                    trial_added: false
                                })}>{lang.TRIAL_CLOSE}</button>
                            </Link>

                        </div> : null
                }
                <div className='trial__register__header'>
                    <img src={HeaderImg} alt="" />
                    <div>
                        <h1>{lang.TRIAL_REG_H1}</h1>
                        <p>{lang.TRIAL_P}</p>
                        <a href={JOIN_CANCEL_POLICY} target="_blank" rel="noopener noreferrer">{lang.TRIAL_LINK}</a>
                    </div>
                </div>
                {member_exists ? <div className='member__exists__box'>
                    <h1>{lang.EXISTS}</h1>
                    <span>


                        <button onClick={() => this.clearExistingMember()}>{lang.EXISTS_BTN2}</button>
                    </span>
                </div> : null}


                {redirect_to_success ? <Redirect to='/registration-success' /> : null}

                {sending_error ? <div className='register__send__error__box'>
                    <h2>קרתה שגיאה, אנא נסה שנית</h2>
                    <button onClick={() => this.setState({ sending_error: false })}>נסה שנית</button>
                </div> : null}

                <nav className="register__nav">
                    <div
                        onClick={() => selected_form !== 'disabillity_form' ?
                            this.setState({ selected_form: 'personal' }) : null}
                        className={selected_form === 'personal' ?
                            "register__nav__stages--active register__nav__stages" : personal_submitted ? ' register__nav__stages register__nav__stages__submitted' : "register__nav__stages"}
                    >
                        <img src={selected_form === 'personal' ? PersonalWhite : personal_submitted ? PersonalBlack : PersonalGray} alt="" />
                        <p className={selected_form !== 'personal' && personal_submitted ? 'black__p' : ''}>{lang.stage1}</p>
                    </div>
                    <div
                        onClick={() => personal_submitted && selected_form !== 'disabillity_form' ?
                            this.setState({ selected_form: 'parents' }) : null}
                        className={selected_form === 'parents' ?
                            "register__nav__stages--active register__nav__stages" :
                            parents_submitted ? ' register__nav__stages register__nav__stages__submitted' :
                                "register__nav__stages"}
                    >
                        <img src={selected_form === 'parents' ? ParentsWhite : parents_submitted ? ParentsBlack : ParentsGray} alt="" />
                        <p className={selected_form !== 'parents' && parents_submitted ? 'black__p' : ''}>{lang.PARENTS_INFO}</p>
                    </div>
                    <div
                        onClick={() => parents_submitted ? this.setState({
                            selected_form: 'alergy'
                        }) : null}
                        className={selected_form === 'alergy' ?
                            "register__nav__stages--active register__nav__stages" : "register__nav__stages"}
                    >
                        <img src={selected_form === 'alergy' ? AlregyWhite : AlregyGray} alt="" />
                        <p >{lang.STAGE_ALERGY}</p>
                    </div>
                </nav>




                {!hasDisability && selected_form === 'personal' ?
                    <form onSubmit={(e) => { this.submitForm(e, ['form1', 'form', 'form11'], 1); }} className="register__form">

                        <section>

                            <div className="register__form__first-container">
                                <h2>{lang.secHeader}</h2>
                                <div>
                                    <p>{lang.gender}</p>
                                    <div className="register__form__area" key={form[0].name}>
                                        <CustomDropDown formObj={form[0]} validationIndex={0} validate={this.validate} placeholder={form[0].placeholder} form={'form'} options={form[0].options} value={form[0].value} name={0} onDropDownChange={this.onDropDownChange} dropDownClass="customdropdown" type={form[0].type} />
                                        {form[0].errMessageEmpty ? <p>{form[0].errMessageEmpty}</p> : null}
                                        {form[0].errMessageInvalid && !form[0].errMessageEmpty ? <p>{form[0].errMessageInvalid}</p> : null}
                                    </div>
                                </div>

                                <div style={{ marginRight: '20px' }}>
                                    <p>{lang.dateOfBirth}</p>
                                    <div className="register__form__first-container__flex">
                                        <div className="register__form__area" key={form[1].name}>
                                            <CustomDropDown formObj={form[1]} validationIndex={1} validate={this.validate} placeholder={form[1].placeholder} form={'form'} options={form[1].options} value={form[1].value} name={1} onDropDownChange={this.onDropDownChange} dropDownClass="customdropdown" type={form[1].type} />
                                            {form[1].errMessageEmpty ? <p>{form[1].errMessageEmpty}</p> : null}
                                            {form[1].errMessageInvalid && !form[1].errMessageEmpty ? <p>{form[1].errMessageInvalid}</p> : null}
                                        </div>

                                        <div style={{ width: '130px' }} className="register__form__area" key={form[2].name}>
                                            <CustomInput formObj={form[2]} validationIndex={2} validate={this.validate} placeholder={form[2].placeholder} form={'form'} value={form[2].value} name={2} onInputChange={this.onInputChange} inputClass="custominput" type={form[2].type} />
                                            {form[2].errMessageEmpty ? <p>{form[2].errMessageEmpty}</p> : null}
                                            {form[2].errMessageInvalid && !form[2].errMessageEmpty ? <p>{form[2].errMessageInvalid}</p> : null}
                                        </div>

                                        <div style={{ width: '130px' }} className="register__form__area" key={form[3].name}>
                                            <CustomInput formObj={form[3]} validationIndex={3} validate={this.validate} placeholder={form[3].placeholder} form={'form'} value={form[3].value} name={3} onInputChange={this.onInputChange} inputClass="custominput" type={form[3].type} />
                                            {form[3].errMessageEmpty ? <p>{form[3].errMessageEmpty}</p> : null}
                                            {form[3].errMessageInvalid && !form[3].errMessageEmpty ? <p>{form[3].errMessageInvalid}</p> : null}
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="register__form__second-container">


                                <div>
                                    {form1.map((item, index) => {
                                        return form1[index].type === 'dropdown' ? <div className="register__form__area" key={item.name}>
                                            <CustomDropDown formObj={form1[index]} validationIndex={index} validate={this.validate} placeholder={form1[index].placeholder} form={'form1'} options={form1[index].options} value={form1[index].value} name={index} onDropDownChange={this.onDropDownChange} dropDownClass="customdropdown" type={form1[index].type} />
                                            {form1[index].errMessageEmpty ? <p>{form1[index].errMessageEmpty}</p> : null}
                                            {form1[index].errMessageInvalid && !form1[index].errMessageEmpty ? <p>{form1[index].errMessageInvalid}</p> : null}
                                        </div>
                                            : form1[index].type === 'checkbox' ?
                                                <div className="register__form__area" key={item.name}>
                                                    <CustomCheckBox formObj={form1[index]} validationIndex={index} validate={this.validate} placeholder={form1[index].placeholder} form={'form1'} text={item.text} value={form1[index].value} name={index} onCheck={this.onCheck} checkBoxClass="customcheckbox" type={form1[index].type} />
                                                    {form1[index].errMessageEmpty ? <p>{form1[index].errMessageEmpty}</p> : null}
                                                    {form1[index].errMessageInvalid && !form1[index].errMessageEmpty ? <p>{form1[index].errMessageInvalid}</p> : null}
                                                </div>
                                                : form1[index].type === 'doublecheckbox' ?
                                                    <div className="register__form__area" key={item.name}>
                                                        <CustomDoubleCheckBox formObj={form1[index]} validationIndex={index} validate={this.validate} placeholder={form1[index].placeholder} form={'form1'} text={[item.text1, item.text2]} value={form1[index].value} name={index} onDoubleCheck={this.onDoubleCheck} doubleCheckBoxClass="customdoublecheckbox" type={form1[index].type} />
                                                        {form1[index].errMessageEmpty ? <p>{form1[index].errMessageEmpty}</p> : null}
                                                        {form1[index].errMessageInvalid && !form1[index].errMessageEmpty ? <p>{form1[index].errMessageInvalid}</p> : null}
                                                    </div>
                                                    : form1[index].type === 'multiplecheckbox' ?
                                                        <div className="register__form__area" key={item.name}>
                                                            <CustomMultipleCheckBox formObj={form1[index]} validationIndex={index} validate={this.validate} placeholder={form1[index].placeholder} multipleValuesAllowed={item.multipleValuesAllowed} form={'form1'} options={item.options} value={form1[index].value} name={index} onMultiCheck={this.onMultiCheck} multipleCheckBoxClass="custommultiplecheckbox" type={form1[index].type} />
                                                            {form1[index].errMessageEmpty ? <p>{form1[index].errMessageEmpty}</p> : null}
                                                            {form1[index].errMessageInvalid && !form1[index].errMessageEmpty ? <p>{form1[index].errMessageInvalid}</p> : null}
                                                        </div>
                                                        :
                                                        <div className="register__form__area " key={item.name}>
                                                            <CustomInput 
                                                               show_options={item.name === 'city' ? 'city' : ''}
                                                            formObj={form1[index]}
                                                                show_options={item.name === 'city' ? 'city' : ''}
                                                                validationIndex={index} validate={this.validate} placeholder={form1[index].placeholder} form={'form1'} value={form1[index].value} name={index} onInputChange={this.onInputChange} inputClass="custominput" type={form1[index].type} />
                                                            {form1[index].errMessageEmpty ? <p>{form1[index].errMessageEmpty}</p> : null}
                                                            {form1[index].errMessageInvalid && !form1[index].errMessageEmpty ? <p>{form1[index].errMessageInvalid}</p> : null}
                                                        </div>

                                    })}

                                    <div className="register__form__area--absolute">
                                        <h1>{lang.tshirtSize}</h1>
                                        <div className="register__form__area"
                                            key={form11[1].name}>
                                            <CustomMultipleCheckBox
                                                formObj={form11[1]}
                                                validationIndex={1}
                                                validate={this.validate}
                                                placeholder={form11[1].placeholder}
                                                multipleValuesAllowed={form11[1].multipleValuesAllowed}
                                                form={'form11'} options={form11[1].options}
                                                value={form11[1].value} name={1}
                                                onMultiCheck={this.onMultiCheck}
                                                multipleCheckBoxClass="custommultiplecheckbox"
                                                type={form11[1].type}
                                                shirt_select={true}
                                            />
                                            {form11[1].errMessageEmpty ?
                                                <p>{form11[1].errMessageEmpty}</p> : null}
                                            {form11[1].errMessageInvalid &&
                                                !form11[1].errMessageEmpty ?
                                                <p>{form11[1].errMessageInvalid}</p> : null}
                                        </div>
                                    </div>




                                </div>

                                <button type="submit">{lang.NEXT_STEP_TRIAL}</button>

                            </div>


                        </section>

                        <section>

                        </section>

                    </form>
                    :
                    null
                }



                {selected_form === 'parents' && !hasDisability ?
                    <form onSubmit={(e) => { this.submitForm(e, ['form2', 'form22'], 2) }}
                        className="register__form__second">

                        <div className="form2-section">

                            <div className="form2-section__flex">
                                <div>
                                    <h2>{lang.parent1}</h2>
                                    {form2.map((item, index) => {
                                        return form2[index].type === 'dropdown' ?
                                            <div className="register__form__area" key={item.name}>
                                                <CustomDropDown formObj={form2[index]}
                                                    validationIndex={index}
                                                    validate={this.validate} form={'form2'}
                                                    placeholder={form2[index].placeholder}
                                                    options={form2[index].options}
                                                    value={form2[index].value}
                                                    name={index}
                                                    onDropDownChange={this.onDropDownChange}
                                                    dropDownClass="customdropdown"
                                                    type={form2[index].type} />
                                                {form2[index].errMessageEmpty ?
                                                    <p>{form2[index].errMessageEmpty}</p> : null}
                                                {form2[index].errMessageInvalid &&
                                                    !form2[index].errMessageEmpty ?
                                                    <p>{form2[index].errMessageInvalid}</p> : null}
                                            </div>
                                            :
                                            <div className="register__form__area"
                                                key={item.name}>
                                                <CustomInput
                                                 show_options={
                                                    item.name === 'parent1placeOfBirth' ? 'country' : ''}
                                                    formObj={form2[index]}
                                                    validationIndex={index}
                                                    validate={this.validate}
                                                    form={'form2'}
                                                    placeholder={form2[index].placeholder}
                                                    value={form2[index].value}
                                                    name={index}
                                                    onInputChange={this.onInputChange}
                                                    show_options={item.name === 'parent1placeOfBirth' ? 'country' : ''}
                                                    inputClass="custominput"
                                                    type={form2[index].type} />
                                                {form2[index].errMessageEmpty ?
                                                    <p>{form2[index].errMessageEmpty}</p> : null}
                                                {form2[index].errMessageInvalid &&
                                                    !form2[index].errMessageEmpty ?
                                                    <p>{form2[index].errMessageInvalid}</p> : null}
                                            </div>
                                    })}
                                </div>

                                <div>
                                    <h2>{lang.parent2}</h2>
                                    {form22.map((item, index) => {
                                        return form2[index].type === 'dropdown' ? <div className="register__form__area" key={item.name}>
                                            <CustomDropDown formObj={form22[index]} validationIndex={index} validate={this.validate} form={'form22'} placeholder={form22[index].placeholder} options={form22[index].options} value={form22[index].value} name={index} onDropDownChange={this.onDropDownChange} dropDownClass="customdropdown" type={form22[index].type} />
                                            {form22[index].errMessageEmpty ? <p>{form22[index].errMessageEmpty}</p> : null}
                                            {form22[index].errMessageInvalid && !form22[index].errMessageEmpty ? <p>{form22[index].errMessageInvalid}</p> : null}
                                        </div>
                                            :
                                            <div className="register__form__area" key={item.name}>
                                                <CustomInput

                                                    show_options={item.name === 'parent2placeOfBirth' ? 'country' : ''}
                                                    formObj={form22[index]} validationIndex={index} validate={this.validate} form={'form22'} placeholder={form22[index].placeholder} value={form22[index].value} name={index} onInputChange={this.onInputChange} inputClass="custominput" type={form22[index].type} />
                                                {form22[index].errMessageEmpty ? <p>{form22[index].errMessageEmpty}</p> : null}
                                                {form22[index].errMessageInvalid && !form22[index].errMessageEmpty ? <p>{form22[index].errMessageInvalid}</p> : null}
                                            </div>
                                    })}
                                </div>
                            </div>
                        </div>


                        <button type="submit" className='trial__parents__btn'>{lang.NEXT_STEP_TRIAL}</button>

                    </form>
                    :
                    null
                }

                {
                    selected_form === 'alergy' ?
                        <form
                            className='trial__alergy__form'
                            onSubmit={(e) => { this.submitForm(e, ['form3', 'form4', 'form5', 'form6'], 2); this.addTrialMember() }} >
                            <h2>{lang.ALERRGY_DESC}</h2>
                            <div className='reg__alergy__box'>
                                <span>
                                    <figure className='trial__checkbox__figure'>
                                        <Radio
                                            checked={has_alergy === false}
                                            onChange={() => this.setState({ has_alergy: false })}

                                        />
                                    </figure>

                                    <h3>{lang.NO_ALERGY}</h3>
                                </span>
                                <span>
                                    <figure className='trial__checkbox__figure'>
                                        <Radio
                                            checked={has_alergy === true}
                                            onChange={() => this.setState({ has_alergy: true })}

                                        />
                                    </figure>

                                    <h3>{lang.YES_ALERGY}</h3>
                                </span>
                                {
                                    has_alergy ?
                                        <section className='trial__alergy__description'>
                                            <aside>
                                                <h3>{lang.ALERGY_TYPE}</h3>
                                                <input type="text"
                                                    value={alergy_type}
                                                    onChange={e => this.setState({ alergy_type: e.target.value })} />
                                            </aside>
                                            <aside>
                                                <h3>{lang.LETHAL_ALERGY}</h3>
                                                <span>
                                                    <figure className='trial__checkbox__figure'>
                                                        <Radio
                                                            checked={lethal_alergy === true}
                                                            onChange={() => this.setState({ lethal_alergy: true })}

                                                        />
                                                    </figure>

                                                    <h3>{lang.YES}</h3>
                                                </span>
                                                <span>

                                                    <figure className='trial__checkbox__figure'> <Radio
                                                        checked={lethal_alergy === false}
                                                        onChange={() => this.setState({ lethal_alergy: false })}

                                                    /></figure>

                                                    <h3>{lang.NO}</h3>
                                                </span>

                                            </aside>
                                        </section>
                                        : null
                                }

                            </div>


                            <div className='reg__alergy__box reg__medical__box'>
                                <h3>{lang.MEDICAL_EQIUP_EXP}</h3>
                                <span>
                                    <figure className='trial__checkbox__figure'>
                                        <Radio
                                            checked={has_medical === true}
                                            onChange={() => this.setState({ has_medical: true })}

                                        />
                                    </figure>

                                    <h3>{lang.YES}</h3>
                                </span>
                                <span>
                                    <figure className='trial__checkbox__figure'>
                                        <Radio
                                            checked={has_medical === false}
                                            onChange={() => this.setState({ has_medical: false })}

                                        />
                                    </figure>

                                    <h3>{lang.NO}</h3>
                                </span>
                                {
                                    has_medical ?
                                        <section className='trial__alergy__description'>
                                            <aside>
                                                <h3>{lang.MEDICAL_EQUIP}</h3>
                                                <input type="text"
                                                    value={medical_type}
                                                    onChange={e => this.setState({ medical_type: e.target.value })} />
                                            </aside>
                                        </section>
                                        : null
                                }

                            </div>

                            <div className='trial__know'>
                              <span>
                              <figure>
                               <Checkbox
                                    checked={trial_know}
                                    onChange={() => this.setState({ trial_know: !trial_know, trial_know_error:false })}

                                />
                               </figure>
                               
                                <h3>{lang.I_KNOW}</h3>
                              </span>
                                {trial_know_error ?     <p className='trial__error'>שדה זה הינו חובה</p>  : ''}
                            </div>



                          {trial_loading ? 
                            <Loader />
                          : <button type="submit"
                                className={trial_submitted ? 'trial__alergy__submit trial__alergy__submit__disabled' :
                                    'trial__alergy__submit'} >{lang.SIGN_END}</button>}
                        </form>
                        : null
                }





            </div>
        );
    }
}

export default RegisterTrial;

