import React, { Component } from 'react'
import TutorInput from './small-parts/TutorInput';

import Plus from '../../../images/tutor/add.svg'

class Parents extends Component {
    constructor() {
        super()
        this.state = {
            another_parent: false

        }
    }



    submitForm = async (e, val) => {
        let check = ''
        this.setState({
            errors:[]
        })
        const { has_errors } = this.state
        e.preventDefault()
        this.setState({
            validate: true
        })
        setTimeout(() => {
            this.setState({
                validate: false
            })
        }, 100);
        setTimeout(async ()  => {
            check = await document.querySelector('.error__msg')
           if(check){
               
           }else{
              
            this.props.changeSection(3)
           }
        }, 100);
       
     
     
    };


    render() {

       
        const { values, lang } = this.props
        const {validate} = this.state
        const {
            first_parent_name,
            first_parent_phone,
            first_parent_email,
            second_parent_name,
            second_parent_phone,
            second_parent_email,
            another_parent
        } = values
        return (
            <form className='tutor__parents__form'
            onSubmit = {e => this.submitForm(e)}
            >
                <section>
                    <TutorInput
                        updateForm={this.props.updateForm}
                        value={first_parent_name}
                        state_name='first_parent_name'
                        name={lang.PARENT_NAME}
                        type={'text'}
                        validate={validate}
                        error_msg = {lang.ERR}
                    />
                    <TutorInput
                        updateForm={this.props.updateForm}
                        value={first_parent_phone}
                        state_name='first_parent_phone'
                        name={lang.PARENT_PHONE}
                        type={'text'}
                        validate={validate}
                        error_msg = {lang.ERR}
                    />
                    <TutorInput
                        updateForm={this.props.updateForm}
                        value={first_parent_email}
                        state_name='first_parent_email'
                        name={lang.PARENT_EMAIL}
                        type={'text'}
                        validate={validate}
                        error_msg = {lang.ERR}
                        validation="email"
                        email_error = {lang.EMAIL_ERR}
                    />
                </section>
                {!another_parent ?
                     <span className='tutor__add__parent'
                     onClick = {() => {this.setState({
                        another_parent:true
                     });
                     this.props.updateForm('another_parent', true)
                    }}
                     >
                    <img src={Plus} alt="" />
                    <h5>{lang.ADD_PARENT}</h5>
                </span> : null}
                {another_parent ? <section>

                    <TutorInput
                        updateForm={this.props.updateForm}
                        value={second_parent_name}
                        state_name='second_parent_name'
                        name={lang.PARENT_NAME}
                        type={'text'}
                       
                    />
                    <TutorInput
                        updateForm={this.props.updateForm}
                        value={second_parent_phone}
                        state_name='second_parent_phone'
                        name={lang.PARENT_PHONE}
                        type={'text'}
                        phone = {true}
                        
                    />
                    <TutorInput
                        updateForm={this.props.updateForm}
                        value={second_parent_email}
                        state_name='second_parent_email'
                        name={lang.PARENT_EMAIL}
                        type={'text'}
                        
                    />


                </section> : null}
                <aside class='tutor__form__submit'>
                   <h2>↙</h2>
               <input type="submit" value = 'המשך'/>
               </aside>
            </form >
        )
    }
}

export default Parents